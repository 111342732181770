import Loader from 'components/controls/Loader/Loader';
import { SkillCategory, SkillCategoryList } from 'modules/general/general.type';
import React, { FunctionComponent, useEffect } from 'react';
import Tree from 'views/category/categorytree';

interface CProps {
  getCategories: Function;
  categories: SkillCategoryList;
  updateCategory: Function;
  removeCategory: Function;
  createCategory: Function;
  addCategory: SkillCategory;
  isDataLoading: boolean;
}
const categoryView: FunctionComponent<CProps> = (props: CProps) => {
  const {
    getCategories,
    categories,
    createCategory,
    updateCategory,
    removeCategory,
    addCategory,
    isDataLoading,
  } = props;
  useEffect(() => {
    getCategories();
  }, []);
  return (
    <>
      {isDataLoading ? (
        <Loader isSmallView />
      ) : (
        <div>
          <Tree
            categories={categories}
            updateCategory={updateCategory}
            removeCategory={removeCategory}
            category={addCategory}
            createCategory={createCategory}
          />
        </div>
      )}
    </>
  );
};
export default categoryView;
