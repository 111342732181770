import { NotificationList, RoleList } from 'modules/general/general.type';
import { IfetchNotications } from 'modules/params/type';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const FetchingNotications = (state: IfetchNotications) => ({
  type: ACTIONS.NOTIFICATION_FETCHING,
  payload: state,
});

export const NotificationFetched = {
  pending: {
    type: ACTIONS.NOTIFICATION_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: NotificationList) => ({
    type: ACTIONS.NOTIFICATION_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.NOTIFICATION_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const CreateNotification = (data: RoleList) => ({
  type: ACTIONS.NOTIFICATION_CREATE_CALL,
  payload: data,
});

export const DeleteNotification = (id: string) => ({
  type: ACTIONS.NOTIFICATION_DELETE_CALL,
  payload: id,
});

export const DeleteNotificationStatus = {
  pending: {
    type: ACTIONS.NOTIFICATION_DELETE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.NOTIFICATION_DELETE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.NOTIFICATION_DELETE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const CreateNotificationStatus = {
  pending: {
    type: ACTIONS.NOTIFICATION_CREATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.NOTIFICATION_CREATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.NOTIFICATION_CREATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
