import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from 'components/controls/button/button';
import Header from 'components/topHeader/topHeader';
import buttonStyle from 'components/controls/button/button.module.css';
import LoginStyle from 'views/login/login.module.css';
import { useHistory } from 'react-router';
import { checkEmail } from 'utils/FormValidation';
import { useSelector } from 'react-redux';
import { loginError, loginInProgress } from 'store/selector/auth.selector';
// import { Link } from 'react-router-dom';
import CustomInput from 'components/controls/input/customInput/CustomInput';

interface LoginProps {
  loginRequest: Function;
}

const Login: FunctionComponent<LoginProps> = (props) => {
  const { loginRequest } = props;
  const history = useHistory();

  const isLoading = useSelector(loginInProgress);
  const authError = useSelector(loginError);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleLogin = () => {
    isButtonEnabled();
    if (email.length < 1 || password.length < 1 || emailError.length > 0 || isLoading) {
      return;
    }
    loginRequest({
      email,
      password,
    });
  };

  const token = localStorage.getItem('cpa_accessToken');
  useEffect(() => {
    if (token) {
      history.push('/dashboard');
    }
  });

  // const handleSingup = () => {
  //   history.push('/signupcategory');
  // };

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };
  const isButtonEnabled = () => {
    setEmailError(checkEmail(email));
  };

  const handleBlur = (event: React.FormEvent<HTMLInputElement>) => {
    const val = event.currentTarget.value;
    setEmailError(checkEmail(val));
  };

  return (
    <div>
      <div>
        <Header />
        <div className={LoginStyle.backgroundWrapper}>
          <h1>
            Login to
            <span>&nbsp;Casting </span>
            Pax
          </h1>
          <div className={LoginStyle.loginWrapper}>
            <div>
              <CustomInput
                errorMessage={emailError}
                blurHandler={handleBlur}
                handleChange={setEmail}
                type="email"
                placeholder="Email Address"
                cssClass={emailError ? 'border-danger' : ''}
              />
              <CustomInput
                placeholder="Password"
                type="password"
                handleChange={setPassword}
                handleKeyDown={handleKeyDownEvent}
              />
            </div>
            <h5 className={LoginStyle.errorMessage}>{authError}</h5>
            <div className={`${buttonStyle.loginBtnWrapper} ${LoginStyle.mT20}`}>
              <div>
                <Button label="Login" cssClass="login-btn w-100" clickHandler={handleLogin} showLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
