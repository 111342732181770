import { InvoiceParams } from 'modules/params/type';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const fethingInvoices = (payload: InvoiceParams) => ({
  type: ACTIONS.INVOICES_FETCH_CALL,
  payload,
});

export const fetchInvoices = {
  pending: {
    type: ACTIONS.INVOICES_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.INVOICES_FETCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.INVOICES_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
