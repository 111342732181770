import React, { FunctionComponent } from 'react';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
  page: number;
  totalPages: number;
  handlePagination: (selectedItem: any) => void;
}

const Pagination: FunctionComponent<PaginationProps> = (props: PaginationProps) => {
  const { page, totalPages, handlePagination } = props;
  console.log('page', page);
  return (
    <ReactPaginate
      previousLabel=""
      nextLabel=""
      breakLabel="..."
      breakClassName="break-me"
      pageCount={totalPages}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={handlePagination}
      containerClassName="pagination"
      activeClassName="active"
    />
  );
};

export default Pagination;
