import createSelector from 'utils/reselect';

const EmailCampaignStateData = (state: any) => {
  const { emailCampaignReducer } = state;
  return emailCampaignReducer || {};
};

export const getEmailCampaignsSelector = createSelector(
  EmailCampaignStateData,
  (emailcampaignreducer) => emailcampaignreducer.get('emailCampaigns') || [],
);
export const getPaginationSelector = createSelector(EmailCampaignStateData, (emailcampaignreducer) =>
  emailcampaignreducer.get('pagination'),
);

export const getLoadingSelector = createSelector(EmailCampaignStateData, (emailcampaignreducer) =>
  emailcampaignreducer.get('loading'),
);

export const getDataLoading = createSelector(
  EmailCampaignStateData,
  (emailcampaignreducer) => emailcampaignreducer.get('dataLoading') || false,
);
