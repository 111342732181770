import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';
import { ICategory } from 'modules/params/type';
import { SkillCategory, SkillCategoryList } from 'modules/general/general.type';

export const FetchingCategories = () => ({
  type: ACTIONS.CATEGORY_FETCH_CALL,
});

export const CategoriesFetched = {
  pending: {
    type: ACTIONS.CATEGORY_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: SkillCategoryList) => ({
    type: ACTIONS.CATEGORY_FETCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CATEGORY_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const AddingCategory = (data: ICategory) => ({
  type: ACTIONS.CATEGORY_CREATE_CALL,
  payload: data,
});

export const AddCategory = (data: SkillCategory) => ({
  type: ACTIONS.CATEGORY_CREATE,
  data,
});

export const CategoryUpdate = (data: ICategory) => ({
  type: ACTIONS.CATEGORY_UPDATE_CALL,
  payload: data,
});

export const DeletingCategory = (id: string) => ({
  type: ACTIONS.CATEGORY_DELETE_CALL,
  payload: id,
});

export const DeleteCategory = {
  pending: {
    type: ACTIONS.CATEGORY_DELETE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CATEGORY_DELETE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CATEGORY_DELETE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const UpdateCategory = {
  pending: {
    type: ACTIONS.CATEGORY_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CATEGORY_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CATEGORY_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
