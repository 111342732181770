import Heading from 'components/controls/heading/Heading';
import { User, UserList } from 'modules/user/types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateShowAccountBegin } from 'store/actions/show.actions';
import { FETCH_STATUS } from 'store/castingPax.constants';
import { getUpdatingUserId } from 'store/selector/show.selector';
import Commission from 'views/show/commision';

interface IShowUserProps {
  showId: string;
  title: string;
  users: UserList;
  ownerId: string;
}
const ShowUsers: FunctionComponent<IShowUserProps> = (props: IShowUserProps) => {
  const { users, title, showId, ownerId } = props;
  const getUserId = () => {
    const ulist = users.filter((u: User) => u.amount !== 0);
    if (ulist.length > 0) return ulist[0].id;
    return '';
  };

  const [selectedUserId, setSelectedUserId] = useState<string>(getUserId());
  const [prevUserId, setPrevUserId] = useState<string>(getUserId());
  const [enableInput, setEnableInput] = useState<boolean>(false);
  const updatingUserId = useSelector(getUpdatingUserId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Object.keys(updatingUserId).length > 0) {
      if (updatingUserId.id) {
        const index = users.findIndex((u: User) => u.id === updatingUserId.id);
        if (index !== -1 && updatingUserId.status !== FETCH_STATUS.LOADING) {
          setSelectedUserId(getUserId());
          setEnableInput(false);
        }
      }
    }
  }, [updatingUserId]);

  const cancelCommision = () => {
    setSelectedUserId(getUserId());
    setEnableInput(false);
  };

  const checkUsers = () => (users.length === 0 ? `No ${title}` : '');

  const handleEditable = () => {
    setEnableInput(true);
  };

  const handleEnableFields = (event: any, user: User) => {
    if (!event.target.checked) {
      if (selectedUserId === prevUserId) {
        dispatch(updateShowAccountBegin({ showId, id: selectedUserId, amount: 0 }));
      }
      setEnableInput(false);
      setSelectedUserId('');
      setPrevUserId('');
    } else {
      setEnableInput(prevUserId !== user.id);
      setSelectedUserId(user.id);
    }
  };

  const handleSaveCommission = (commission: number) => {
    const obj = { showId, id: selectedUserId, amount: commission };

    const pUserId = users.filter((u: User) => u.amount);
    if (pUserId.length > 0 && pUserId[0].id !== selectedUserId) {
      Object.assign(obj, { prevId: pUserId[0].id });
    }

    dispatch(updateShowAccountBegin(obj));
    setPrevUserId(selectedUserId);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <Heading label={`${title} users `} />
        </div>
      </div>
      <br />
      {checkUsers()}
      {users &&
        users.map((u: User, index: number) => (
          <Commission
            key={index}
            selectedUserId={selectedUserId}
            user={u}
            enableInput={enableInput}
            prevUserId={prevUserId}
            isOwner={ownerId === u.id}
            isLoading={updatingUserId && u.id === updatingUserId.id && updatingUserId.status === FETCH_STATUS.LOADING}
            handleEnableFields={handleEnableFields}
            cancelCommision={cancelCommision}
            handleEditable={handleEditable}
            handleSaveCommision={handleSaveCommission}
          />
        ))}
    </>
  );
};

export default ShowUsers;
