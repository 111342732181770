import { fromJS } from 'immutable';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

const initialState = fromJS({
  user: {},
  loading: false,
  error: '',
  generalSetting: {},
  dataLoading: false,
});

export default function userReducer(state = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.LOGIN.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.LOGIN.SUCCESS: {
      return state.set('loading', false).set('error', '').set('user', action.data).set('refreshPusher', true);
    }
    case ACTIONS.LOGIN.ERROR: {
      console.log('Error', action);
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.GENERAL_SETTING_FETCHED.PENDING: {
      return state.set('dataLoading', action.status === FETCH_STATUS.LOADING).set('error', '');
    }
    case ACTIONS.GENERAL_SETTING_FETCHED.SUCCESS: {
      return state.set('generalSetting', action.data).set('dataLoading', false).set('error', '');
    }
    case ACTIONS.GENERAL_SETTING_FETCHED.ERROR: {
      return state.set('dataLoading', false).set('error', action.error);
    }
    default: {
      return state;
    }
  }
}
