import Button from 'components/controls/button/button';
import Modal from 'components/controls/Modal/Modal';
import { IUnionStatus } from 'modules/params/type';
import { UnionStatusList, UnionStatus } from 'modules/user/types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import ModalBody from 'views/unionStatus/modalBody';
import Item from 'views/unionStatus/item';
import Loader from 'components/controls/Loader/Loader';

interface UnionStatusProps {
  createUnionStatus: Function;
  getUnionStatus: Function;
  deleteUnionStatus: Function;
  updateUnionStatus: Function;
  loader: boolean;
  isLoading: boolean;
  unionStatusData: UnionStatusList;
}

const unionStatusView: FunctionComponent<UnionStatusProps> = (props) => {
  const {
    createUnionStatus,
    loader,
    unionStatusData,
    getUnionStatus,
    deleteUnionStatus,
    updateUnionStatus,
    isLoading,
  } = props;
  const [selectedId, setSelectedId] = useState<string>();
  const [isshow, setIsShow] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [shortName, setShortName] = useState<string>('');
  const [rate, setRate] = useState<any>();
  const [loading, setLoading] = useState<boolean>(loader);
  const [unionStatusList, setUnionStatusList] = useState<UnionStatusList>(unionStatusData);
  const [selectedUnionStatus, setSelectedUnionStatus] = useState<UnionStatus>();

  useEffect(() => {
    getUnionStatus();
  }, []);
  useEffect(() => {
    setLoading(loader);
    if (!loader) {
      setIsShow(false);
      setSelectedId('');
      setMode('');
    }
  }, [loader]);

  useEffect(() => {
    setSelectedId('');
    setIsShow(false);
    setMode('');
    setUnionStatusList(unionStatusData);
  }, [unionStatusData]);

  const handleAddUnionStatus = () => {
    setMode('add');
    setIsShow(true);
  };

  const modalBody = () => (
    <ModalBody
      name={name}
      shortName={shortName}
      rate={rate}
      clickHandle={updateModelData}
      clickCancle={closeModal}
      loader={loading}
    />
  );

  const closeModal = () => {
    setIsShow(false);
    setMode('');
    setName('');
    setShortName('');
    setRate(null);
  };

  const updateModelData = (data: IUnionStatus) => {
    if (mode === 'add') createUnionStatus(data);
    if (mode === 'edit') {
      const item = data;
      if (selectedUnionStatus) item.id = selectedUnionStatus.id;
      updateUnionStatus(item);
    }
  };

  const handleChangeUnionStatus = (item: UnionStatus) => {
    setName(item.name);
    setSelectedUnionStatus(item);
    setShortName(item.short);
    setRate(item.rate);
    setMode('edit');
    setIsShow(true);
  };

  const handleDeleteLocation = (item: string) => {
    setSelectedId(item);
    setMode('delete');
    deleteUnionStatus(item);
  };

  return (
    <div className="card">
      <div className="card-header" style={{ textAlign: 'center' }}>
        Union Status
        <div style={{ float: 'right' }}>
          <Button label="Add Union Status" clickHandler={handleAddUnionStatus} />
        </div>
      </div>
      <div className="card-body">
        {isLoading ? (
          <Loader isSmallView />
        ) : (
          <div className="row justify-content-center">
            <Table style={{ textAlign: 'center', width: '80%' }} striped bordered hover>
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Name</th>
                  <th>Short Name</th>
                  <th>Rate</th>
                  <th style={{ width: '40%' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {unionStatusList &&
                  unionStatusList.map((u: any, index: number) => (
                    <Item
                      deleteUnionStatus={handleDeleteLocation}
                      updateUnionStatus={handleChangeUnionStatus}
                      selectedunionId={selectedId}
                      key={index}
                      sr={index}
                      unionStatus={u}
                    />
                  ))}
              </tbody>
            </Table>
            {isshow && (
              <Modal
                title={mode === 'add' ? 'Enter New Union Status' : 'Update Union Status'}
                body={modalBody}
                closeModal={closeModal}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default unionStatusView;
