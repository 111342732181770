import { InvoiceParams } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import { URL_INVOICES } from 'store/services/URL';

export const api = {
  getInvoices(payload: InvoiceParams) {
    return apiCall.post({ url: URL_INVOICES, isAuthToken: true, params: payload });
  },
};

export default api;
