import { connect } from 'react-redux';
import Location from 'views/location/Location';
import { fetchinglocation, LocationUpdating, CreateLocation, DeleteLocation } from 'store/actions/location.actions';
import { getDataLoading, GetLocationSelector, LocationProgress } from 'store/selector/location.selector';

const mapDispatchToProps = {
  getLocations: fetchinglocation,
  updateLocation: LocationUpdating,
  addLocation: CreateLocation,
  deleteLocation: DeleteLocation,
};
const mapStateToProps = (state: any) => {
  const Locations = GetLocationSelector(state);
  const Loading = LocationProgress(state);
  const isDataLoading = getDataLoading(state);
  return {
    locations: Locations,
    loading: Loading,
    isDataLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Location);
