import { SubscriptionPlan } from 'modules/user/types';
import { connect } from 'react-redux';
import { FetchingPlans, PlanUpdating } from 'store/actions/subscription.actions';
import { getLoading, GetPlanSelector } from 'store/selector/subscription.selector';
import Subscripton from 'views/subscription/Subscription';

const mapDisptachToprops = {
  getPlans: FetchingPlans,
  updatePlan: PlanUpdating,
};
const mapStateToProps = (state: any) => {
  const Plans: SubscriptionPlan[] = GetPlanSelector(state);
  const isLoading = getLoading(state);
  return {
    plans: Plans,
    isLoading,
  };
};

export default connect(mapStateToProps, mapDisptachToprops)(Subscripton);
