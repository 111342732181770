import { all, call, takeEvery, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/role.services';
import { roleActions } from 'store/actions';
import { RoleList } from 'modules/general/general.type';

function* GetRoles() {
  try {
    const response: RoleList = yield call(api.getRoles);
    if (response) yield put(roleActions.RoleFetched(response));
  } catch (error: any) {
    yield console.log('error', error.message);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTIONS.ROLE_FETCHING, GetRoles)]);
}
