import React, { FunctionComponent, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Modal from 'components/controls/Modal/Modal';
import Button from 'components/controls/button/button';
import { Location } from 'modules/user/types';
import Item from 'views/location/item';
import ModalBody from 'views/location/ModelBody';
import Loader from 'components/controls/Loader/Loader';

interface Locationprops {
  getLocations: Function;
  updateLocation: Function;
  addLocation: Function;
  deleteLocation: Function;
  loading: boolean;
  isDataLoading: boolean;
  locations: Location[];
}
const locationListing: FunctionComponent<Locationprops> = (props: Locationprops) => {
  const { getLocations, updateLocation, deleteLocation, addLocation, locations, loading, isDataLoading } = props;
  const [loader, setLoader] = useState<boolean>(loading);
  const [locationList, setLocations] = useState<Location[]>(locations);
  const [isshow, setIsShow] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('');
  const [selectLocation, setSelectLocation] = useState<any>('');
  const [locationName, setLocationName] = useState<string>('');
  const [selectedId, setSelectedId] = useState<string>();

  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    setLocations(locations);
    closeModal();
  }, [locations]);

  useEffect(() => {
    setLoader(loading);
    if (!loading) setSelectedId('');
  }, [loading]);

  const closeModal = () => {
    setLoader(false);
    setIsShow(false);
    setSelectLocation('');
    setLocationName('');
    setMode('');
  };

  const modalBody = () => (
    <ModalBody locationName={locationName} clickHandle={updateModelData} clickCancle={closeModal} loader={loader} />
  );

  const updateModelData = (item: any) => {
    if (mode === 'add') {
      addLocation(item);
    } else {
      const data = { id: selectLocation.id, name: item };
      updateLocation(data);
    }
  };

  const handleChangeLocation = (item: Location) => {
    setSelectLocation(item);
    setLocationName(item.name);
    setMode('edit');
    setIsShow(true);
  };

  const changeMode = (data: string) => {
    setMode(data);
  };

  const handleAddLocation = () => {
    setMode('add');
    setIsShow(true);
  };

  const handleDeleteLocation = (data: Location) => {
    setSelectedId(data.id);
    deleteLocation(data.id);
  };

  return (
    <div className="card">
      <div className="card-header" style={{ textAlign: 'center' }}>
        Locations
        <div style={{ float: 'right' }}>
          <Button label="Add Location" clickHandler={handleAddLocation} />
        </div>
      </div>
      <div className="card-body">
        {isDataLoading ? (
          <Loader isSmallView />
        ) : (
          <div className="row justify-content-center">
            <Table style={{ textAlign: 'center', width: '70%' }} striped bordered hover>
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>Name</th>
                  <th style={{ width: '40%' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {locationList &&
                  locationList.map((location: any, index: number) => (
                    <Item
                      selectedId={selectedId}
                      key={index}
                      sr={index}
                      location={location}
                      deleteLocation={handleDeleteLocation}
                      changeLocation={handleChangeLocation}
                      changeMode={changeMode}
                    />
                  ))}
              </tbody>
            </Table>
            {isshow ? (
              <Modal
                title={mode === 'add' ? 'Enter New Location' : 'Update Location'}
                body={modalBody}
                closeModal={closeModal}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
export default locationListing;
