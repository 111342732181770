import React, { FunctionComponent, useEffect, useState } from 'react';
import { HelpCenterItem, HelpCenterList } from 'modules/general/general.type';
import Tree from 'views/helpcenter/helpcentertree';
import Loader from 'components/controls/Loader/Loader';

interface IHelpcenter {
  getHelpCenterList: Function;
  createHelpCenterParent: Function;
  createHelpCenterChildren: Function;
  removeHelpCenterParent: Function;
  removeHelpCenterChildren: Function;
  updateHelpCenterParent: Function;
  updateHelpCenterChildren: Function;
  helpCenterList: HelpCenterList;
  helpCenter: HelpCenterItem;
  isDataLoading: boolean;
}
const Helpcenter: FunctionComponent<IHelpcenter> = (props: IHelpcenter) => {
  const {
    getHelpCenterList,
    helpCenterList,
    helpCenter,
    createHelpCenterParent,
    createHelpCenterChildren,
    removeHelpCenterParent,
    removeHelpCenterChildren,
    updateHelpCenterParent,
    updateHelpCenterChildren,
    isDataLoading,
  } = props;
  const [helpList, setHelpList] = useState<HelpCenterList>(helpCenterList);
  useEffect(() => {
    getHelpCenterList();
  }, []);
  useEffect(() => {
    setHelpList(helpCenterList);
  }, [helpCenterList]);
  return (
    <div>
      {isDataLoading ? (
        <Loader isSmallView />
      ) : (
        <Tree
          helpCenter={helpCenter}
          items={helpList}
          removeHelpCenterChildren={removeHelpCenterChildren}
          removeHelpCenterParent={removeHelpCenterParent}
          createHelpCenterChildren={createHelpCenterChildren}
          createHelpCenterParent={createHelpCenterParent}
          updateHelpCenterParent={updateHelpCenterParent}
          updateHelpCenterChildren={updateHelpCenterChildren}
        />
      )}
    </div>
  );
};

export default Helpcenter;
