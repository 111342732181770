import { spawn, all } from 'redux-saga/effects';
import authSagas from 'store/sagas/auth.sagas';
import userSagas from 'store/sagas/user.sagas';
import categorSagas from 'store/sagas/category.sagas';
import jobSagas from 'store/sagas/job.sagas';
import subscriptionSagas from 'store/sagas/subscription.sagas';
import locationSagas from 'store/sagas/location.sagas';
import noticationSagas from 'store/sagas/notification.sagas';
import helpCenterSagas from 'store/sagas/helpcenter.sagas';
import defaultTypeSagas from 'store/sagas/defaulttype.sagas';
import emailCampaignSagas from 'store/sagas/emailcampaign.sagas';
import dashbordSagas from 'store/sagas/dashboard.sagas';
import roleSagas from 'store/sagas/role.sagas';
import unionStatusSagas from 'store/sagas/unionStatus.sagas';
import invoicesSagas from 'store/sagas/invoices.sagas';
import showSagas from 'store/sagas/show.sagas';
import rateSagas from 'store/sagas/rate.sagas';

export default function* rootSaga() {
  yield all([
    spawn(authSagas),
    spawn(userSagas),
    spawn(categorSagas),
    spawn(jobSagas),
    spawn(subscriptionSagas),
    spawn(locationSagas),
    spawn(noticationSagas),
    spawn(helpCenterSagas),
    spawn(defaultTypeSagas),
    spawn(emailCampaignSagas),
    spawn(dashbordSagas),
    spawn(roleSagas),
    spawn(unionStatusSagas),
    spawn(invoicesSagas),
    spawn(showSagas),
    spawn(rateSagas),
  ]);
}
