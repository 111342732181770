import React, { FunctionComponent, useEffect, useState } from 'react';
import Pagination from 'components/controls/pagination/Pagination';
import { defaultShowPagination } from 'helpers/utils';
import { Table } from 'react-bootstrap';
import Item from 'views/show/item';
import { ShowPagination as Paginationtype } from 'modules/response/response.types';
import { Show, ShowList } from 'modules/Show/show.types';
import Modal from 'components/controls/Modal/Modal';
import ModalBodyItem from 'views/show/modalBody';
import videoSvg from 'assets/svg/video.svg';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import Loader from 'components/controls/Loader/Loader';

interface IShowProps {
  getShows: Function;
  shows: ShowList;
  pagination: Paginationtype;
  loading: boolean;
}
const showComponent: FunctionComponent<IShowProps> = (props: IShowProps) => {
  const { getShows, shows, pagination, loading } = props;
  const [showPagination, setShowPagination] = useState<Paginationtype>(defaultShowPagination());
  const [allShows, setAllShows] = useState<ShowList>(shows);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedShow, setSelectedShow] = useState<any>();
  const [searchShow, setSearchShow] = useState<string>();

  useEffect(() => {
    LoadData(1);
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => HandleSearchShows(), 500);
    return () => clearTimeout(timeOutId);
  }, [searchShow]);

  useEffect(() => {
    setAllShows(shows);
    setShowPagination(pagination);
  }, [shows]);

  const HandleSearchShows = () => LoadData(1);

  const LoadData = (page: number) => {
    getShows({ page, limit: 10, text: searchShow });
  };

  const handlePages = (updatePage: any) => {
    LoadData(updatePage.selected + 1);
  };

  const showModal = () => setIsShow(true);

  const closemodel = () => {
    setIsShow(false);
    setSelectedShow('');
  };

  const modelbody = () => <ModalBodyItem item={selectedShow} clickCancel={closemodel} />;

  const loadComponent = () => (
    <>
      <Table style={{ textAlign: 'center' }} striped bordered hover>
        <thead>
          <tr>
            <th>Sr#</th>
            <th>Show Title</th>
            <th>Show Type</th>
            <th>Users</th>
            <th>Show Detail</th>
          </tr>
        </thead>
        <tbody>
          {allShows &&
            allShows.map((show: Show, index: number) => (
              <Item key={index} serialNo={index} show={show} showModal={showModal} selectShow={setSelectedShow} />
            ))}
        </tbody>
      </Table>
      <br />
    </>
  );
  return (
    <div className="card">
      <div className="card-header" style={{ textAlign: 'center', width: '100%' }}>
        All Shows
      </div>
      <div className="card-body">
        <CustomInput type="text" placeholder="Search by show title and user name" handleChange={setSearchShow} />
        {loading ? <Loader isSmallView /> : loadComponent()}
        {showPagination.total > 1 && (
          <Pagination
            page={showPagination.page}
            totalPages={
              showPagination.total > showPagination.limit ? Math.ceil(showPagination.total / showPagination.limit) : 1
            }
            handlePagination={handlePages}
          />
        )}
      </div>
      {isShow && <Modal maxWidth icon={videoSvg} title={selectedShow.title} body={modelbody} closeModal={closemodel} />}
    </div>
  );
};

export default showComponent;
