import { all, call, takeEvery, put, takeLatest } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/user.services';
import ApiResponse from 'modules/response/response.types';
import { userActions } from 'store/actions';
import { IUserStatus, IfetchUsers } from 'modules/params/type';

function* getUsers(state: { type: string; payload: IfetchUsers }) {
  try {
    yield put(userActions.userfetch.pending);
    const response: ApiResponse = yield call(api.getUsers, state.payload);
    if (response) yield put(userActions.userfetch.success(response));
  } catch (error: any) {
    yield put(userActions.userfetch.error(error.message));
  }
}

function* changeUserStatus(state: { type: string; payload: IUserStatus }) {
  try {
    yield put(userActions.updateUserStatus.pending);
    const response: ApiResponse = yield call(api.updateUserStatus, state.payload);
    if (response) yield put(userActions.updateUserStatus.success(state.payload));
  } catch (error: any) {
    yield put(userActions.updateUserStatus.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([takeLatest(ACTIONS.USER_FETCHING, getUsers), takeEvery(ACTIONS.USER_STATUS, changeUserStatus)]);
}
