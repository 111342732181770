import createSelector from 'utils/reselect';

const rateStateData = (state: any) => {
  const { rateReducer } = state;
  return rateReducer || {};
};

export const getRates = createSelector(rateStateData, (rateReducer) => rateReducer.get('rates') || []);

export const getRateLoading = createSelector(rateStateData, (rateReducer) => rateReducer.get('fetchLoading') || false);

export const getIsLoading = createSelector(rateStateData, (rateReducer) => rateReducer.get('loading') || false);
