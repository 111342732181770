import { IHelpcenter, IparamHelpcenterorder } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import {
  URL_HELPCENTER,
  URL_HELPCENTER_PARENT_CREATE,
  URL_HELPCENTER_CHILDREN_CREATE,
  URL_HELPCENTER_PARENT_DELETE,
  URL_HELPCENTER_CHILDREN_DELETE,
  URL_HELPCENTER_REORDER,
} from 'store/services/URL';

export const api = {
  GetHelpcenterList() {
    return apiCall.get({ url: URL_HELPCENTER, isAuthToken: true, params: {} });
  },
  Createparenthelpcenter(data: IHelpcenter) {
    return apiCall.post({ url: URL_HELPCENTER_PARENT_CREATE, isAuthToken: true, params: data });
  },
  Createchildrenhelpcenter(data: IHelpcenter) {
    return apiCall.post({ url: URL_HELPCENTER_CHILDREN_CREATE, isAuthToken: true, params: data });
  },
  Deleteparenthelpcenter(id: string) {
    return apiCall.post({ url: URL_HELPCENTER_PARENT_DELETE, isAuthToken: true, params: id });
  },
  Deletechildrenhelpcenter(id: string) {
    return apiCall.post({ url: URL_HELPCENTER_CHILDREN_DELETE, isAuthToken: true, params: id });
  },
  Updateparenthelpcenter(payload: IHelpcenter) {
    return apiCall.put({ url: URL_HELPCENTER_PARENT_CREATE, isAuthToken: true, params: payload });
  },
  Updatechildrenhelpcenter(payload: IHelpcenter) {
    return apiCall.put({ url: URL_HELPCENTER_CHILDREN_CREATE, isAuthToken: true, params: payload });
  },
  ReOrderSkills(params: IparamHelpcenterorder) {
    return apiCall.post({ url: URL_HELPCENTER_REORDER, isAuthToken: true, params });
  },
};

export default api;
