import React, { FunctionComponent } from 'react';
import { formateDate } from 'helpers/utils';
import Style from 'views/job/job.module.css';

interface ItemProps {
  job: any;
}

const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { job } = props;

  return (
    <tr>
      <td>{job.showId.title}</td>
      <td>{job.noPerformerRequired}</td>
      <td style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {job.dates &&
          job.dates.map((d: any, index: number) => (
            <div className={`mr-4 ${Style.dateBoxWrapper}`} key={index}>
              <p className="mb-0">{formateDate(d || '', 'MMM')}</p>
              <h6 className="mb-0">{formateDate(d || '', 'DD')}</h6>
            </div>
          ))}
      </td>
      <td>{job.location ? job.location.name : 'null'}</td>
      <td>
        {job.createdBy.firstName}
        &nbsp;
        {job.createdBy.lastName}
      </td>
    </tr>
  );
};

export default Item;
