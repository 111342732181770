import { all, call, takeEvery, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/category.services';
import ApiResponse from 'modules/response/response.types';
import { categoryActions } from 'store/actions';
import { ICategory } from 'modules/params/type';
import { SkillCategory, SkillCategoryList } from 'modules/general/general.type';

function* GetCategories() {
  try {
    yield put(categoryActions.CategoriesFetched.pending);
    const response: SkillCategoryList = yield call(api.GetCategories);
    if (response) yield put(categoryActions.CategoriesFetched.success(response));
  } catch (error: any) {
    yield put(categoryActions.CategoriesFetched.error(error.message));
  }
}
function* UpdateCategory(state: { type: string; payload: ICategory }) {
  try {
    yield put(categoryActions.UpdateCategory.pending);
    const response: ApiResponse = yield call(api.UpdateCategory, state.payload);
    if (response) yield put(categoryActions.UpdateCategory.success(response));
  } catch (error: any) {
    yield put(categoryActions.UpdateCategory.error(error.message));
  }
}

function* AddCategory(state: { type: string; payload: ICategory }) {
  try {
    const response: SkillCategory = yield call(api.AddCategory, state.payload);
    if (response) yield put(categoryActions.AddCategory(response));
  } catch (error: any) {
    yield console.log(error);
  }
}

function* DeleteCategory(state: { type: string; payload: string }) {
  try {
    yield put(categoryActions.DeleteCategory.pending);
    const response: ApiResponse = yield call(api.DeleteCategory, state.payload);
    if (response) yield put(categoryActions.DeleteCategory.success({ id: state.payload, status: response }));
  } catch (error: any) {
    yield put(categoryActions.DeleteCategory.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.CATEGORY_FETCH_CALL, GetCategories),
    takeEvery(ACTIONS.CATEGORY_CREATE_CALL, AddCategory),
    takeEvery(ACTIONS.CATEGORY_UPDATE_CALL, UpdateCategory),
    takeEvery(ACTIONS.CATEGORY_DELETE_CALL, DeleteCategory),
  ]);
}
