import { getChartData, getLastThirtyDays } from 'helpers/utils';
import createSelector from 'utils/reselect';

const dashboardStateData = (state: any) => {
  const { dashboardReducer } = state;
  return dashboardReducer || {};
};

export const GetJobsSelector = createSelector(
  dashboardStateData,
  (dashboardReducer) => dashboardReducer.get('jobs') || [],
);
export const GetUsersSelector = createSelector(
  dashboardStateData,
  (dashboardReducer) => dashboardReducer.get('allUsers') || [],
);

export const getJobChart = createSelector(dashboardStateData, (DashboardReducer) => {
  const data: any = DashboardReducer.getIn(['chartData', 'jobs'] || []);
  const datesData: string[] = getLastThirtyDays();
  if (data) {
    const bookedData: number[] = getChartData([...datesData], data.booked);
    const requestedData: number[] = getChartData([...datesData], data.requested);
    const cancelledData: number[] = getChartData([...datesData], data.cancelled);
    const pendingData: number[] = getChartData([...datesData], data.pending);

    const obj = {
      booked: bookedData,
      requested: requestedData,
      cancelled: cancelledData,
      pending: pendingData,
      maxValue: data.maxVal + 2,
    };
    return obj;
  }
  return [];
});

export const getUserChart = createSelector(dashboardStateData, (DashboardReducer) => {
  const data: any = DashboardReducer.getIn(['chartData', 'users'] || []);
  const datesData = getLastThirtyDays();
  if (data) {
    const directorData: number[] = getChartData([...datesData], data.director);
    const productionData: number[] = getChartData([...datesData], data.production);
    const coordinatorData: number[] = getChartData([...datesData], data.coordinator);
    const performerData: number[] = getChartData([...datesData], data.performer);
    const obj = {
      director: directorData,
      production: productionData,
      coordinator: coordinatorData,
      performer: performerData,
      maxValue: data.maxVal + 2,
    };
    return obj;
  }
  return [];
});

export const getChartLoading = createSelector(
  dashboardStateData,
  (dashboardReducer) => dashboardReducer.get('chartLoading') || false,
);
