import React, { FunctionComponent, useEffect, useState } from 'react';
import Button from 'components/controls/button/button';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import { User } from 'modules/user/types';
import { ROLE_DIRECTOR_ID } from 'store/castingPax.constants';
import pencilSvg from 'assets/svg/edit.svg';
import Style from 'views/show/show.module.css';
import { getImageThumbnail } from 'helpers/utils';

interface ICommissionProps {
  user: User;
  selectedUserId: string | undefined;
  isLoading: boolean;
  enableInput: boolean;
  handleSaveCommision: Function;
  handleEnableFields: Function;
  cancelCommision: Function;
  handleEditable: Function;
  isOwner: boolean;
  prevUserId: string | undefined;
}

const Commission: FunctionComponent<ICommissionProps> = (props: ICommissionProps) => {
  const {
    handleSaveCommision,
    cancelCommision,
    isLoading,
    enableInput,
    user,
    selectedUserId,
    handleEditable,
    handleEnableFields,
    isOwner,
    prevUserId,
  } = props;
  const [commission, setCommission] = useState<any>(user.amount);

  const handleCancel = () => {
    if (user.amount === 0) setCommission(0);
    cancelCommision(user.id);
  };

  useEffect(() => {
    setCommission(user.amount);
  }, [selectedUserId, user.amount]);

  return (
    <div className="row">
      <div className="col-md-1">
        <img className={Style.imagePreviewModal} src={getImageThumbnail(user.profilePicUrl)} alt="" />
      </div>
      <div className="col-md-4">
        {user.firstName}
        {isOwner && '(Owner)'}
        <br />
        {user.email}
      </div>
      <div className="col-md-1" style={{ padding: '0px' }}>
        <div className="custom-control custom-switch">
          <input
            checked={selectedUserId === user.id}
            type="checkbox"
            name="incentive"
            className="custom-control-input"
            id={user.id}
            onChange={(e) => handleEnableFields(e, user)}
          />
          <label className="custom-control-label" htmlFor={user.id} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="row">
          <div className="col-md-5">
            <CustomInput
              value={commission}
              isDisable={!enableInput}
              placeholder={user.showRole === ROLE_DIRECTOR_ID ? 'Incentive' : 'commission'}
              type="number"
              handleChange={setCommission}
            />
          </div>
          {!enableInput && prevUserId === user.id && (
            <div
              className="mt-2"
              onClick={() => handleEditable(user.id)}
              onKeyUp={() => handleEditable(user.id)}
              role="button"
              tabIndex={0}
            >
              <img src={pencilSvg} alt="" className={Style.pencilIcon} />
            </div>
          )}
          {enableInput && user.id === selectedUserId && (
            <div className="col-md-7">
              <Button
                showLoading={isLoading}
                label={user.amount !== 0 ? 'update' : 'save'}
                clickHandler={() => handleSaveCommision(commission)}
              />
              &ensp;
              <Button label="Cancel" type="customCancelBtn" clickHandler={handleCancel} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Commission;
