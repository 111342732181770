import { RoleList } from 'modules/general/general.type';
import ACTIONS from 'store/castingPax.constants';

export const FetchingRoles = () => ({
  type: ACTIONS.ROLE_FETCHING,
});

export const RoleFetched = (data: RoleList) => ({
  type: ACTIONS.ROLE_FETCHED,
  data,
});
