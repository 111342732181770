import emailCampaign from 'views/emailcampaign/emailcampaign';
import { connect } from 'react-redux';
import { createEmailCampaign, fetchEmailCampaign, emailCampaignUpdating } from 'store/actions/emailcampaign.actions';
import {
  getDataLoading,
  getEmailCampaignsSelector,
  getLoadingSelector,
  getPaginationSelector,
} from 'store/selector/emailCampaign.selector';
import { getRoleSelector } from 'store/selector/job.selector';

const mapDispatchToProps = {
  createCampaign: createEmailCampaign,
  getEmailCampaigns: fetchEmailCampaign,
  updateEmailCampaign: emailCampaignUpdating,
};

const mapStateToProps = (state: any) => {
  const emailCampaigns = getEmailCampaignsSelector(state);
  const pagination = getPaginationSelector(state);
  const loading = getLoadingSelector(state);
  const rolesSelector = getRoleSelector(state);
  const isDataLoading = getDataLoading(state);
  return {
    emailCampaigns,
    pagination,
    loading,
    rolesSelector,
    isDataLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(emailCampaign);
