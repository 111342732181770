import { fromJS } from 'immutable';
import { DefaultType } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

const initialState = fromJS({
  types: [],
  categories: [],
  pagination: {},
  loading: false,
  dataLoading: false,
  error: '',
});
export default function DefaulttypeReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.TYPES_FETCHED.SUCCESS: {
      return state
        .set('types', action.data.data)
        .set('pagination', action.data.pagination)
        .set('dataLoading', false)
        .set('error', '');
    }
    case ACTIONS.TYPES_FETCHED.PENDING: {
      return state.set('dataLoading', action.status === FETCH_STATUS.LOADING).set('error', '');
    }
    case ACTIONS.TYPES_FETCHED.ERROR: {
      return state.set('dataLoading', false).set('error', action.error);
    }
    case ACTIONS.DEFAULT_CATEGORIES: {
      return state.set('categories', action.data);
    }
    case ACTIONS.CREATE_TYPE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.CREATE_TYPE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.CREATE_TYPE.SUCCESS: {
      const prevtypes = state.get('types');
      prevtypes.unshift(action.data);
      return state
        .set('types', [...prevtypes])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.TYPE_UPDATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.TYPE_UPDATE.SUCCESS: {
      console.log(action.data);
      const prevtypes = state.get('types');
      const index = prevtypes.findIndex((D: DefaultType) => D.id === action.data.id);
      if (index !== -1) {
        prevtypes.splice(index, 1, action.data);
      }
      return state
        .set('types', [...prevtypes])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.TYPE_UPDATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.TYPE_DELETE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.TYPE_DELETE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.TYPE_DELETE.SUCCESS: {
      const prevtypes = state.get('types');
      const newtypes = prevtypes.filter((d: DefaultType) => d.id !== action.data.id);
      return state
        .set('types', [...newtypes])
        .set('loading', false)
        .set('error', '');
    }
    default:
      return state;
  }
}
