import { apiCall } from 'store/services/service';
import { URL_ROLE } from 'store/services/URL';

export const RoleApi = {
  getRoles() {
    return apiCall.get({ url: URL_ROLE, isAuthToken: true, params: {} });
  },
};

export default RoleApi;
