import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import Menu from 'views/menu';
import Topbar from 'views/topbar';
import User from 'views/user';
import Jobs from 'views/job';
import Subscription from 'views/subscription';
import Location from 'views/location';
import Notifcation from 'views/notication';
import Helpcenter from 'views/helpcenter';
import Category from 'views/category';
import defaulttype from 'views/defaulttype';
import Emailcampaign from 'views/emailcampaign';
import dashboard from 'views/dashboard';
import generalSetting from 'views/generalsetting';
import unionStatus from 'views/unionStatus';
import Invoices from 'views/invoices';
import shows from 'views/show';
import Rate from 'views/rate';

const LandingPage: FunctionComponent = () => {
  const onloadcomponent = () => (
    <Switch>
      <Route exact path="/dashboard/user" component={User} />
      <Route exact path="/dashboard/categories" component={Category} />
      <Route exact path="/dashboard/jobs" component={Jobs} />
      <Route exact path="/dashboard/shows" component={shows} />
      <Route exact path="/dashboard/Subscriptionplan" component={Subscription} />
      <Route exact path="/dashboard/location" component={Location} />
      <Route exact path="/dashboard/rate" component={Rate} />
      <Route exact path="/dashboard/unionStatus" component={unionStatus} />
      <Route exact path="/dashboard/notification" component={Notifcation} />
      <Route exact path="/dashboard/helpcenter" component={Helpcenter} />
      <Route exact path="/dashboard/defaulttype" component={defaulttype} />
      <Route exact path="/dashboard/emailcompaign" component={Emailcampaign} />
      <Route exact path="/dashboard/invoices" component={Invoices} />
      <Route exact path="/dashboard/generalsetting" component={generalSetting} />
      <Route exact path="/dashboard" component={dashboard} />
    </Switch>
  );

  return (
    <>
      <Topbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2">
            <Menu />
          </div>
          <div style={{ marginTop: '20px', marginLeft: '50px' }} className="col-md-9">
            {onloadcomponent()}
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
