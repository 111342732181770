import React, { FunctionComponent, useEffect } from 'react';
import PaginationComponent from 'components/controls/pagination/Pagination';
import { Pagination as PaginationType } from 'modules/response/response.types';
import { Table } from 'react-bootstrap';
import Item from 'views/invoices/Item';
import Loader from 'components/controls/Loader/Loader';

interface IInvoiceProps {
  loading: boolean;
  fetchInvoices: Function;
  totalInvoices: any;
  pagination: PaginationType;
}
const Invoices: FunctionComponent<IInvoiceProps> = (props: IInvoiceProps) => {
  const { fetchInvoices, pagination, totalInvoices, loading } = props;
  useEffect(() => {
    loadData(1);
  }, []);

  const loadData = (pageNumber: number) => {
    fetchInvoices({ page: pageNumber, limit: 10 });
  };
  const handlePages = (updatePage: any) => {
    loadData(updatePage.selected + 1);
  };
  return (
    <div className="card">
      <div className="card-header" style={{ textAlign: 'center', width: '100%' }}>
        All Invoices
      </div>
      <div className="card-body">
        {loading ? (
          <Loader isSmallView />
        ) : (
          <Table style={{ textAlign: 'center' }} striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th style={{ width: '50%' }}>Description</th>
                <th>Receive From</th>
                <th>Amount</th>
                <th>Invoice No</th>
              </tr>
            </thead>
            <tbody>
              {totalInvoices &&
                totalInvoices.map((invoice: any, index: number) => <Item key={index} invoice={invoice} />)}
            </tbody>
          </Table>
        )}
        <br />
        {pagination.totalPages > 1 && (
          <PaginationComponent
            page={pagination.page}
            totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
            handlePagination={handlePages}
          />
        )}
      </div>
    </div>
  );
};

export default Invoices;
