import { connect } from 'react-redux';
import { rateFetching, createRateBegin, deleteRateBegin, updateRateBegin } from 'store/actions/rate.actions';
import { getIsLoading, getRateLoading, getRates } from 'store/selector/rate.selector';
import Rate from 'views/rate/rate';

const mapDispatchToProps = {
  rateFetching,
  createRateBegin,
  deleteRateBegin,
  updateRateBegin,
};
const mapStateToProps = (state: any) => {
  const rates = getRates(state);
  const fetchLoading = getRateLoading(state);
  const isLoading = getIsLoading(state);
  return { rates, isLoading, fetchLoading };
};
export default connect(mapStateToProps, mapDispatchToProps)(Rate);
