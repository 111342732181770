import React, { useEffect, FunctionComponent, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Pagination as PaginationType } from 'modules/response/response.types';
import { defaultPagination } from 'helpers/utils';
import Item from 'views/user/item';
import Pagination from 'components/controls/pagination/Pagination';
import { User } from 'modules/user/types';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import GroupCheckBoxes from 'components/controls/checkbox/GroupCheckBoxes';
import { RoleList } from 'modules/general/general.type';
import Loader from 'components/controls/Loader/Loader';

interface UserProps {
  fetchUsers: Function;
  changeUserStatus: Function;
  users: User[];
  usersPagination: PaginationType;
  loading: boolean;
  rolesSelector: RoleList;
  isDataLoding: boolean;
}

const UserListing: FunctionComponent<UserProps> = (Props: UserProps) => {
  const { fetchUsers, changeUserStatus, users, usersPagination, loading, rolesSelector, isDataLoding } = Props;
  const [roles, setRoles] = useState<RoleList>(rolesSelector);
  const [selectedId, setSelectedId] = useState<string>();
  const [pagination, setPagination] = useState<PaginationType>(defaultPagination());
  const [searchUser, setSearchUser] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string[]>([]);

  useEffect(() => {
    setRoles(rolesSelector);
  }, [rolesSelector]);

  useEffect(() => {
    const timeOutId = setTimeout(() => HandleSearchUsers(), 500);
    return () => clearTimeout(timeOutId);
  }, [searchUser, selectedRole]);

  useEffect(() => {
    setPagination(usersPagination);
  }, [users]);

  useEffect(() => {
    if (!loading) setSelectedId('');
  }, [loading]);

  const LoadData = (page: number) => {
    let data: any;
    if (!selectedRole[0]) data = '';
    else data = selectedRole;
    fetchUsers({ page, limit: 10, text: searchUser, roles: data });
  };

  const handlePages = (updatePage: any) => LoadData(updatePage.selected + 1);

  const changeStatus = (item: User) => {
    const data = { id: item.id, status: !item.isActive };
    setSelectedId(item.id);
    changeUserStatus(data);
  };

  // const changeIncentive = (item: User) => {
  //   changeUserIncentive({ id: item.id, incentive: !item.isIncentiveAllow });
  // };

  const HandleSearchUsers = () => {
    LoadData(1);
  };
  const hanldeChecked = (data: any) => {
    setSelectedRole(data);
  };

  const loadComponent = () => (
    <>
      <br />
      <Table style={{ textAlign: 'center' }} striped bordered hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((user: User, index: number) => (
              <Item key={index} user={user} changeStatus={changeStatus} SelectedID={selectedId} />
            ))}
        </tbody>
      </Table>
      <br />
    </>
  );

  return (
    <>
      <div className="card">
        <div style={{ textAlign: 'center' }} className="card-header">
          All Users
        </div>
        <div className="card-body">
          <CustomInput type="text" placeholder="Search by name and email" handleChange={setSearchUser} />
          {roles.length > 0 ? <GroupCheckBoxes Items={roles} handleChecked={hanldeChecked} /> : <Loader isSmallView />}
          {isDataLoding ? <Loader isSmallView /> : loadComponent()}
          {pagination.totalPages > 1 && (
            <Pagination
              page={pagination.page}
              totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
              handlePagination={handlePages}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default UserListing;
