import createSelector from 'utils/reselect';

const showStateData = (state: any) => {
  const { showReducer } = state;
  return showReducer || {};
};

export const getShowsSelector = createSelector(showStateData, (showReducer) => showReducer.get('shows') || []);
export const getPaginationSelector = createSelector(
  showStateData,
  (showReducer) => showReducer.get('pagination') || [],
);
export const getUpdateLoading = createSelector(showStateData, (showReducer) => showReducer.get('loading') || false);
export const getUpdatingUserId = createSelector(
  showStateData,
  (showReducer) => showReducer.get('userIdUpdating') || '',
);
