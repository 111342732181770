import { IfetchNotications, INotification } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import { URL_NOTIFICATION, URL_ROLE, URL_NOTIFICATION_DELETE } from 'store/services/URL';

export const api = {
  GetNotifications(data: IfetchNotications) {
    return apiCall.get({ url: URL_NOTIFICATION, isAuthToken: true, params: data });
  },
  getRoles() {
    return apiCall.get({ url: URL_ROLE, isAuthToken: true, params: {} });
  },
  CreateNotification(data: INotification) {
    return apiCall.post({ url: URL_NOTIFICATION, isAuthToken: true, params: data });
  },
  DeleteNotification(id: string) {
    return apiCall.post({ url: URL_NOTIFICATION_DELETE, isAuthToken: true, params: { id } });
  },
};

export default api;
