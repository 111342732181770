import ApiResponse from 'modules/response/response.types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const FetchingJobs = (payload: { page: number; limit: number }) => ({
  type: ACTIONS.JOBS_FETCHING,
  payload,
});

export const JobsFetched = {
  pending: {
    type: ACTIONS.JOBS_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ApiResponse) => ({
    type: ACTIONS.JOBS_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.JOBS_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
