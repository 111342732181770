import Helpcenter from 'views/helpcenter/Helpcenter';
import { connect } from 'react-redux';
import {
  FetchingHelpcenterlist,
  AddingHelpcenterparent,
  AddingHelpcenterchildren,
  DeletingparentHelpcenter,
  DeletingchildrenHelpcenter,
  UpdatingparentHelpcenter,
  UpdatingchildrenHelpcenter,
} from 'store/actions/helpcenter.actions';
import { getHelpCenterSelector, getHelpCenterItemSelector, getDataLoading } from 'store/selector/helpcenter.selector';

const mapDispatchToProps = {
  getHelpCenterList: FetchingHelpcenterlist,
  createHelpCenterParent: AddingHelpcenterparent,
  createHelpCenterChildren: AddingHelpcenterchildren,
  removeHelpCenterParent: DeletingparentHelpcenter,
  removeHelpCenterChildren: DeletingchildrenHelpcenter,
  updateHelpCenterParent: UpdatingparentHelpcenter,
  updateHelpCenterChildren: UpdatingchildrenHelpcenter,
};
const mapStateToProps = (state: any) => {
  const HelpCenterList = getHelpCenterSelector(state);
  const HelpCenterItem = getHelpCenterItemSelector(state);
  const isDataLoading = getDataLoading(state);
  return {
    helpCenterList: HelpCenterList,
    helpCenter: HelpCenterItem,
    isDataLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Helpcenter);
