import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PlaceholderImage from 'assets/images/dropzone-placeholder.png';
import Style from 'components/controls/dropzone/Image.module.css';
import InputStyle from 'components/controls/fileInput/imageInput.module.css';
import pdfIcon from 'assets/svg/pdf_icon.svg';
import imageIcon from 'assets/svg/image_icon.svg';
import excelIcon from 'assets/svg/xls.svg';
import docIcon from 'assets/svg/doc_icon.svg';
import deleteIcon from 'assets/svg/close.svg';

interface DropZoneProps {
  handlePrevDeleteFiles?: Function;
  type: string;
  fileUploadHandler: Function;
  prevFiles?: any;
  path?: any;
}

const DropZone: FunctionComponent<DropZoneProps> = (props: DropZoneProps) => {
  const { fileUploadHandler, type, prevFiles, handlePrevDeleteFiles } = props;
  const [file, setFile] = useState<File[]>([]);
  const [prevFile, setPrevFile] = useState(prevFiles);

  useEffect(() => {
    setPrevFile(prevFiles);
  }, [prevFiles]);

  const onDrop = (acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles[0]) {
      const tmpfile: File[] = file;
      for (let i = 0; i < acceptedFiles.length; i += 1) {
        tmpfile.push(acceptedFiles[i]);
      }
      setFile([...tmpfile]);
      fileUploadHandler(tmpfile, false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: true });

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const tmpfile: File[] = file;
      for (let i = 0; i < event.target.files.length; i += 1) {
        tmpfile.push(event.target.files[i]);
      }
      setFile([...tmpfile]);
      fileUploadHandler(tmpfile, false);
    }
  };

  // const getUrl = (f: File) => URL.createObjectURL(f);

  const deleteHandle = (index: number) => {
    if (!file) return;
    const tmp: File[] = file;
    tmp.splice(index, 1);
    setFile([...tmp]);
    fileUploadHandler([...tmp], false);
  };

  const deletePrevFileHandle = (index: number) => {
    if (!prevFile) return;
    if (handlePrevDeleteFiles) handlePrevDeleteFiles(prevFiles[index]);
    const tmp: File[] = prevFile;
    tmp.splice(index, 1);
    setPrevFile([...tmp]);
    fileUploadHandler([...tmp], true);
  };
  const splitFileName = (f: string) => {
    let str = f;
    let index = str.indexOf('-');
    str = str.substring(index + 1, str.length);
    index = str.indexOf('-');
    str = str.substring(index + 1, str.length);
    return str;
  };
  const changeIcon = (f: string) => {
    const filetype: any = /[.]/.exec(f) ? /[^.]+$/.exec(f) : undefined;

    if (filetype[0] === 'png' || filetype[0] === 'jpg' || filetype[0] === 'jpeg') return imageIcon;
    if (filetype[0] === 'pdf') return pdfIcon;
    if (filetype[0] === 'xls' || filetype[0] === 'xlsx') return excelIcon;
    if (filetype[0] === 'doc' || filetype[0] === 'docx') return docIcon;
    return '';
  };

  return (
    <>
      <div className={Style.imageItemDialogWrapper} {...getRootProps()}>
        <input {...getInputProps()} onChange={onImageChange} />
        {type === 'pdf' ? (
          <>
            <img src={PlaceholderImage} alt="" className={`${Style.popupImage}`} />
            <p className={`mb-0 ${Style.popupTitle}`}>Drop the files here ...</p>
          </>
        ) : (
          <>
            <img src={PlaceholderImage} alt="" className={`${Style.popupImage}`} />
            <p className={`mb-0 ${Style.popupTitle}`}>
              Drag and drop your image here
              <br />
              or
              <span className={`mb-0 pl-2 ${Style.chooseFileText}`}> Choose your file</span>
            </p>
          </>
        )}
      </div>
      <div className={file[0] ? `${Style.maindiv}` : ''}>
        {file &&
          file.map((f: File, index: number) => (
            <div key={index} className={InputStyle.previewFiles}>
              <img src={changeIcon(f.name)} alt="file" className={`${Style.previewImage}`} />
              <div className={InputStyle.imageName}>{splitFileName(f.name)}</div>
              <img
                src={deleteIcon}
                alt=""
                onClick={() => deleteHandle(index)}
                onKeyPress={() => deleteHandle(index)}
                className={InputStyle.imagePreview}
                role="presentation"
              />
            </div>
          ))}
        {prevFile &&
          prevFile.map((f: any, index: number) => (
            <div key={index} className={InputStyle.previewFiles}>
              <img src={changeIcon(f)} alt="file" className={`${Style.previewImage}`} />
              <div className={InputStyle.imageName}>{splitFileName(f)}</div>
              <img
                src={deleteIcon}
                alt=""
                onClick={() => deletePrevFileHandle(index)}
                onKeyPress={() => deletePrevFileHandle(index)}
                className={InputStyle.imagePreview}
                role="presentation"
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default DropZone;
