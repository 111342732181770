import React, { FunctionComponent, useEffect, useState } from 'react';
import { Pagination as PaginationType } from 'modules/response/response.types';
import PaginationComponent from 'components/controls/pagination/Pagination';
import { Table } from 'react-bootstrap';
import Button from 'components/controls/button/button';
import Modal from 'components/controls/Modal/Modal';
import ModalBody from 'views/notication/Modalbody';
import { Notification, NotificationList, RoleList } from 'modules/general/general.type';
import Item from 'views/notication/item';
import Loader from 'components/controls/Loader/Loader';

interface NotificationProps {
  getNotification: Function;
  deleteNotification: Function;
  notifications: NotificationList;
  pagination: PaginationType;
  createNotification: Function;
  loading: boolean;
  roles: RoleList;
  isDataLoading: boolean;
}
const NotificationListing: FunctionComponent<NotificationProps> = (props: NotificationProps) => {
  const {
    getNotification,
    notifications,
    createNotification,
    deleteNotification,
    pagination,
    loading,
    roles,
    isDataLoading,
  } = props;
  const [Notifications, setnotification] = useState<NotificationList>(notifications);
  const [isshow, setisshow] = useState<boolean>(false);
  const [SelectedID, setSelectedId] = useState<string>();

  useEffect(() => {
    LoadData(1);
  }, []);
  useEffect(() => {
    setnotification(notifications);
    if (!loading) {
      setisshow(false);
      setSelectedId('');
    }
  }, [notifications]);

  const LoadData = (page: number) => {
    getNotification({ page, limit: 10 });
  };

  const handlePages = (updatePage: any) => {
    LoadData(updatePage.selected + 1);
  };

  const handleDelete = (id: string) => {
    setSelectedId(id);
    deleteNotification(id);
  };

  const showmodal = () => {
    setisshow(true);
  };

  const closemodel = () => {
    setisshow(false);
  };

  const modelbody = () => (
    <ModalBody loader={loading} roles={roles} ClickCancle={closemodel} ClickHandle={AddNotification} />
  );

  const AddNotification = (data: Notification) => createNotification(data);

  const loadComponent = () => (
    <Table style={{ textAlign: 'center' }} striped bordered hover>
      <thead>
        <tr>
          <th>Title</th>
          <th>Message</th>
          <th>Role</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {Notifications &&
          Notifications.map((n: Notification, index: number) => (
            <Item key={index} notification={n} deleteNotification={handleDelete} SelectedID={SelectedID} />
          ))}
      </tbody>
    </Table>
  );
  return (
    <div className="card">
      <div className="card-header" style={{ textAlign: 'center' }}>
        Notification
        <div style={{ float: 'right' }}>
          <Button label="Create Notification" clickHandler={showmodal} />
        </div>
      </div>
      <div className="card-body">
        {isDataLoading ? <Loader isSmallView /> : loadComponent()}
        <br />
        {pagination.totalPages > 1 && (
          <PaginationComponent
            page={pagination.page}
            totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
            handlePagination={handlePages}
          />
        )}
        {isshow ? <Modal title="Create New Notification" body={modelbody} closeModal={closemodel} /> : null}
      </div>
    </div>
  );
};

export default NotificationListing;
