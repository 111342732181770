import Loader from 'components/controls/Loader/Loader';
import Modal from 'components/controls/Modal/Modal';
import { Rate, RateList } from 'modules/general/general.type';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import ModalBody from 'views/rate/modalBody';
import Item from 'views/rate/item';
import Button from 'components/controls/button/button';

interface IRateProps {
  rateFetching: Function;
  createRateBegin: Function;
  deleteRateBegin: Function;
  updateRateBegin: Function;
  rates: RateList;
  isLoading: boolean;
  fetchLoading: boolean;
}
const RateComponent: FunctionComponent<IRateProps> = (props) => {
  const { rateFetching, rates, isLoading, createRateBegin, fetchLoading, deleteRateBegin, updateRateBegin } = props;
  const [selectedId, setSelectedId] = useState<string>();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('');
  const [selectedRate, setSelectedRate] = useState<Rate>({
    title: '',
    code: '',
    rate: 0,
    id: `${Math.random()}`,
    isActive: true,
  });

  useEffect(() => {
    if (!isLoading) closeModel();
  }, [isLoading]);

  useEffect(() => {
    rateFetching();
  }, []);

  const deleteRate = (id: string) => {
    setSelectedId(id);
    deleteRateBegin({ id });
  };

  const updateRate = (item: Rate) => {
    setIsShow(true);
    setMode('edit');
    setSelectedRate(item);
  };

  const closeModel = () => {
    setIsShow(false);
    setSelectedRate({
      title: '',
      code: '',
      rate: 0,
      id: `${Math.random()}`,
      isActive: true,
    });
  };

  const handleClick = (item: Rate) => {
    if (mode === 'add') createRateBegin({ title: item.title, code: item.code, rate: item.rate });
    if (mode === 'edit') updateRateBegin({ id: selectedRate.id, title: item.title, code: item.code, rate: item.rate });
  };

  const modalBody = () => (
    <ModalBody item={selectedRate} clickHandle={handleClick} loader={isLoading} clickCancle={closeModel} mode={mode} />
  );

  const handleAddRate = () => {
    setMode('add');
    setIsShow(true);
  };

  return (
    <>
      <div className="card">
        <div className="card-header" style={{ textAlign: 'center', width: '100%' }}>
          All Rates
          <div style={{ float: 'right' }}>
            <Button label="Add Rate" clickHandler={handleAddRate} />
          </div>
        </div>
        <div className="card-body">
          {fetchLoading ? (
            <Loader isSmallView />
          ) : (
            <Table style={{ textAlign: 'center' }} striped bordered hover>
              <thead>
                <tr>
                  <th>Tile</th>
                  <th>Code</th>
                  <th>Rate</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {rates &&
                  rates.map((rate: Rate, index: number) => (
                    <Item
                      key={index}
                      rate={rate}
                      deleteRate={deleteRate}
                      updateRate={updateRate}
                      SelectedId={selectedId}
                    />
                  ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>

      {isShow && (
        <Modal title={mode === 'add' ? 'Enter New Rate' : 'Update Rate'} body={modalBody} closeModal={closeModel} />
      )}
    </>
  );
};

export default RateComponent;
