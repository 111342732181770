import { ICategory, IParamSkillOrder } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import { URL_CATEGORY_FETCH, URL_CATEGORY_DELETE, URL_CATEGORY_REORDER } from 'store/services/URL';

export const api = {
  GetCategories() {
    return apiCall.get({ url: URL_CATEGORY_FETCH, isAuthToken: true, params: {} });
  },
  UpdateCategory(data: ICategory) {
    return apiCall.put({ url: URL_CATEGORY_FETCH, isAuthToken: true, params: data });
  },
  AddCategory(data: ICategory) {
    return apiCall.post({ url: URL_CATEGORY_FETCH, isAuthToken: true, params: data });
  },
  DeleteCategory(id: string) {
    return apiCall.post({ url: URL_CATEGORY_DELETE, isAuthToken: true, params: id });
  },
  ReOrderSkills(params: IParamSkillOrder) {
    return apiCall.post({ url: URL_CATEGORY_REORDER, isAuthToken: true, params });
  },
};

export default api;
