import React, { FunctionComponent, useState } from 'react';
import { Show } from 'modules/Show/show.types';
import { User } from 'modules/user/types';
import ShowUsers from 'views/show/showUsers';
import { ROLE_DIRECTOR_ID, ROLE_PRODUCTION_ID } from 'store/castingPax.constants';
import Button from 'components/controls/button/button';
import showApi from 'store/services/show.services';

interface ModelItem {
  item: Show;
  clickCancel: Function;
}
const ModelBodyItem: FunctionComponent<ModelItem> = (props: ModelItem) => {
  const { item, clickCancel } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const handleGenerateBill = () => {
    setLoading(true);
    showApi
      .generateBill()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  };
  return (
    <div>
      <div className="row d-flex justify-content-between">
        <div>
          <img style={{ height: '120px', width: '120px' }} src={item.showImage} alt="" />
        </div>
        <div>
          <Button showLoading={loading} label="Generate Bill" clickHandler={handleGenerateBill} />
        </div>
      </div>
      <br />
      <ShowUsers
        showId={item.id}
        title="Casting"
        ownerId={item.createdBy}
        users={item.users?.filter((u: User) => u.showRole === ROLE_DIRECTOR_ID) || []}
      />
      <ShowUsers
        showId={item.id}
        title="Production"
        ownerId={item.createdBy}
        users={item.users?.filter((u: User) => u.showRole === ROLE_PRODUCTION_ID) || []}
      />
      <div>
        <Button label="Close" type="customCancelBtn" clickHandler={() => clickCancel()} />
      </div>
    </div>
  );
};

export default ModelBodyItem;
