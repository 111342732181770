import { fromJS } from 'immutable';
import { Show } from 'modules/Show/show.types';
import { User } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS, ROLE_DIRECTOR_ID, ROLE_PRODUCTION_ID } from 'store/castingPax.constants';

const initialState = fromJS({
  shows: [],
  pagination: {},
  loading: false,
  userIdUpdating: null,
  error: '',
});
export default function JobReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.SHOW_FETCHED.SUCCESS: {
      return state
        .set('shows', action.data.data)
        .set('pagination', action.data.pagination[0])
        .set('loading', false)
        .set('error', false);
    }
    case ACTIONS.SHOW_FETCHED.PENDING: {
      return state.set('loading', true).set('error', false);
    }
    case ACTIONS.SHOW_FETCHED.ERROR: {
      return state.set('loading', false).set('error', action.data);
    }
    case ACTIONS.UPDATE_SHOW_ACCOUNT.PENDING: {
      return state.set('userIdUpdating', { id: action.payload.id, status: FETCH_STATUS.LOADING }).set('error', false);
    }
    case ACTIONS.UPDATE_SHOW_ACCOUNT.SUCCESS: {
      const prevShow = state.get('shows');
      const loadingData = { id: action.data.id, status: FETCH_STATUS.SUCCESS };
      const showIndex = prevShow.findIndex((s: Show) => s.id === action.data.showId);
      if (showIndex !== -1) {
        const show: Show = prevShow[showIndex];
        const currentUser: any = show.users?.filter((u: User) => u.id === action.data.id);
        const roleId = currentUser[0].showRole === ROLE_DIRECTOR_ID ? ROLE_DIRECTOR_ID : ROLE_PRODUCTION_ID;
        const users: any = show.users?.map((u: User) => {
          const obj = u;
          if (obj.showRole === roleId) {
            if (obj.id === action.data.id) obj.amount = parseFloat(action.data.amount);
            else obj.amount = 0;
          }
          return obj;
        });
        show.users = users;
        prevShow.splice(showIndex, 1, { ...show });
        return state
          .set('userIdUpdating', { ...loadingData })
          .set('error', false)
          .set('shows', [...prevShow]);
      }
      return state.set('userIdUpdating', { ...loadingData }).set('error', false);
    }
    case ACTIONS.UPDATE_SHOW_ACCOUNT.ERROR: {
      return state
        .set('userIdUpdating', { id: action.payload.id, status: FETCH_STATUS.ERROR })
        .set('error', action.data);
    }
    default:
      return state;
  }
}
