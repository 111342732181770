import { IDefaulttype } from 'modules/params/type';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const FetchingTypes = (data: { page: number; limit: number; text: string }) => ({
  type: ACTIONS.TYPES_FETCHING,
  payload: data,
});

export const TypesFetched = {
  pending: {
    type: ACTIONS.TYPES_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.TYPES_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.TYPES_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const FetchingCategories = () => ({
  type: ACTIONS.DEFAULT_CATEGORIES_CALL,
});

export const CategoriesFetched = (data: any) => ({
  type: ACTIONS.DEFAULT_CATEGORIES,
  data,
});

export const CreateDefaultType = (data: IDefaulttype) => ({
  type: ACTIONS.CREATE_TYPE_CALL,
  payload: data,
});

export const UpdatingDefaultType = (data: IDefaulttype) => ({
  type: ACTIONS.TYPE_UPDATE_CALL,
  payload: data,
});

export const DeletingDefaultType = (data: string) => ({
  type: ACTIONS.TYPE_DELETE_CALL,
  payload: { id: data },
});

export const DeleteDefaulttypeStatus = {
  pending: {
    type: ACTIONS.TYPE_DELETE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.TYPE_DELETE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.TYPE_DELETE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const UpdateDefaulttypeStatus = {
  pending: {
    type: ACTIONS.TYPE_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.TYPE_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.TYPE_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const CreateDefaultTypeStatus = {
  pending: {
    type: ACTIONS.CREATE_TYPE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CREATE_TYPE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_TYPE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
