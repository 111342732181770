import axios, { AxiosRequestConfig } from 'axios';
import { IProfileFileParam } from 'modules/params/type';
import parseError from 'utils/ErrorParse';

function withAPIKeys(requestConfig?: AxiosRequestConfig) {
  const token = localStorage.getItem('cpa_accessToken');
  if (token) {
    return {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        'X-Auth-Token': token ?? 'A',
        'Content-Type': 'application/json',
      },
    };
  }
  return {
    ...requestConfig,
    headers: {
      ...requestConfig?.headers,
      'Content-Type': 'application/json',
    },
  };
}

function withMultipartAPIKeys(requestConfig?: AxiosRequestConfig) {
  const token = localStorage.getItem('cpa_accessToken');

  if (token) {
    return {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        'X-Auth-Token': token ?? 'A',
        'Content-Type': 'multipart/form-data',
      },
    };
  }
  return {
    ...requestConfig,
    headers: {
      ...requestConfig?.headers,
      'Content-Type': 'multipart/form-data',
    },
  };
}

const addPageNumber = (url: string, page: number) => {
  if (page) {
    return `${url}?page=${page}`;
  }
  return url;
};

export const apiCall = {
  get: (payload: requestType) =>
    axios
      .get(addPageNumber(payload.url, payload.params.page), withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  post: (payload: requestType) =>
    axios
      .post(addPageNumber(payload.url, payload.params.page), payload.params, withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  put: (payload: requestType) =>
    axios
      .put(addPageNumber(payload.url, payload.params.page), payload.params, withAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
  filePost: (url: string, payload: IProfileFileParam) =>
    axios
      .post(url, payload.params, withMultipartAPIKeys())
      .then((response) => response.data)
      .catch((error) => parseError(error)),
};

export interface requestType {
  url: string;
  isAuthToken: boolean;
  params: any;
}
