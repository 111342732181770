import { connect } from 'react-redux';
import { fetchshowBegin } from 'store/actions/show.actions';
import { getPaginationSelector, getShowsSelector, getUpdateLoading } from 'store/selector/show.selector';
import show from 'views/show/show';

const mapDispatchToProps = {
  getShows: fetchshowBegin,
};
const mapStateToProps = (state: any) => {
  const shows = getShowsSelector(state);
  const pagination = getPaginationSelector(state);
  const loading = getUpdateLoading(state);
  return {
    shows,
    pagination,
    loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(show);
