import React, { FunctionComponent, useState, useEffect } from 'react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import Delete from 'assets/svg/delete.svg';
import Edit from 'assets/svg/edit.svg';
import plus from 'assets/svg/plus.svg';
import { Button } from 'react-bootstrap';
import Modal from 'components/controls/Modal/Modal';
import { SkillCategory, SkillCategoryList } from 'modules/general/general.type';
import api from 'store/services/category.services';
import { ISkillOrder } from 'modules/params/type';
import ModelBody from 'views/category/modalbody';

interface ITree {
  categories: SkillCategoryList;
  updateCategory: Function;
  removeCategory: Function;
  createCategory: Function;
  category: SkillCategory;
}
const CategoryTree: FunctionComponent<ITree> = (Props: ITree) => {
  const { categories, updateCategory, createCategory, removeCategory, category } = Props;
  const [categoryTreeData, setCategoryTreeData] = useState<any>(categories);
  const [prevData, setPrevData] = useState<any>();
  const [isShow, setIsShow] = useState<boolean>();
  const [selectedNode, setSelectedNode] = useState<any>('');
  const [titleInput, setTitleInput] = useState<string>();
  const [mode, setMode] = useState<string>();

  useEffect(() => {
    console.log('mount');
    return () => {
      console.log('unmound');
      setCategoryTreeData({});
    };
  }, []);

  useEffect(() => {
    setCategoryTreeData(categories);
  }, [categories]);

  useEffect(() => {
    if (mode === 'delete') {
      setMode('');
      setUpdateData(selectedNode);
    }
    if (mode === 'add') {
      setMode('');
      setNewData(category);
    }
  }, [category]);

  const onMoveNode = (event: any) => {
    let flag = true;
    if (event.nextParentNode) {
      if (!event.nextParentNode.categoryId && !event.node.categoryId) {
        flag = false;
        setCategoryTreeData(prevData);
      }
    }
    if (event.nextTreeIndex === 0) {
      flag = false;
      setCategoryTreeData(prevData);
    }
    if (flag) {
      const { treeData } = event;
      const items: any = treeData.map((p: SkillCategory) => {
        const obj: ISkillOrder = { id: p.id, parentId: p.parentId };
        if (p.children) {
          obj.children = p.children.map((c: SkillCategory) => {
            const childObj: ISkillOrder = { id: c.id, parentId: p.id };
            return childObj;
          });
        }
        return obj;
      });
      items.splice(0, 1);
      api.ReOrderSkills({ items }).catch((err) => err);
      console.log('Sorting', items);
    }
  };

  const updateModalData = (catTitle: string) => {
    const data: any = {
      title: catTitle,
    };
    if (mode === 'add') {
      if (selectedNode.node.id === '-1') {
        data.title = catTitle;
        if (categoryTreeData.length === 1 && categoryTreeData.length <= 0) {
          data.itemPosition = 0;
        } else {
          data.itemPosition = categoryTreeData.length - 1;
        }
      } else {
        data.itemPosition = categoryTreeData.length - 1;
        data.parentId = selectedNode.node.id;
      }
      createCategory(data);
    } else {
      data.id = selectedNode.node.id;
      data.parentId = selectedNode.node.parentId;
      selectedNode.node.title = data.title;
      updateCategory(data);
    }
    setIsShow(false);
    setTitleInput('');
    setSelectedNode('');
  };

  const modelBody = () => <ModelBody title={titleInput} handleSave={updateModalData} handleCancle={closeModel} />;

  const editTitle = (rowInfo: any) => {
    setSelectedNode(rowInfo);
    setTitleInput(rowInfo.node.title);
    setMode('edit');
    setIsShow(true);
  };
  const removeNode = (rowInfo: any) => {
    setMode('delete');
    setSelectedNode(rowInfo);
    removeCategory({ id: rowInfo.node.id });
  };
  const closeModel = () => {
    setIsShow(false);
    setMode('');
    setTitleInput('');
  };
  const addNode = (rowInfo: any) => {
    setMode('add');
    setIsShow(true);
    setSelectedNode(rowInfo);
    setTitleInput('');
  };
  const setNewData = (data: any) => {
    const newData = categoryTreeData;
    const index = newData.findIndex((c: SkillCategory) => c.id === data.parentId);
    if (index !== -1) {
      const cat = newData[index];
      if (!cat.children) {
        cat.children = [];
      }
      cat.children.push(data);
      newData.splice(index, 1, { ...cat });
    } else {
      newData.push(data);
    }
    setCategoryTreeData([...newData]);
    setSelectedNode('');
    setTitleInput('');
  };
  const setUpdateData = (rowInfo: any) => {
    const index = categoryTreeData.findIndex((c: SkillCategory) => c.id === rowInfo.node.id);
    if (index !== -1) {
      const data = categoryTreeData.filter((c: SkillCategory) => c.id !== rowInfo.node.id);
      setCategoryTreeData([...data]);
    } else {
      const data = categoryTreeData;
      data.map((skill: SkillCategory) => {
        const child = skill;
        if (child.children) {
          const pos = child.children.findIndex((c: SkillCategory) => c.id === rowInfo.node.id);
          if (pos !== -1) {
            child.children.splice(pos, 1);
          }
        }
        return { ...child };
      });
      setCategoryTreeData([...data]);
    }
    setSelectedNode('');
  };
  const onChange = (data: any) => {
    setPrevData(categoryTreeData);
    setCategoryTreeData(data);
  };
  return (
    <div style={{ height: 800 }}>
      <SortableTree
        canDrag={(node) => node.node.id !== '-1'}
        treeData={categoryTreeData}
        maxDepth={2}
        onChange={onChange}
        onMoveNode={onMoveNode}
        generateNodeProps={(rowInfo) => ({
          buttons: [
            <div>
              {!rowInfo.parentNode ? (
                <Button className="mr-2" onClick={() => addNode(rowInfo)}>
                  <img src={plus} alt="Addbutton" width="15px" />
                </Button>
              ) : null}
              {rowInfo.node.id !== '-1' ? (
                <Button className="mr-2" style={{ background: 'white' }} onClick={() => editTitle(rowInfo)}>
                  <img src={Edit} alt="editbutton" width="15px" />
                </Button>
              ) : null}
              {(!rowInfo.node.children || rowInfo.node.children.length < 1) && rowInfo.node.id !== '-1' ? (
                <Button style={{ background: 'white' }} onClick={() => removeNode(rowInfo)}>
                  <img src={Delete} alt="deletebutton" width="15px" />
                </Button>
              ) : null}
            </div>,
          ],
        })}
      />
      {isShow ? (
        <Modal
          title={mode === 'add' ? 'Enter New Category Title' : 'Update Category Title'}
          body={modelBody}
          closeModal={closeModel}
        />
      ) : null}
    </div>
  );
};

export default CategoryTree;
