import { all, call, takeEvery, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/invoices.services';
import ApiResponse from 'modules/response/response.types';
import { InvoiceParams } from 'modules/params/type';
import { invoicesActions } from 'store/actions';

function* getInvoices(state: { type: string; payload: InvoiceParams }) {
  try {
    yield put(invoicesActions.fetchInvoices.pending);
    const response: ApiResponse = yield call(api.getInvoices, state.payload);
    if (response) yield put(invoicesActions.fetchInvoices.success(response));
  } catch (error: any) {
    yield put(invoicesActions.fetchInvoices.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTIONS.INVOICES_FETCH_CALL, getInvoices)]);
}
