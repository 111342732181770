import { apiCall } from 'store/services/service';
import { URL_USER_LISTING, URL_USER_STATUS } from 'store/services/URL';
import { IUserStatus, IfetchUsers } from 'modules/params/type';

export const api = {
  getUsers(payload: IfetchUsers) {
    return apiCall.post({ url: URL_USER_LISTING, isAuthToken: true, params: payload });
  },
  updateUserStatus(data: IUserStatus) {
    return apiCall.post({ url: URL_USER_STATUS, isAuthToken: true, params: data });
  },
};

export default api;
