import { IfetchShows, IUpdateAccount } from 'modules/params/type';
import { ShowList } from 'modules/Show/show.types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const fetchshowBegin = (payload: IfetchShows) => ({
  type: ACTIONS.SHOW_FETCHING,
  payload,
});

export const fetchShow = {
  pending: {
    type: ACTIONS.SHOW_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ShowList) => ({
    type: ACTIONS.SHOW_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SHOW_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateShowAccountBegin = (payload: IUpdateAccount) => ({
  type: ACTIONS.UPDATE_SHOW_ACCOUNT_BEGIN,
  payload,
});

export const updateShowAccount = {
  pending: (payload: any) => ({
    type: ACTIONS.UPDATE_SHOW_ACCOUNT.PENDING,
    payload,
    status: FETCH_STATUS.LOADING,
  }),
  success: (data: any) => ({
    type: ACTIONS.UPDATE_SHOW_ACCOUNT.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_SHOW_ACCOUNT.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
