import { GeneralSetting } from 'modules/general/general.type';
import { IProfileFileParam } from 'modules/params/type';
import { User, LOGIN_PARAMS, IGeneralSetting } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const loginAttempt = {
  pending: {
    type: ACTIONS.LOGIN.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.LOGIN.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.LOGIN.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const logoutSucceed = () => ({
  type: ACTIONS.AUTH_LOGOUT,
});

export const callLogout = () => ({
  type: ACTIONS.AUTH_LOGOUT_BEGIN,
});

export const authenticatedUser = (state: User) => ({
  type: ACTIONS.AUTHENTICATED_USER,
  data: state,
});

export const getLoginRequest = (state: LOGIN_PARAMS) => ({
  type: ACTIONS.LOGIN_CALL,
  payload: state,
});

export const generalSettingfetching = () => ({
  type: ACTIONS.GENERAL_SETTING_FETCH,
});

export const generalSettingfetched = {
  pending: {
    type: ACTIONS.GENERAL_SETTING_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: GeneralSetting) => ({
    type: ACTIONS.GENERAL_SETTING_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.GENERAL_SETTING_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const generalSetting = (state: IGeneralSetting) => ({
  type: ACTIONS.GENERAL_SETTING,
  payload: state,
});

export const registerUser = {
  pending: {
    type: ACTIONS.SIGNUP.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User) => ({
    type: ACTIONS.SIGNUP.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.SIGNUP.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const profileFileUpload = {
  pending: {
    type: ACTIONS.PROFILE_FILE_UPLOAD.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: User, payload: IProfileFileParam) => ({
    type: ACTIONS.PROFILE_FILE_UPLOAD.SUCCESS,
    data,
    payload,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.PROFILE_FILE_UPLOAD.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
