import createSelector from 'utils/reselect';

const InvoicesReducerData = (state: any) => {
  const { invoicesReducer } = state;
  return invoicesReducer || {};
};

export const getInvociesSelector = createSelector(
  InvoicesReducerData,
  (invoicesReducer) => invoicesReducer.get('invoices') || [],
);
export const getPaginationSelector = createSelector(InvoicesReducerData, (invoicesReducer) =>
  invoicesReducer.get('pagination'),
);
export const getLoading = createSelector(
  InvoicesReducerData,
  (invoicesReducer) => invoicesReducer.get('loading') || false,
);
