import { IUnionStatus } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import { URL_UNION_STATUS, URL_UNION_STATUS_DELETE } from 'store/services/URL';

export const api = {
  getUnionStatus() {
    return apiCall.get({ url: URL_UNION_STATUS, isAuthToken: true, params: {} });
  },
  updateUnionStatus(params: IUnionStatus) {
    return apiCall.put({ url: URL_UNION_STATUS, isAuthToken: true, params });
  },
  addUnionStatus(params: IUnionStatus) {
    return apiCall.post({ url: URL_UNION_STATUS, isAuthToken: true, params });
  },
  deleteUnionStatus(id: string) {
    return apiCall.post({ url: URL_UNION_STATUS_DELETE, isAuthToken: true, params: { id } });
  },
};

export default api;
