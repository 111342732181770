import React, { FunctionComponent } from 'react';
import PaginationComponent from 'components/controls/pagination/Pagination';
import { EmailTemplateList, EmailTemplate } from 'modules/general/general.type';
import { Pagination } from 'modules/response/response.types';
import { Table } from 'react-bootstrap';
import Button from 'components/controls/button/button';
import Loader from 'components/controls/Loader/Loader';

interface IEmailCampaignView {
  isDataLoading: boolean;
  pagination: Pagination;
  emailcampaigns: EmailTemplateList;
  handlePages: Function;
  handleDetail: Function;
}
const EmailCampaignList: FunctionComponent<IEmailCampaignView> = (props: IEmailCampaignView) => {
  const { pagination, emailcampaigns, handlePages, handleDetail, isDataLoading } = props;
  const loadComponent = () => (
    <Table style={{ textAlign: 'center' }} striped bordered hover>
      <thead>
        <tr>
          <th>Title</th>
          <th>Subject</th>
          <th>Status</th>
          <th>Schedule</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {emailcampaigns &&
          emailcampaigns.map((emailCampaign: EmailTemplate, index: number) => (
            <tr key={index}>
              <td>{emailCampaign.title}</td>
              <td>{emailCampaign.subject}</td>
              <td>{emailCampaign.status}</td>
              <td>{emailCampaign.isSchedule ? 'Schdeuled' : 'Non-Scheduled'}</td>
              <td>
                <Button label="Detail" clickHandler={() => handleDetail(emailCampaign)} />
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
  return (
    <>
      {isDataLoading ? <Loader isSmallView /> : loadComponent()}
      <br />
      {pagination.totalPages > 1 && (
        <PaginationComponent
          page={pagination.page}
          totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
          handlePagination={() => handlePages()}
        />
      )}
    </>
  );
};

export default EmailCampaignList;
