import { fromJS } from 'immutable';
import { SubscriptionPlan } from 'modules/user/types';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  plans: [],
  loading: false,
  error: '',
});
export default function SubsciptionReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.PLAN_FETCHED.PENDING: {
      return state.set('loading', true).set('error', false);
    }
    case ACTIONS.PLAN_FETCHED.SUCCESS: {
      return state.set('plans', action.data).set('loading', false).set('error', false);
    }
    case ACTIONS.PLAN_FETCHED.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.PLAN_UPDATE: {
      const prevplans = state.get('plans');
      const index = prevplans.findIndex((p: SubscriptionPlan) => p.id === action.data.id);
      if (index !== -1) {
        prevplans[index] = action.data;
      }
      return state.set('plans', [...prevplans]);
    }
    default:
      return state;
  }
}
