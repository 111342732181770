import { fromJS } from 'immutable';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  categories: [],
  addcategory: {},
  dataLoading: false,
  loading: false,
  error: '',
});
export default function CategoryReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.CATEGORY_FETCH.PENDING: {
      return state.set('dataLoading', true).set('error', false);
    }
    case ACTIONS.CATEGORY_FETCH.ERROR: {
      return state.set('dataLoading', false).set('error', action.error);
    }
    case ACTIONS.CATEGORY_FETCH.SUCCESS: {
      return state.set('categories', action.data).set('dataLoading', false).set('error', false);
    }
    case ACTIONS.CATEGORY_CREATE: {
      return state.set('addcategory', action.data);
    }
    case ACTIONS.CATEGORY_UPDATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.CATEGORY_UPDATE.SUCCESS: {
      return state.set('loading', false).set('error', '');
    }
    case ACTIONS.CATEGORY_UPDATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.CATEGORY_DELETE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.CATEGORY_DELETE.SUCCESS: {
      return state.set('loading', false).set('error', '').set('addcategory', { id: action.data.id.id, status: true });
    }
    case ACTIONS.CATEGORY_DELETE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }

    default:
      return state;
  }
}
