import { all, takeEvery, call, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/defaulttype.services';
import ApiResponse from 'modules/response/response.types';
import { defaultTypeActions } from 'store/actions';
import { IDefaulttype, IfetchTypes } from 'modules/params/type';
import { DefaultType } from 'modules/user/types';

function* GetTypes(state: { type: string; payload: IfetchTypes }) {
  try {
    yield put(defaultTypeActions.TypesFetched.pending);
    const response: ApiResponse = yield call(api.GetTypes, state.payload);
    if (response) yield put(defaultTypeActions.TypesFetched.success(response));
  } catch (error) {
    yield put(defaultTypeActions.TypesFetched.error(error.message));
  }
}
function* GetDefaultCategories() {
  try {
    const response: ApiResponse = yield call(api.GetDefaultCategories);
    if (response) yield put(defaultTypeActions.CategoriesFetched(response));
  } catch (error: any) {
    yield console.log(error.message);
  }
}
function* CreateDefaultType(state: { type: string; payload: IDefaulttype }) {
  try {
    yield console.log(state.payload);
    yield put(defaultTypeActions.CreateDefaultTypeStatus.pending);
    const response: DefaultType = yield call(api.CreateDefaultType, state.payload);
    if (response) yield put(defaultTypeActions.CreateDefaultTypeStatus.success(response));
  } catch (error: any) {
    yield put(defaultTypeActions.CreateDefaultTypeStatus.error(error.message));
  }
}
function* UpdateDefaultType(state: { type: string; payload: IDefaulttype }) {
  try {
    yield put(defaultTypeActions.UpdateDefaulttypeStatus.pending);
    const response: DefaultType = yield call(api.UpdateDefaultType, state.payload);
    if (response) yield put(defaultTypeActions.UpdateDefaulttypeStatus.success(response));
  } catch (error: any) {
    yield put(defaultTypeActions.UpdateDefaulttypeStatus.error(error.message));
  }
}
function* DeleteDefaultType(state: { type: string; payload: string }) {
  try {
    yield put(defaultTypeActions.DeleteDefaulttypeStatus.pending);
    const response: boolean = yield call(api.DeleteDefaultType, state.payload);
    if (response) yield put(defaultTypeActions.DeleteDefaulttypeStatus.success(state.payload));
  } catch (error: any) {
    yield put(defaultTypeActions.DeleteDefaulttypeStatus.error(error.message));
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.TYPES_FETCHING, GetTypes),
    takeEvery(ACTIONS.DEFAULT_CATEGORIES_CALL, GetDefaultCategories),
    takeEvery(ACTIONS.CREATE_TYPE_CALL, CreateDefaultType),
    takeEvery(ACTIONS.TYPE_UPDATE_CALL, UpdateDefaultType),
    takeEvery(ACTIONS.TYPE_DELETE_CALL, DeleteDefaultType),
  ]);
}
