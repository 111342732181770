import { RateList } from 'modules/general/general.type';
import { IDeleteRate, IRateParams } from 'modules/params/type';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const rateFetching = () => ({
  type: ACTIONS.RATE_FETCHING,
});

export const rateFetched = {
  pending: {
    type: ACTIONS.RATE_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: RateList) => ({
    type: ACTIONS.RATE_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.RATE_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createRateBegin = (payload: IRateParams) => ({
  type: ACTIONS.CREATE_RATE_BEGIN,
  payload,
});

export const createRate = {
  pending: {
    type: ACTIONS.CREATE_RATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.CREATE_RATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.CREATE_RATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const deleteRateBegin = (payload: IDeleteRate) => ({
  type: ACTIONS.DELETE_RATE_BEGIN,
  payload,
});

export const deleteRate = {
  pending: {
    type: ACTIONS.DELETE_RATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.DELETE_RATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DELETE_RATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateRateBegin = (payload: IDeleteRate) => ({
  type: ACTIONS.UPDATE_RATE_BEGIN,
  payload,
});

export const updateRate = {
  pending: {
    type: ACTIONS.UPDATE_RATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UPDATE_RATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_RATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
