import createSelector from 'utils/reselect';

const JobStateData = (state: any) => {
  const { jobReducer } = state;
  return jobReducer || {};
};

export const getJobsSelector = createSelector(JobStateData, (jobreducer) => jobreducer.get('jobs') || []);
export const getLoading = createSelector(JobStateData, (jobreducer) => jobreducer.get('loading') || false);
export const getPaginationSelector = createSelector(JobStateData, (jobreducer) => jobreducer.get('pagination'));
export const getRoleSelector = createSelector(JobStateData, () => [
  {
    value: '60671838f9d2b991014963f8',
    id: '60671838f9d2b991014963f8',
    label: 'Casting Director',
    name: 'Casting Director',
    isChecked: false,
  },
  {
    value: '606717f8f9d2b991014963f7',
    id: '606717f8f9d2b991014963f7',
    label: 'Production Director',
    name: 'Production Director',
    isChecked: false,
  },
  {
    value: '6067185ff9d2b991014963f9',
    id: '6067185ff9d2b991014963f9',
    label: 'BG Coordinator',
    name: 'BG Coordinator',
    isChecked: false,
  },
  {
    value: '60671874f9d2b991014963fa',
    id: '60671874f9d2b991014963fa',
    label: 'BG Performer',
    name: 'BG Performer',
    isChecked: false,
  },
]);
