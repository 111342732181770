import React, { FunctionComponent, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import { Button } from 'react-bootstrap';

interface IModelItem {
  title: string | undefined;
  handleSave: Function;
  handleCancle: Function;
}
const ModalItem: FunctionComponent<IModelItem> = (Props: IModelItem) => {
  const { title, handleSave, handleCancle } = Props;
  const [titleError, setTitleError] = useState<string>();
  const [titleInput, setTitleInput] = useState(title);
  const handleChange = () => {
    if (titleInput) handleSave(titleInput);
    else setTitleError('Title field is required');
  };

  return (
    <div>
      <CustomInput
        type="text"
        placeholder="Enter Title"
        value={titleInput}
        handleChange={setTitleInput}
        errorMessage={titleError}
      />
      <Button className="mr-2" onClick={handleChange}>
        {title === '' ? 'Save' : 'Update'}
      </Button>
      <Button onClick={() => handleCancle()}>Cancel</Button>
    </div>
  );
};

export default ModalItem;
