import { Location, LocationList } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const fetchinglocation = () => ({
  type: ACTIONS.LOCATION_FETCHING,
});

export const locationfetched = {
  pending: {
    type: ACTIONS.LOCATION_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: LocationList) => ({
    type: ACTIONS.LOCATION_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.LOCATION_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const LocationUpdating = (data: Location) => ({
  type: ACTIONS.LOCATION_UPDATE_CALL,
  payload: data,
});

export const CreateLocation = (name: string) => ({
  type: ACTIONS.LOCATION_CREATE_CALL,
  payload: name,
});

export const DeleteLocation = (id: string) => ({
  type: ACTIONS.LOCATION_DELETE_CALL,
  payload: id,
});

export const UpdateLocation = {
  pending: {
    type: ACTIONS.UPDATE_LOCATION.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UPDATE_LOCATION.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_LOCATION.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const CreateLocationStatus = {
  pending: {
    type: ACTIONS.LOCATION_CREATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.LOCATION_CREATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.LOCATION_CREATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const DeleteLocationStatus = {
  pending: {
    type: ACTIONS.LOCATION_DELETE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.LOCATION_DELETE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.LOCATION_DELETE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
