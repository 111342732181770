import React, { FunctionComponent, useState } from 'react';
import Button from 'components/controls/button/button';
import { getImageThumbnail } from 'helpers/utils';
import { Show } from 'modules/Show/show.types';
import { User } from 'modules/user/types';
import Style from 'views/show/show.module.css';

interface ItemProps {
  show: Show;
  serialNo: number;
  showModal: Function;
  selectShow: Function;
}

const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { show, serialNo, showModal, selectShow } = props;
  const [hiddenClass, setHiddenClass] = useState<any>(Style.showMoreContentHidden);

  const extentClass = () => setHiddenClass(Style.showMoreContent);

  const checkUserLength = () => {
    if (hiddenClass === Style.showMoreContent) return '';
    if (show.users) {
      if (show.users.length > 2) {
        return (
          <span
            style={{ marginRight: '5px', color: 'blue' }}
            onClick={() => extentClass()}
            onKeyPress={() => extentClass()}
            role="button"
            tabIndex={0}
          >
            show more
          </span>
        );
      }
    }
    return '';
  };

  const handleSelectShow = () => {
    selectShow(show);
    showModal();
  };

  return (
    <tr>
      <td>{serialNo + 1}</td>
      <td>{show.title}</td>
      <td>{show.showType}</td>
      <td className={Style.tableColumn}>
        <div className={hiddenClass}>
          <div className="row">
            {show.users &&
              show.users.map((u: User, index: number) => (
                <div className="col-md-6" key={index}>
                  <div className="row" style={{ marginBottom: '5px' }}>
                    <div className="col-md-2">
                      <img src={getImageThumbnail(u.profilePicUrl)} alt="img" className={Style.imagePreview} />
                    </div>
                    <div className="col-md-10">
                      <p className={Style.teamInfo}>
                        {`${u.firstName} ${u.lastName}`}
                        <br />
                      </p>
                    </div>
                  </div>
                  {index === 1 ? (
                    <>
                      <div className="row">
                        <div className="col-md-8">
                          <div>{checkUserLength()}</div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              ))}
          </div>
        </div>
      </td>
      <td>
        <Button clickHandler={handleSelectShow} label="view" />
      </td>
    </tr>
  );
};

export default Item;
