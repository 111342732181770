import React, { FunctionComponent, useEffect, useState } from 'react';
import checkboxStyle from 'components/controls/checkbox/checkbox.module.css';
import CheckBox from 'components/controls/checkbox/checkBox';
import { Role, RoleList } from 'modules/general/general.type';

interface CheckBoxProps {
  Items: RoleList;
  handleChecked: Function;
  campaignRoles?: RoleList;
}

const GroupCheckBoxes: FunctionComponent<CheckBoxProps> = (props: CheckBoxProps) => {
  const { handleChecked, Items, campaignRoles } = props;
  const [items, setItems] = useState<RoleList>(Items);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  useEffect(() => {
    let flag: any = [];
    items.map((r: any) => {
      const obj: any = r;
      if (campaignRoles) flag = campaignRoles.filter((c: Role) => c.id === obj.id);
      if (flag.length) obj.isChecked = true;
      else obj.isChecked = false;
      return obj;
    });
    setItems([...items]);
  }, [Items]);

  const clickChange = (event: boolean, item: any) => {
    const prevItems = items;
    const index = prevItems.findIndex((i: any) => i.id === item.id);
    if (index !== -1) {
      const obj = prevItems[index];
      obj.isChecked = event;
      prevItems.splice(index, 1, { ...obj });
    }
    setItems([...prevItems]);
    const data = items.filter((i: any) => i.isChecked === true).map((r: any) => r.id);
    handleChecked([...data]);
    setCheckAll(data.length === items.length);
  };

  const handleCheckAll = (isChecked: boolean) => {
    items.map((i: any) => {
      const tmp: any = i;
      tmp.isChecked = isChecked;
      return tmp;
    });
    setItems([...items]);
    setCheckAll(isChecked);
    const data = items.filter((i: any) => i.isChecked === true).map((r: any) => r.id);
    handleChecked([...data]);
  };

  return (
    <>
      <div
        style={{ display: 'flex', justifyContent: 'space-between' }}
        className={`custom-control custom-checkbox ${checkboxStyle.customCheckBoxWrapper}`}
      >
        {items &&
          items.map((r: Role, index: number) => (
            <CheckBox
              item={r}
              id={r.id}
              label={r.name}
              handleChecked={clickChange}
              key={index}
              defaultCheck={r.isChecked || false}
            />
          ))}
        <CheckBox id="0" label="Check All" handleChecked={handleCheckAll} defaultCheck={checkAll} />
      </div>
    </>
  );
};
export default GroupCheckBoxes;
