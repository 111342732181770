import { IfetchShows, IUpdateAccount } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import { URL_GENERATE_BILL, URL_SHOWS, URL_UPDATE_AMOUNT } from 'store/services/URL';

export const api = {
  getShows(params: IfetchShows) {
    return apiCall.post({ url: URL_SHOWS, isAuthToken: true, params });
  },
  updateAccount(params: IUpdateAccount) {
    return apiCall.post({ url: URL_UPDATE_AMOUNT, isAuthToken: true, params });
  },
  generateBill() {
    return apiCall.post({ url: URL_GENERATE_BILL, isAuthToken: true, params: {} });
  },
};

export default api;
