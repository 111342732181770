import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import Menustyle from 'views/menu/menu.module.css';

const Menu: FunctionComponent = () => (
  <div className={Menustyle.sidebar}>
    <div id={Menustyle.sidebarmenu}>Menu</div>
    <NavLink to="/dashboard/user">User</NavLink>
    <NavLink to="/dashboard/categories">Skills</NavLink>
    <NavLink to="/dashboard/jobs">Jobs</NavLink>
    <NavLink to="/dashboard/shows">Shows</NavLink>
    <NavLink to="/dashboard/subscriptionplan">Subscription Plans</NavLink>
    <NavLink to="/dashboard/location">Location</NavLink>
    <NavLink to="/dashboard/rate">Rate</NavLink>
    <NavLink to="/dashboard/unionStatus">Union Status</NavLink>
    <NavLink to="/dashboard/notification">Notification</NavLink>
    <NavLink to="/dashboard/helpcenter">Help Center</NavLink>
    <NavLink to="/dashboard/defaulttype">Default Type</NavLink>
    <NavLink to="/dashboard/emailcompaign">Email Compaign</NavLink>
    <NavLink to="/dashboard/invoices">Invoices</NavLink>
    <NavLink to="/dashboard/generalsetting">General Setting</NavLink>
  </div>
);

export default Menu;
