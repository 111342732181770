import createSelector from 'utils/reselect';

const userStateData = (state: any) => {
  const { users } = state;
  return users || {};
};

export const getUser = createSelector(userStateData, (users) => {
  const u = users.get('user') || null;
  if (u) {
    return JSON.parse(JSON.stringify(u));
  }
  return u;
});

export const loginInProgress = createSelector(userStateData, (users) => users.get('loading'));

export const loginError = createSelector(userStateData, (users) => users.get('error'));

export const getGeneralSettingSelector = createSelector(userStateData, (users) => users.get('generalSetting'));

export const getDataLoading = createSelector(userStateData, (users) => users.get('dataLoading') || false);
