import createSelector from 'utils/reselect';

const SubscriptionStateData = (state: any) => {
  const { subscriptionReducer } = state;
  return subscriptionReducer || {};
};

export const GetPlanSelector = createSelector(
  SubscriptionStateData,
  (subscriptionreducer) => subscriptionreducer.get('plans') || [],
);

export const getLoading = createSelector(
  SubscriptionStateData,
  (subscriptionreducer) => subscriptionreducer.get('loading') || false,
);
