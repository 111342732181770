import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const FetchingStats = () => ({
  type: ACTIONS.DASHBOARD_STATS_CALL,
});

export const DashboardStats = {
  pending: {
    type: ACTIONS.DASHBOARD_STATS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.DASHBOARD_STATS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DASHBOARD_STATS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const getChartDataBegin = () => ({
  type: ACTIONS.DASHBOARD_CHART_DATA_CALL,
});

export const dashboardChartData = {
  pending: {
    type: ACTIONS.DASHBOARD_CHART_DATA.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.DASHBOARD_CHART_DATA.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.DASHBOARD_CHART_DATA.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
