import React, { FunctionComponent, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
// import { SubscriptionPlan } from 'modules/user/types';
import Button from 'components/controls/button/button';

interface ModelItem {
  item: any;
  ClickCancle: Function;
  ClickHandle: Function;
}
const ModelBodyItem: FunctionComponent<ModelItem> = (props: ModelItem) => {
  const { item, ClickCancle, ClickHandle } = props;

  const [Features, setFeatures] = useState<string>(item.features);
  const [Days, setDays] = useState<number>(item.days);
  const [Jobs, setJobs] = useState<number>(item.jobs);
  const [Photos, setPhotos] = useState<number>(item.photos);
  const [RatePerMonth, setRatePerMonth] = useState<number>(item.ratePerMonth);
  const [RatePerYear, setRatePerYear] = useState<number>(item.ratePerYear);
  const [Skills, setSkills] = useState<number>(item.skills);
  const [Title, setTitle] = useState<string>(item.title);

  const savedata = () => {
    item.features = Features;
    item.days = Days;
    item.jobs = Jobs;
    item.photos = Photos;
    item.ratePerMonth = RatePerMonth;
    item.ratePerYear = RatePerYear;
    item.skills = Skills;
    item.title = Title;
    ClickHandle(item);
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-4">
          <label>Title</label>
        </div>
        <div className="col-md-8">
          <CustomInput type="string" placeholder="Enter Title" value={Title} handleChange={setTitle} />
        </div>
      </div>
      <label>Feature</label>
      <CustomInput type="textarea" placeholder="Enter Features" value={Features} handleChange={setFeatures} />
      <div className="row">
        <div className="col-md-4">
          <label>No of Calender Days</label>
        </div>
        <div className="col-md-8">
          <CustomInput type="number" placeholder="Enter Days Calender" value={Days} handleChange={setDays} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>No of Jobs</label>
        </div>
        <div className="col-md-8">
          <CustomInput type="number" placeholder="Enter Jobs" value={Jobs} handleChange={setJobs} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>No of Photos</label>
        </div>
        <div className="col-md-8">
          <CustomInput type="number" placeholder="Enter Photos" value={Photos} handleChange={setPhotos} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>No of Skills</label>
        </div>
        <div className="col-md-8">
          <CustomInput type="number" placeholder="Enter Skills" value={Skills} handleChange={setSkills} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Rate Per Month</label>
        </div>
        <div className="col-md-8">
          <CustomInput
            type="number"
            placeholder="Enter Rate Per Month"
            value={RatePerMonth}
            handleChange={setRatePerMonth}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Rate Per Year</label>
        </div>
        <div className="col-md-8">
          <CustomInput
            type="number"
            placeholder="Enter Rate Per Year"
            value={RatePerYear}
            handleChange={setRatePerYear}
          />
        </div>
      </div>
      <div>
        <Button label="Update" clickHandler={savedata} />
        &ensp;
        <Button label="Cancel" type="customCancelBtn" clickHandler={() => ClickCancle()} />
      </div>
    </div>
  );
};

export default ModelBodyItem;
