import { IEmailCampaignParam } from 'modules/params/type';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const createEmailCampaign = (payload: IEmailCampaignParam) => ({
  type: ACTIONS.EMAILCAMPAIGN_CREATE_CALL,
  payload,
});
export const fetchEmailCampaign = (payload: { page: number; limit: number }) => ({
  type: ACTIONS.EMAILCAMPAIGN_FETCH_CALL,
  payload,
});

export const emailCampaignUpdating = (payload: IEmailCampaignParam) => ({
  type: ACTIONS.EMAILCAMPAIGN_UPDATE_CALL,
  payload,
});

export const fetchEmailCampaignStatus = {
  pending: {
    type: ACTIONS.EMAILCAMPAIGN_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.EMAILCAMPAIGN_FETCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.EMAILCAMPAIGN_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createEmailCampaignStatus = {
  pending: {
    type: ACTIONS.EMAILCAMPAIGN_CREATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.EMAILCAMPAIGN_CREATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.EMAILCAMPAIGN_CREATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const updateEmailCampaignStatus = {
  pending: {
    type: ACTIONS.EMAILCAMPAIGN_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.EMAILCAMPAIGN_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.EMAILCAMPAIGN_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
