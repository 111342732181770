import { all, takeEvery, call, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import { Pagination } from 'modules/response/response.types';
import { IEmailCampaignParam } from 'modules/params/type';
import api from 'store/services/emailcampaign.services';
import { emailCampaignActions } from 'store/actions';
import { EmailTemplate, EmailTemplateList } from 'modules/general/general.type';

function* CreateEmailCampaign(state: { type: string; payload: IEmailCampaignParam }) {
  try {
    yield put(emailCampaignActions.createEmailCampaignStatus.pending);
    const response: EmailTemplate = yield call(api.CreateEmailCampaign, state.payload);
    if (response) yield put(emailCampaignActions.createEmailCampaignStatus.success(response));
  } catch (error: any) {
    yield put(emailCampaignActions.createEmailCampaignStatus.error(error.message));
  }
}

function* getEmailCompaigns(state: { type: string; payload: Pagination }) {
  try {
    yield put(emailCampaignActions.fetchEmailCampaignStatus.pending);
    const response: EmailTemplateList = yield call(api.getEmailCampaigns, state.payload);
    if (response) yield put(emailCampaignActions.fetchEmailCampaignStatus.success(response));
  } catch (error: any) {
    yield put(emailCampaignActions.fetchEmailCampaignStatus.error(error.message));
  }
}
function* updateEmailCompaigns(state: { type: string; payload: IEmailCampaignParam }) {
  try {
    yield put(emailCampaignActions.updateEmailCampaignStatus.pending);
    const response: EmailTemplate = yield call(api.updateEmailCampaigns, state.payload);
    if (response) yield put(emailCampaignActions.updateEmailCampaignStatus.success(response));
  } catch (error: any) {
    yield put(emailCampaignActions.updateEmailCampaignStatus.error(error.message));
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.EMAILCAMPAIGN_CREATE_CALL, CreateEmailCampaign),
    takeEvery(ACTIONS.EMAILCAMPAIGN_FETCH_CALL, getEmailCompaigns),
    takeEvery(ACTIONS.EMAILCAMPAIGN_UPDATE_CALL, updateEmailCompaigns),
  ]);
}
