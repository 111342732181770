import React, { FunctionComponent } from 'react';
import Style from 'components/controls/Loader/Loader.module.css';
import { Spinner } from 'react-bootstrap';

interface LoaderProps {
  isSmallView?: boolean;
  cssClass?: string;
}

const Loader: FunctionComponent<LoaderProps> = (props: LoaderProps) => {
  const { isSmallView, cssClass } = props;

  if (isSmallView) {
    return <Spinner as="span" className={cssClass} animation="border" size="sm" role="status" aria-hidden="true" />;
  }
  return (
    <>
      <div className={Style.loaderWrapper} />
      {/* <div className={Style.loaderInner}>
            <div className="lds-hourglass" />
          </div> */}
      <Spinner as="span" className={cssClass} animation="border" size="sm" role="status" aria-hidden="true" />
    </>
  );
};

export default Loader;
