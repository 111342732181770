import { fromJS } from 'immutable';
import { Rate } from 'modules/general/general.type';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  rates: [],
  fetchLoading: false,
  loading: false,
  error: '',
});
export default function JobReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.RATE_FETCHED.PENDING: {
      return state.set('fetchLoading', true).set('error', '');
    }
    case ACTIONS.RATE_FETCHED.SUCCESS: {
      return state.set('rates', action.data).set('fetchLoading', false).set('error', '');
    }
    case ACTIONS.RATE_FETCHED.ERROR: {
      return state.set('fetchLoading', false).set('error', action.error);
    }
    case ACTIONS.CREATE_RATE.PENDING: {
      return state.set('loading', true).set('error', '');
    }
    case ACTIONS.CREATE_RATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.CREATE_RATE.SUCCESS: {
      const prevRates = state.get('rates');
      prevRates.unshift(action.data);
      return state
        .set('rates', [...prevRates])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.DELETE_RATE.PENDING: {
      return state.set('loading', true).set('error', '');
    }
    case ACTIONS.DELETE_RATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.DELETE_RATE.SUCCESS: {
      const prevRates = state.get('rates');
      const newRatesList = prevRates.filter((u: Rate) => u.id !== action.data);
      return state
        .set('rates', [...newRatesList])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.UPDATE_RATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.UPDATE_RATE.SUCCESS: {
      console.log('action', action.data);
      const prevRates = state.get('rates');
      const index = prevRates.findIndex((u: Rate) => u.id === action.data.id);
      if (index !== -1) {
        const rate: Rate = prevRates[index];
        rate.title = action.data.title;
        rate.code = action.data.code;
        rate.rate = action.data.rate;
        prevRates.splice(index, 1, { ...rate });
      }
      return state
        .set('rates', [...prevRates])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.UPDATE_RATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    default:
      return state;
  }
}
