import { fromJS } from 'immutable';
import { UnionStatus } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

const initialState = fromJS({
  unionStatus: [],
  loading: false,
  dataLoading: false,
  error: '',
});
export default function unionStatusReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.UNION_STATUS_FETCHED.PENDING: {
      return state.set('dataLoading', action.status === FETCH_STATUS.LOADING);
    }
    case ACTIONS.UNION_STATUS_FETCHED.SUCCESS: {
      return state.set('unionStatus', action.data).set('dataLoading', false).set('error', '');
    }
    case ACTIONS.UNION_STATUS_FETCHED.ERROR: {
      return state.set('dataLoading', false).set('error', action.error);
    }
    case ACTIONS.UNION_STATUS_CREATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.UNION_STATUS_CREATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.UNION_STATUS_CREATE.SUCCESS: {
      const prevUnionStatus = state.get('unionStatus');
      prevUnionStatus.unshift(action.data);
      return state
        .set('unionStatus', [...prevUnionStatus])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.UNION_STATUS_DELETE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.UNION_STATUS_DELETE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.UNION_STATUS_DELETE.SUCCESS: {
      const prevUnionStatus = state.get('unionStatus');
      const newUnionStatus = prevUnionStatus.filter((u: UnionStatus) => u.id !== action.data);
      return state
        .set('unionStatus', [...newUnionStatus])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.UNION_STATUS_UPDATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.UNION_STATUS_UPDATE.SUCCESS: {
      const prevUnionStatus = state.get('unionStatus');
      const index = prevUnionStatus.findIndex((u: UnionStatus) => u.id === action.data.id);
      if (index !== -1) {
        const union: UnionStatus = prevUnionStatus[index];
        union.name = action.data.name;
        union.short = action.data.short;
        union.rate = action.data.rate;
        prevUnionStatus.splice(index, 1, { ...union });
      }
      return state
        .set('unionStatus', [...prevUnionStatus])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.UNION_STATUS_UPDATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    default:
      return state;
  }
}
