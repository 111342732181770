import React, { FunctionComponent } from 'react';
import topHeader from 'components/topHeader/topHeader.module.css';

const Header: FunctionComponent = () => (
  <header>
    <div className={topHeader.topHeading}>
      <h1>
        <a href="/index" target="_blank">
          <span>Casting</span>
          PAX
        </a>
      </h1>
    </div>
  </header>
);

export default Header;
