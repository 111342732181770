import React, { FunctionComponent, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import Button from 'components/controls/button/button';
import { Rate } from 'modules/general/general.type';

interface ModelItem {
  item: Rate;
  clickHandle: Function;
  loader: boolean;
  clickCancle: Function;
  mode: string;
}
const ModelBodyItem: FunctionComponent<ModelItem> = (props: ModelItem) => {
  const { item, clickHandle, clickCancle, loader, mode } = props;
  const [title, setTitle] = useState<string>(item.title);
  const [code, setCode] = useState<string>(item.code);
  const [rate, setRate] = useState<number>(item.rate);

  const handleSave = () => {
    if (title.length === 0 || code.length === 0 || rate <= 0) return;
    const data = { title, code, rate };
    clickHandle(data);
  };
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <label>Title</label>
        </div>
        <div className="col-md-8">
          <CustomInput type="text" placeholder="Enter Rate Title" value={title} handleChange={setTitle} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Code</label>
        </div>
        <div className="col-md-8">
          <CustomInput type="text" placeholder="Enter Rate Code" value={code} handleChange={setCode} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Rate</label>
        </div>
        <div className="col-md-8">
          <CustomInput type="text" placeholder="Enter Rate" value={rate} handleChange={setRate} />
        </div>
      </div>
      <div className="row d-flex">
        <Button showLoading={loader} label={mode === 'add' ? 'Add' : 'Update'} clickHandler={handleSave} />
        &ensp;
        <Button type="customCancelBtn" label="Cancel" clickHandler={() => clickCancle()} />
      </div>
    </>
  );
};

export default ModelBodyItem;
