import createSelector from 'utils/reselect';

const CategoryStateData = (state: any) => {
  const { categoryReducer } = state;
  return categoryReducer || {};
};

export const getCategoriesSelector = createSelector(CategoryStateData, (categoryreducer) => {
  const cat = categoryreducer.get('categories') || [];
  if (cat.length > 0 && cat[0].id !== '-1') cat.unshift({ id: '-1', title: 'Create New Category' });
  return cat;
});
export const getCategorySelector = createSelector(CategoryStateData, (categoryreducer) =>
  categoryreducer.get('addcategory'),
);
export const getLoading = createSelector(
  CategoryStateData,
  (categoryreducer) => categoryreducer.get('loading') || false,
);
export const getDataLoading = createSelector(
  CategoryStateData,
  (categoryreducer) => categoryreducer.get('dataLoading') || false,
);
