import { IfetchJobs } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import { URL_JOB_FETCH } from 'store/services/URL';

export const api = {
  GetJobs(payload: IfetchJobs) {
    return apiCall.get({ url: URL_JOB_FETCH, isAuthToken: true, params: payload });
  },
};

export default api;
