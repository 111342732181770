import createSelector from 'utils/reselect';

const DefaulttypeStateData = (state: any) => {
  const { defaultTypeReducer } = state;
  return defaultTypeReducer || {};
};

export const GetDefaulttypessSelector = createSelector(
  DefaulttypeStateData,
  (defaulttypeReducer) => defaulttypeReducer.get('types') || [],
);
export const GetPaginationSelector = createSelector(DefaulttypeStateData, (defaulttypeReducer) =>
  defaulttypeReducer.get('pagination'),
);
export const GetCategoriesSelector = createSelector(DefaulttypeStateData, (defaulttypeReducer) =>
  defaulttypeReducer.get('categories'),
);
export const TypeProgress = createSelector(DefaulttypeStateData, (defaulttypeReducer) =>
  defaulttypeReducer.get('loading'),
);

export const getDataLoading = createSelector(
  DefaulttypeStateData,
  (defaulttypeReducer) => defaulttypeReducer.get('dataLoading') || false,
);
