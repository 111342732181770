import { fromJS } from 'immutable';
import { Location } from 'modules/user/types';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  locations: [],
  loading: false,
  dataLoading: false,
  error: '',
});
export default function LocationReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.LOCATION_FETCHED.PENDING: {
      return state.set('dataLoading', true).set('error', '');
    }
    case ACTIONS.LOCATION_FETCHED.SUCCESS: {
      return state.set('locations', action.data).set('dataLoading', false).set('error', '');
    }
    case ACTIONS.LOCATION_FETCHED.ERROR: {
      return state.set('error', action.error).set('dataLoading', false);
    }
    case ACTIONS.UPDATE_LOCATION.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.UPDATE_LOCATION.SUCCESS: {
      const prevlocations = state.get('locations');
      const index = prevlocations.findIndex((l: Location) => l.id === action.data.id);
      if (index !== -1) {
        const location = prevlocations[index];
        location.name = action.data.name;
        prevlocations.splice(index, 1, { ...location });
      }
      return state
        .set('locations', [...prevlocations])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.UPDATE_LOCATION.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.LOCATION_CREATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.LOCATION_CREATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.LOCATION_CREATE.SUCCESS: {
      const prevlocations = state.get('locations');
      prevlocations.unshift(action.data);
      console.log(prevlocations);
      return state
        .set('locations', [...prevlocations])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.LOCATION_DELETE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.LOCATION_DELETE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.LOCATION_DELETE.SUCCESS: {
      const prevlocations = state.get('locations');
      const newlocations = prevlocations.filter((c: Location) => c.id !== action.data);
      return state
        .set('locations', [...newlocations])
        .set('loading', false)
        .set('error', '');
    }
    default:
      return state;
  }
}
