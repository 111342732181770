import { all, call, takeEvery, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/helpcenter.services';
import { helpCenterActions } from 'store/actions';
import { IHelpcenter } from 'modules/params/type';
import { HelpCenterItem, HelpCenterList } from 'modules/general/general.type';
import ApiResponse from 'modules/response/response.types';

function* GetHelpcenterList() {
  try {
    yield put(helpCenterActions.HelpcenterlistFetched.pending);
    const response: HelpCenterList = yield call(api.GetHelpcenterList);
    if (response) yield put(helpCenterActions.HelpcenterlistFetched.success(response));
  } catch (error: any) {
    yield put(helpCenterActions.HelpcenterlistFetched.error(error.message));
  }
}
function* AddparentHelpcenter(state: { type: string; payload: IHelpcenter }) {
  try {
    const response: HelpCenterItem = yield call(api.Createparenthelpcenter, state.payload);
    if (response) yield put(helpCenterActions.AddHelpcenter(response));
  } catch (error: any) {
    yield console.log(error.message);
  }
}
function* AddchildrenHelpcenter(state: { type: string; payload: IHelpcenter }) {
  try {
    const response: HelpCenterItem = yield call(api.Createchildrenhelpcenter, state.payload);
    if (response) yield put(helpCenterActions.AddHelpcenter(response));
  } catch (error: any) {
    yield console.log(error.message);
  }
}
function* Deleteparenthelpcenter(state: { type: string; payload: string }) {
  try {
    yield put(helpCenterActions.DeleteHelpcenter.pending);
    const response: ApiResponse = yield call(api.Deleteparenthelpcenter, state.payload);
    if (response) yield put(helpCenterActions.DeleteHelpcenter.success(state.payload));
  } catch (error: any) {
    yield put(helpCenterActions.DeleteHelpcenter.error(error.message));
  }
}
function* Deletechildrenhelpcenter(state: { type: string; payload: string }) {
  try {
    yield put(helpCenterActions.DeleteHelpcenter.pending);
    const response: ApiResponse = yield call(api.Deletechildrenhelpcenter, state.payload);
    if (response) yield put(helpCenterActions.DeleteHelpcenter.success(state.payload));
  } catch (error: any) {
    yield put(helpCenterActions.DeleteHelpcenter.error(error.message));
  }
}
function* Updateparenthelpcenter(state: { type: string; payload: IHelpcenter }) {
  try {
    yield put(helpCenterActions.UpdateHelpcenter.pending);
    const response: HelpCenterItem = yield call(api.Updateparenthelpcenter, state.payload);
    if (response) yield put(helpCenterActions.UpdateHelpcenter.success(response));
  } catch (error: any) {
    yield put(helpCenterActions.UpdateHelpcenter.error(error.message));
  }
}
function* Updatechildrenhelpcenter(state: { type: string; payload: IHelpcenter }) {
  try {
    yield put(helpCenterActions.UpdateHelpcenter.pending);
    const response: HelpCenterItem = yield call(api.Updatechildrenhelpcenter, state.payload);
    if (response) yield put(helpCenterActions.UpdateHelpcenter.success(response));
  } catch (error: any) {
    yield put(helpCenterActions.UpdateHelpcenter.error(error.message));
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.HELPCENTER_FETCH_CALL, GetHelpcenterList),
    takeEvery(ACTIONS.HELPCENTER_PARENT_CREATE_CALL, AddparentHelpcenter),
    takeEvery(ACTIONS.HELPCENTER_CHILDREN_CREATE_CALL, AddchildrenHelpcenter),
    takeEvery(ACTIONS.HELPCENTER_PARENT_DELETE_CALL, Deleteparenthelpcenter),
    takeEvery(ACTIONS.HELPCENTER_CHILDREN_DELETE_CALL, Deletechildrenhelpcenter),
    takeEvery(ACTIONS.HELPCENTER_PARENT_UPDATE_CALL, Updateparenthelpcenter),
    takeEvery(ACTIONS.HELPCENTER_CHILDREN_UPDATE_CALL, Updatechildrenhelpcenter),
  ]);
}
