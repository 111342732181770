import React, { FunctionComponent, useEffect } from 'react';
import { JobStats, JobStatsList, UserStats, UserStatsList } from 'modules/user/types';
import { Bar } from 'react-chartjs-2';
import { getChartLabel } from 'helpers/utils';
import {
  COORDINATOR_ROLE,
  DIRECTOR_ROLE,
  JOB_STATUS_BOOKED,
  JOB_STATUS_CANCELLED,
  JOB_STATUS_PENDING,
  JOB_STATUS_REQUESTED,
  PERFORMER_ROLE,
  PRODUCTION_ROLE,
} from 'store/castingPax.constants';
import moment from 'moment';
import Loader from 'components/controls/Loader/Loader';

interface DashboardProps {
  jobs: JobStatsList;
  allUsers: UserStatsList;
  getStats: Function;
  getChartData: Function;
  jobChart: any;
  userChart: any;
  chartLoading: boolean;
}
const Dashboard: FunctionComponent<DashboardProps> = (props: DashboardProps) => {
  const { getStats, jobs, allUsers, getChartData, jobChart, userChart, chartLoading } = props;
  const labels = getChartLabel();

  useEffect(() => {
    getChartData();
    getStats();
  }, []);

  const jobOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: `Job Status From ${labels[0]}  ${moment().add(-1, 'M').format('MMMM')} To ${
          labels[labels.length - 1]
        }  ${moment().format('MMMM')}`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          min: 0,
          stepSize: 1,
        },
        max: jobChart.maxValue,
      },
    },
  };

  const userOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: `Users From ${labels[0]}  ${moment().add(-1, 'M').format('MMMM')} To ${
          labels[labels.length - 1]
        }  ${moment().format('MMMM')}`,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          min: 0,
          stepSize: 1,
        },
        max: userChart.maxValue,
      },
    },
  };

  const jobData = {
    labels,
    datasets: [
      {
        label: JOB_STATUS_BOOKED,
        backgroundColor: '#eb5757',
        borderColor: '#eb5757',
        data: jobChart.booked,
        stack: '4',
        order: 4,
      },
      {
        label: JOB_STATUS_REQUESTED,
        backgroundColor: '#49d360',
        borderColor: '#49d360',
        data: jobChart.requested,
        stack: '3',
        order: 3,
      },
      {
        label: JOB_STATUS_CANCELLED,
        backgroundColor: '#333',
        borderColor: '#333',
        data: jobChart.cancelled,
        stack: '2',
        order: 2,
      },
      {
        label: JOB_STATUS_PENDING,
        backgroundColor: '#f2994a',
        borderColor: '#f2994a',
        data: jobChart.pending,
        stack: '1',
        order: 1,
      },
    ],
  };

  const userData = {
    labels,
    datasets: [
      {
        label: DIRECTOR_ROLE,
        backgroundColor: '#eb5757',
        borderColor: '#eb5757',
        data: userChart.director,
        stack: '4',
        order: 4,
      },
      {
        label: PRODUCTION_ROLE,
        backgroundColor: '#49d360',
        borderColor: '#49d360',
        data: userChart.production,
        stack: '3',
        order: 3,
      },
      {
        label: COORDINATOR_ROLE,
        backgroundColor: '#333',
        borderColor: '#333',
        data: userChart.coordinator,
        stack: '2',
        order: 2,
      },
      {
        label: PERFORMER_ROLE,
        backgroundColor: '#f2994a',
        borderColor: '#f2994a',
        data: userChart.performer,
        stack: '1',
        order: 1,
      },
    ],
  };

  return (
    <div className="mt-4">
      {chartLoading ? (
        <Loader isSmallView />
      ) : (
        <div className="row">
          {jobChart && (
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Bar data={jobData} options={jobOptions} />
            </div>
          )}
          {userChart && (
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Bar data={userData} options={userOptions} />
            </div>
          )}
        </div>
      )}
      <br />
      <div className="row">
        {jobs &&
          jobs.map((j: JobStats, index: number) => (
            <div key={index} className="col-md-3">
              <div className="card bg-light mb-3">
                <div className="card-header">
                  <b>Status: </b>
                  {j.status}
                </div>
                <div className="card-body">
                  <h5 className="card-title">Total Jobs: </h5>
                  <h2 style={{ float: 'right', fontSize: '50px' }} className="card-text">
                    {j.count}
                  </h2>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="row">
        {allUsers &&
          allUsers.map((u: UserStats, index: number) => (
            <div key={index} className="col-md-3">
              <div className="card bg-light mb-3">
                <div className="card-header">
                  <b>Role: </b>
                  {u.role}
                </div>
                <div className="card-body">
                  <h5 className="card-title">Total Users: </h5>
                  <h2 style={{ float: 'right', fontSize: '50px' }} className="card-text">
                    {u.count}
                  </h2>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Dashboard;
