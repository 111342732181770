import { call, put, all, takeEvery } from 'redux-saga/effects';
import { IGeneralSetting, LOGIN_PARAMS, User } from 'modules/user/types';
import { authActions } from 'store/actions';
import api from 'store/services/auth.services';
import ACTIONS from 'store/castingPax.constants';
import ApiResponse from 'modules/response/response.types';
import { IProfileFileParam } from 'modules/params/type';
import { GeneralSetting } from 'modules/general/general.type';

function* login(state: { type: string; payload: LOGIN_PARAMS }) {
  try {
    yield put(authActions.loginAttempt.pending);
    const response: User = yield call(api.loginRequest, state.payload);
    yield localStorage.setItem('cpa_user', JSON.stringify(response));
    if (response.token) yield localStorage.setItem('cpa_accessToken', response.token);
    yield put(authActions.loginAttempt.success(response));
  } catch (error: any) {
    yield put(authActions.loginAttempt.error(error.message));
  }
}

function* logout() {
  yield call([localStorage, 'removeItem'], 'cpa_user');
  yield call([localStorage, 'removeItem'], 'cpa_accessToken');
  yield put(authActions.logoutSucceed());
}

function* fileUpload(state: { type: string; payload: IProfileFileParam }) {
  try {
    yield put(authActions.profileFileUpload.pending);
    const response: ApiResponse = yield call(api.profileFiles, state.payload);
    if (state.payload.updateCall) {
      yield localStorage.setItem('cpa_user', JSON.stringify(response.data));
    }
    yield put(authActions.profileFileUpload.success(response.data, state.payload));
  } catch (error: any) {
    yield put(authActions.profileFileUpload.error(error.message));
  }
}

function* generalSetting(state: { type: string; payload: IGeneralSetting }) {
  try {
    const response: GeneralSetting = yield call(api.generalSetting, state.payload);
    if (response) yield put(authActions.generalSettingfetched.success(response));
  } catch (error: any) {
    yield console.log(error.message);
  }
}

function* getGeneralSetting() {
  try {
    yield put(authActions.generalSettingfetched.pending);
    const response: GeneralSetting = yield call(api.getGeneralSetting);
    if (response) yield put(authActions.generalSettingfetched.success(response));
  } catch (error: any) {
    yield put(authActions.generalSettingfetched.error(error.message));
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.LOGIN_CALL, login),

    takeEvery(ACTIONS.AUTH_LOGOUT_BEGIN, logout),
    takeEvery(ACTIONS.PROFILE_FILE_UPLOAD_BEGIN, fileUpload),
    takeEvery(ACTIONS.GENERAL_SETTING, generalSetting),
    takeEvery(ACTIONS.GENERAL_SETTING_FETCH, getGeneralSetting),
  ]);
}
