import React, { FunctionComponent, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import Button from 'components/controls/button/button';
import SelectOption from 'components/controls/select/SelectOption';

interface ModelItem {
  typedata: any;
  selectedtype: any;
  ClickHandle: Function;
  loader?: boolean;
  ClickCancle: Function;
}
const ModelBodyItem: FunctionComponent<ModelItem> = (props: ModelItem) => {
  const { selectedtype, ClickHandle, loader, ClickCancle, typedata } = props;
  const [Dtype, settype] = useState<string>(selectedtype.type);
  const [Description, setdescription] = useState<string>(selectedtype.description);
  const [Dvalue, setvalue] = useState<string>(selectedtype.value);
  const [Dlabel, setlabel] = useState<string>(selectedtype.label);
  const [categoryRate, setCategoryRate] = useState<number>(selectedtype.rate);

  const handletype = (value: any) => {
    settype(value);
  };
  const handlesave = () => {
    const data = {
      type: Dtype,
      description: Description,
      label: Dlabel,
      value: Dvalue,
      id: selectedtype.id,
      rate: categoryRate,
    };
    ClickHandle(data);
  };
  return (
    <div>
      <SelectOption defaultvalue={selectedtype.type ? Dtype : '--Select--'} data={typedata} handleSelect={handletype} />
      <br />
      <CustomInput
        type="text"
        label="Description"
        placeholder="Enter Description"
        value={Description}
        handleChange={setdescription}
      />
      <CustomInput type="text" label="Label" placeholder="Enter Label" value={Dlabel} handleChange={setlabel} />
      <CustomInput type="text" label="Value" placeholder="Enter Value" value={Dvalue} handleChange={setvalue} />
      {Dtype.toLowerCase() === 'category' && (
        <CustomInput
          type="number"
          label="Rate"
          placeholder="Enter Rate"
          value={categoryRate}
          handleChange={setCategoryRate}
        />
      )}
      <Button showLoading={loader} label={selectedtype.value === '' ? 'Add' : 'Update'} clickHandler={handlesave} />
      &ensp;
      <Button type="customCancelBtn" label="Cancel" clickHandler={() => ClickCancle()} />
    </div>
  );
};

export default ModelBodyItem;
