import React, { FunctionComponent, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import Button from 'components/controls/button/button';

interface ModelItem {
  shortName: String;
  name: string;
  rate: number;
  clickHandle: Function;
  loader: boolean;
  clickCancle: Function;
}
const ModelBodyItem: FunctionComponent<ModelItem> = (props: ModelItem) => {
  const { name, shortName, rate, clickHandle, clickCancle, loader } = props;
  const [unionName, setUnionName] = useState<string>(name);
  const [unionShortName, setUnionShortName] = useState<any>(shortName);
  const [unionRate, setUnionRate] = useState<number>(rate);
  const [unionNameError, setUnionNameError] = useState<string>();
  const [unionShortNameError, setUnionShortNameError] = useState<string>();
  const [unionRateError, setUnionRateError] = useState<string>();

  const handleSave = () => {
    if (unionName !== '' && unionShortName !== '' && unionRate && handleErrors()) {
      const data = { name: unionName, short: unionShortName, rate: unionRate };
      clickHandle(data);
      return;
    }
    setUnionNameError('');
    setUnionShortNameError('');
    setUnionRateError('');
    handleErrors();
  };
  const handleErrors = () => {
    let flag = true;
    if (!unionName) {
      setUnionNameError('Union name is required');
      flag = false;
    }
    if (!unionRate) {
      setUnionRateError('Union rate is required');
      flag = false;
    }
    if (!unionShortName) {
      setUnionShortNameError('Union Short name is required');
      flag = false;
    }
    const words = unionShortName.trim();
    if (words.length > 2) {
      setUnionShortNameError('Union Short Name Length exceed');
      flag = false;
    }
    if (flag) return true;
    return false;
  };
  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <label>Name</label>
        </div>
        <div className="col-md-8">
          <CustomInput
            type="text"
            placeholder="Enter Union Name"
            value={unionName}
            handleChange={setUnionName}
            errorMessage={unionNameError}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Short Name</label>
        </div>
        <div className="col-md-8">
          <CustomInput
            type="text"
            placeholder="Enter Union Short Name"
            value={unionShortName}
            handleChange={setUnionShortName}
            errorMessage={unionShortNameError}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <label>Union Rate</label>
        </div>
        <div className="col-md-8">
          <CustomInput
            type="number"
            placeholder="Enter Union Rate"
            value={unionRate}
            handleChange={setUnionRate}
            errorMessage={unionRateError}
          />
        </div>
      </div>
      <div className="row d-flex">
        <Button showLoading={loader} label={name === '' ? 'Add' : 'Update'} clickHandler={handleSave} />
        &ensp;
        <Button type="customCancelBtn" label="Cancel" clickHandler={() => clickCancle()} />
      </div>
    </>
  );
};

export default ModelBodyItem;
