import React, { FunctionComponent } from 'react';
import Button from 'components/controls/button/button';
import { UnionStatus } from 'modules/user/types';

interface ItemProps {
  sr: number;
  unionStatus: UnionStatus;
  selectedunionId: string | undefined;
  updateUnionStatus: Function;
  deleteUnionStatus: Function;
}
const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { unionStatus, selectedunionId, sr, updateUnionStatus, deleteUnionStatus } = props;

  return (
    <tr>
      <td>{sr + 1}</td>
      <td>{unionStatus.name}</td>
      <td>{unionStatus.short}</td>
      <td>{unionStatus.rate}</td>
      <td>
        <Button label="Edit" clickHandler={() => updateUnionStatus(unionStatus)} />
        &ensp;
        <Button
          label="Delete"
          type="danger"
          clickHandler={() => deleteUnionStatus(unionStatus.id)}
          showLoading={unionStatus.id === selectedunionId}
        />
      </td>
    </tr>
  );
};
export default Item;
