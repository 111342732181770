import createSelector from 'utils/reselect';

const LocationStateData = (state: any) => {
  const { locationReducer } = state;
  return locationReducer || {};
};

export const GetLocationSelector = createSelector(
  LocationStateData,
  (locationreducer) => locationreducer.get('locations') || [],
);
export const LocationProgress = createSelector(
  LocationStateData,
  (locationreducer) => locationreducer.get('loading') || false,
);
export const getDataLoading = createSelector(
  LocationStateData,
  (locationreducer) => locationreducer.get('dataLoading') || false,
);
