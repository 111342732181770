import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import getItemFromLocalStorage from 'utils/getItemFromLocalStorage';

const PrivateRoute = ({ component, path }: any) => {
  const user = getItemFromLocalStorage('cpa_user') || null;

  if (user) return <Route path={path} component={component} />;

  return <Redirect to="/Login" />;
};

export default PrivateRoute;
