import { connect } from 'react-redux';
import { Getusersselector, getLoading, Getpagination, getDataLoading } from 'store/selector/user.selector';
import UserListing from 'views/user/User';
import { FetchingUsers, Userstatus } from 'store/actions/user.actions';
import { User } from 'modules/user/types';
import { getRoleSelector } from 'store/selector/job.selector';

const mapDispatchToProps = {
  fetchUsers: FetchingUsers,
  changeUserStatus: Userstatus,
};

const mapStateToProps = (state: any) => {
  const users: User[] = Getusersselector(state);
  const usersPagination = Getpagination(state);
  const loading = getLoading(state);
  const rolesSelector = getRoleSelector(state);
  const isDataLoding = getDataLoading(state);
  return {
    users,
    usersPagination,
    loading,
    rolesSelector,
    isDataLoding,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListing);
