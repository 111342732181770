import createSelector from 'utils/reselect';

const helpCenterStateData = (state: any) => {
  const { helpCenterReducer } = state;
  return helpCenterReducer || {};
};

export const getHelpCenterSelector = createSelector(helpCenterStateData, (helpcenterreducer) => {
  const cat = helpcenterreducer.get('helpcenterlist') || [];

  if (cat.length > 0 && cat[0].id !== '-1') cat.unshift({ id: '-1', title: 'Create New Item', children: [] });

  return cat;
});

export const getHelpCenterItemSelector = createSelector(helpCenterStateData, (helpcenterreducer) =>
  helpcenterreducer.get('helpcenter'),
);

export const getDataLoading = createSelector(
  helpCenterStateData,
  (helpcenterreducer) => helpcenterreducer.get('dataLoading') || false,
);
