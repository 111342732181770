import { fromJS } from 'immutable';
import { EmailTemplate } from 'modules/general/general.type';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  emailCampaigns: [],
  pagination: {},
  dataLoading: false,
  loading: false,
  error: '',
});
export default function EmailcampaignReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.EMAILCAMPAIGN_FETCH.PENDING: {
      return state.set('dataLoading', action.status === 'LOADING');
    }
    case ACTIONS.EMAILCAMPAIGN_FETCH.ERROR: {
      return state.set('dataLoading', false).set('error', action.error);
    }
    case ACTIONS.EMAILCAMPAIGN_FETCH.SUCCESS: {
      return state
        .set('emailCampaigns', action.data.data)
        .set('pagination', action.data.pagination)
        .set('dataLoading', false)
        .set('error', '');
    }
    case ACTIONS.EMAILCAMPAIGN_CREATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.EMAILCAMPAIGN_CREATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.EMAILCAMPAIGN_CREATE.SUCCESS: {
      const prevemailcampaign = state.get('emailCompaigns');
      prevemailcampaign.unshift(action.data);
      return state
        .set('emailCompaigns', [...prevemailcampaign])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.EMAILCAMPAIGN_UPDATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.EMAILCAMPAIGN_UPDATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.EMAILCAMPAIGN_UPDATE.SUCCESS: {
      const prevemailcampaign = state.get('emailCompaigns');
      const index = prevemailcampaign.findIndex((e: EmailTemplate) => e.id === action.data.id);
      if (index !== -1) {
        prevemailcampaign.splice(index, 1, { ...action.data });
      }
      return state
        .set('emailCompaigns', [...prevemailcampaign])
        .set('loading', false)
        .set('error', '');
    }

    default:
      return state;
  }
}
