import { RoleCode, ROLE_DIRECTOR, ROLE_PRODUCTION, User } from 'modules/user/types';
import moment, { Moment } from 'moment';

export const formateMonthDate = (date: string) => {
  if (date === null || date === undefined) return '';
  return moment(date).format('MMMM DD');
};

export const formateDate = (date: string, format: string) => {
  if (date === null || date === undefined) return '';
  return moment(date).format(format);
};
export const isValidFile = (file: File, type: string) => {
  const fileName = file.name;

  const exts = type === 'image' ? ['png', 'jpg', 'jpeg', 'gif'] : ['pdf', 'doc', 'docx'];

  if (fileName) {
    let getExt = fileName.split('.');
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return type === 'image' ? 'only image files are allowed' : 'only pdf files are allowed';
    }

    if (file.size / 1024 / 1024 > 2) {
      return 'max. 2MB file size allow';
    }

    return '';
  }
  return '';
};

export const getCurrentDate = () => `${moment().format('YYYY-MM-DD')} 00:00:00`;

export const isActiveDate = (d: string) => {
  const currentDate = `${moment().format('YYYY-MM-DD')} 00:00:00`;
  return moment(d).isSameOrAfter(currentDate, 'day');
};

export const isPastDate = (d: string) => {
  const currentDate = `${moment().format('YYYY-MM-DD')} 00:00:00`;
  return moment(d).isBefore(currentDate, 'day');
};

export const getFormattedCurrentDate = (format: string) => moment().format(format);

export const getMinDate = (format: string) => moment().format(format);
export const getMaxDate = (format: string) => moment().add(90, 'days').format(format);

export const getAge = (dob?: string): string => {
  if (dob) {
    const age = moment().diff(moment(dob), 'years').toString();
    return `${age} years`;
  }
  return '';
};

export const getName = (obj: User) => `${obj.firstName} ${obj.lastName}`;

export const formatHeight = (height: string) => {
  if (!height) return '';
  const arr = height.split('');
  if (arr.length > 1) {
    const start = arr[0];
    arr.splice(0, 1);
    const end = arr.join('');
    return `${start}'${end}"`;
  }
  return '';
};

export const formatWeight = (weight?: string) => {
  if (!weight) return '';
  return `${weight} lbs`;
};

export const getFileName = (file: string) => {
  if (file) {
    return file.split('/').pop();
  }
  return 'file';
};

export const redirectPath = {
  save: (path: string) => localStorage.setItem('redirect', path),
  get: () => localStorage.getItem('redirect') || null,
  clear: () => localStorage.removeItem('redirect'),
};

export const getUnionValue = (profile: User) => {
  if (!profile.unionNo) {
    return 'Non union';
  }
  if (profile.unionNo && profile.unionStatus) {
    return `#${profile.unionStatus.short}${profile.unionNo || ''}`;
  }
  return '';
};

export const getUnionClass = (profile: User) => {
  if (!profile.unionNo) {
    return 'union-grey';
  }
  if (profile.unionStatus?.short === 'FU') {
    return 'union-green';
  }

  if (profile.unionStatus?.short === 'AU') {
    return 'union-blue';
  }

  if (profile.unionStatus?.short === 'UE') {
    return 'union-yellow';
  }

  return 'union-grey';
};

export const defaultPagination = () => ({
  limit: 10,
  nextPage: 1,
  page: 1,
  prevPage: -1,
  totalPages: 1,
});

export const defaultShowPagination = () => ({
  limit: 10,
  skip: 0,
  page: 1,
  total: 1,
});

export const getJobSearchActiveFilter = () => [
  { id: 1, label: 'Recently Calls', value: 'Recently Call' },
  { id: 2, label: 'Cattle Calls', value: 'Cattle Call' },
  { id: 3, label: 'Closing Soon', value: 'Closing Soon' },
];

export const clearUnReadNotificationList = () => {
  localStorage.removeItem('unreadNotificationList');
};

export const getImageThumbnail = (url?: string) => {
  if (!url) return '';
  const fileArr = url.split('/');
  if (fileArr.length > 1) {
    const fileName = fileArr[fileArr.length - 1].split('.')[0];
    return `${fileArr.splice(0, fileArr.length - 1).join('/')}/${fileName}-thumbnail.png`;
  }
  return url;
};

export const getRoleId = (type: RoleCode) => {
  if (type === ROLE_PRODUCTION) return '606717f8f9d2b991014963f7';
  if (type === ROLE_DIRECTOR) return '60671838f9d2b991014963f8';
  return '';
};

export const getRoleCode = (id: string) => {
  if (id === '606717f8f9d2b991014963f7') return ROLE_PRODUCTION;
  if (id === '60671838f9d2b991014963f8') return ROLE_DIRECTOR;
  return '';
};

export const getDateInUTC = (date: string) => {
  if (date === null || date === undefined) return '';
  return moment.utc(date).startOf('day').toISOString();
};

export const getDateinDay = (date: Moment) => {
  if (date === null || date === undefined) return '';
  return `${'Day'} ${moment(date).format('D')}`;
};

export const getChartData = (dates: string[], list: any) =>
  dates.map((d: string) => {
    const index = list.findIndex((i: any) => i.date.includes(d));
    return index !== -1 ? list[index].count : 0;
  });

export const getLastThirtyDays = () => {
  const date = moment().add(-31, 'days');
  return [...new Array(30)].map(() => date.add(1, 'days').startOf('day').format('YYYY-MM-DD'));
};

export const getChartLabel = () => {
  const label = getLastThirtyDays().map((l: string) => `${moment(l).format('MMM')} ${moment(l).format('D')}`);
  return label;
};

export const getSubStringDate = (date: string) => date.substring(0, 10);
