import { connect } from 'react-redux';
import NotificationListing from 'views/notication/Notification';
import { FetchingNotications, CreateNotification, DeleteNotification } from 'store/actions/notification.actions';
import {
  GetNotificationSelector,
  GetPaginationSelector,
  GetLoaderSelector,
  getDataLoading,
} from 'store/selector/notification.selector';
import { getRoleSelector } from 'store/selector/job.selector';

const mapDispatchToProps = {
  getNotification: FetchingNotications,
  createNotification: CreateNotification,
  deleteNotification: DeleteNotification,
};

const mapStateToProps = (state: any) => {
  const notifications = GetNotificationSelector(state);
  const pagination = GetPaginationSelector(state);
  const loading = GetLoaderSelector(state);
  const roles = getRoleSelector(state);
  const isDataLoading = getDataLoading(state);
  return {
    notifications,
    pagination,
    loading,
    roles,
    isDataLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationListing);
