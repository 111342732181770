import { fromJS } from 'immutable';
import { User } from 'modules/user/types';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  users: [],
  pagination: {},
  dataLoading: false,
  loading: false,
  error: '',
});
export default function usersReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.USER_FETCH.PENDING: {
      return state.set('dataLoading', true).set('error', false);
    }
    case ACTIONS.USER_FETCH.ERROR: {
      return state.set('dataLoading', false).set('error', true);
    }
    case ACTIONS.USER_FETCH.SUCCESS: {
      return state
        .set('users', action.data.data)
        .set('pagination', action.data.pagination)
        .set('dataLoading', false)
        .set('error', false);
    }
    case ACTIONS.UPDATE_USER_STATUS.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.UPDATE_USER_STATUS.SUCCESS: {
      const prevUsers = state.get('users') || [];
      const index = prevUsers.findIndex((u: User) => u.id === action.data.id);
      if (index !== -1) {
        const user: User = prevUsers[index];
        user.isActive = !user.isActive;
        prevUsers.splice(index, 1, { ...user });
      }
      return state
        .set('users', [...prevUsers])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.UPDATE_USER_STATUS.ERROR: {
      console.log('Error', action);
      return state.set('loading', false).set('error', action.error);
    }
    default:
      return state;
  }
}
