import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import routes from 'routes/index';
import AuthRoute from 'routes/AuthRoute';
import PublicRoute from 'routes/PublicRoute';
import NotFound from 'components/notFound/NotFound';
import 'App.css';
import Dashboard from 'views/Landing';
import { ToastContainer } from 'react-toastify';

function App() {
  document.title = 'Casting pax Admin panel';
  return (
    <div className="App">
      <ToastContainer hideProgressBar />
      <div>
        <Switch>
          {routes &&
            routes.map((route, key) => <PublicRoute key={key} path={route.path} component={route.component} />)}
          <AuthRoute path="/dashboard" url="/dashboard" component={Dashboard} />
          <Redirect exact from="/" to="/Login" />
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
