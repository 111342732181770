import { fromJS } from 'immutable';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

const initialState = fromJS({
  allUsers: [],
  jobs: [],
  loading: false,
  chartLoading: false,
  chartError: '',
  error: '',
  chartData: {},
});
export default function DashboardReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.DASHBOARD_STATS.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.DASHBOARD_STATS.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.DASHBOARD_STATS.SUCCESS: {
      return state
        .set('allUsers', action.data.allUser)
        .set('jobs', action.data.jobs)
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.DASHBOARD_CHART_DATA.PENDING: {
      return state.set('chartLoading', action.status === FETCH_STATUS.LOADING).set('chartError', '');
    }
    case ACTIONS.DASHBOARD_CHART_DATA.ERROR: {
      return state.set('chartLoading', false).set('chartError', action.error);
    }
    case ACTIONS.DASHBOARD_CHART_DATA.SUCCESS: {
      return state
        .setIn(['chartData', 'jobs'], action.data.jobs)
        .setIn(['chartData', 'users'], action.data.users)
        .set('chartLoading', false)
        .set('chartError', '');
    }
    default:
      return state;
  }
}
