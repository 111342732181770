import React, { FunctionComponent, useEffect, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import Button from 'components/controls/button/button';
import { GeneralSetting } from 'modules/general/general.type';
import Loader from 'components/controls/Loader/Loader';

interface IGeneralProps {
  sendGeneralSetting: Function;
  fetchGeneralSetting: Function;
  generalSettingSelector: GeneralSetting;
  isDataLoading: boolean;
}
const generalSetting: FunctionComponent<IGeneralProps> = (props) => {
  const { sendGeneralSetting, fetchGeneralSetting, generalSettingSelector, isDataLoading } = props;
  const [producerIncentive, setProducerIncentive] = useState<number>();
  const [directorIncentiveData, setDirectorIncentiveData] = useState<number>();
  const [taxForUnionData, setTaxForUnionData] = useState<number>();
  const [taxForNonUnionData, setTaxForNonUnionData] = useState<number>();
  const [nonUnionRateData, setNonUnionRateData] = useState<number>();
  const [costumeRateData, setcostumeRateData] = useState<number>();

  const handleSave = () => {
    sendGeneralSetting({
      producerCharge: producerIncentive,
      directorIncentive: directorIncentiveData,
      taxForUnion: taxForUnionData,
      taxForNonUnion: taxForNonUnionData,
      nonUnionRate: nonUnionRateData,
      costumeRate: costumeRateData,
    });
  };
  useEffect(() => {
    fetchGeneralSetting();
  }, []);

  useEffect(() => {
    setProducerIncentive(generalSettingSelector.producerCharge);
    setDirectorIncentiveData(generalSettingSelector.directorIncentive);
    setcostumeRateData(generalSettingSelector.costumeRate);
    setTaxForUnionData(generalSettingSelector.taxForUnion);
    setTaxForNonUnionData(generalSettingSelector.taxForNonUnion);
    setNonUnionRateData(generalSettingSelector.nonUnionRate);
  }, [generalSettingSelector]);
  console.log('loader in genral settinf', isDataLoading);
  return (
    <div className="card">
      <div className="card-header" style={{ textAlign: 'center', width: '100%' }}>
        General Setting
      </div>
      {isDataLoading ? (
        <Loader isSmallView />
      ) : (
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4">
              <CustomInput
                type="number"
                label="Commission Charge from Producer%"
                value={producerIncentive}
                placeholder="Enter Commission Charge from Producer"
                handleChange={setProducerIncentive}
              />
            </div>
            <div className="col-lg-4">
              <CustomInput
                type="number"
                label="Director Incentive"
                placeholder="Enter Director Incentive"
                value={directorIncentiveData}
                handleChange={setDirectorIncentiveData}
              />
            </div>
            <div className="col-lg-4">
              <CustomInput
                type="number"
                label="Non Union Rate"
                placeholder="Enter NonUnion Rate"
                value={nonUnionRateData}
                handleChange={setNonUnionRateData}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <CustomInput
                type="number"
                label="Tax For Union"
                placeholder="Enter Union Tax"
                value={taxForUnionData}
                handleChange={setTaxForUnionData}
              />
            </div>
            <div className="col-lg-4">
              <CustomInput
                type="number"
                label="Tax For NonUnion"
                placeholder="Enter NonUnion Tax"
                value={taxForNonUnionData}
                handleChange={setTaxForNonUnionData}
              />
            </div>
            <div className="col-lg-4">
              <CustomInput
                type="number"
                label="Costume Rate"
                placeholder="Enter Costume Rate"
                value={costumeRateData}
                handleChange={setcostumeRateData}
              />
            </div>
          </div>
          <div className="row d-flex" style={{ float: 'right', margin: '10px' }}>
            <Button label="Save" clickHandler={handleSave} />
          </div>
        </div>
      )}
    </div>
  );
};
export default generalSetting;
