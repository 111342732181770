import React, { FunctionComponent } from 'react';
import Button from 'components/controls/button/button';
import { User } from 'modules/user/types';

interface ItemProps {
  user: User;
  changeStatus: Function;
  SelectedID: string | undefined;
}
const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { user, changeStatus, SelectedID } = props;
  return (
    <tr>
      <td>
        {user.firstName}
        &nbsp;
        {user.lastName}
      </td>
      <td>{user.email}</td>
      <td>{user.role.name}</td>
      <td>{user.phoneNo ? user.phoneNo : 'null'}</td>
      <td>{user.address ? user.address : 'null'}</td>
      <td>
        <Button
          label={user.isActive ? 'Active' : 'InActive'}
          type={user.isActive ? 'customSaveBtn' : 'danger'}
          clickHandler={() => changeStatus(user)}
          showLoading={user.id === SelectedID}
        />
      </td>
    </tr>
  );
};

export default Item;
