import { SubscriptionPlan } from 'modules/user/types';
import { apiCall } from 'store/services/service';
import { URL_SUBSCRIPTION_PLAN } from 'store/services/URL';

export const api = {
  GetPlans() {
    return apiCall.get({ url: URL_SUBSCRIPTION_PLAN, isAuthToken: true, params: {} });
  },
  UpdatePlan(data: SubscriptionPlan) {
    return apiCall.post({ url: URL_SUBSCRIPTION_PLAN, isAuthToken: true, params: data });
  },
};

export default api;
