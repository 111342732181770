import React, { FunctionComponent, useState, useEffect } from 'react';
import TextEditor from 'components/controls/textEditor/TextEditor';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import Button from 'components/controls/button/button';
import DropZone from 'components/controls/dropzone/Dropzone';
import { EmailTemplate, EmailTemplateList, RoleList } from 'modules/general/general.type';
import GroupCheckBoxes from 'components/controls/checkbox/GroupCheckBoxes';
import TextErrorMessage from 'components/utils/errorMessage/TextErrorMessage';
import moment from 'moment';
import EmailCampaignList from 'views/emailcampaign/emailCampaignList';
import { Pagination } from 'modules/response/response.types';

interface IEmailProps {
  createCampaign: Function;
  getEmailCampaigns: Function;
  updateEmailCampaign: Function;
  emailCampaigns: EmailTemplateList;
  pagination: Pagination;
  loading: boolean;
  rolesSelector: RoleList;
  isDataLoading: boolean;
}

const EmailCampaign: FunctionComponent<IEmailProps> = (props: IEmailProps) => {
  const {
    createCampaign,
    getEmailCampaigns,
    emailCampaigns,
    pagination,
    loading,
    updateEmailCampaign,
    rolesSelector,
    isDataLoading,
  } = props;
  const [emailCampaignsList, setEmailCampaigns] = useState<EmailTemplateList>(emailCampaigns);
  const [campaignRoles, setCampaignRoles] = useState<RoleList>([]);
  const [prevFiles, setPrevFiles] = useState<string[]>([]);
  const [item, setItem] = useState<EmailTemplate>();
  const [text, setText] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [rolesError, setRolesError] = useState<string>('');
  const [titleError, setTitleError] = useState<string>('');
  const [subjectError, setSubjectError] = useState<string>('');
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [roles, setRoles] = useState<RoleList>(rolesSelector);
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [file, setFile] = useState<File[]>([]);
  const [mode, setMode] = useState<string>('add');
  const [deletePrevFiles, setDeletePrevFiles] = useState<string[]>([]);

  useEffect(() => {
    LoadData(1);
  }, []);

  useEffect(() => {
    setRoles(rolesSelector);
  }, [rolesSelector]);

  useEffect(() => {
    setEmailCampaigns(emailCampaigns);
    setMode('view');
  }, [emailCampaigns]);

  const LoadData = (page: number) => {
    getEmailCampaigns({ page, limit: 10 });
  };

  const clearFields = () => {
    setTitle('');
    setSubject('');
    setText('');
    setFile([]);
    setCampaignRoles([]);
    setScheduleTime('');
    setScheduleDate('');
    setFile([]);
    setPrevFiles([]);
    setSelectedRoles([]);
  };

  const handleSave = () => {
    let flag = true;
    if (!selectedRoles[0]) {
      flag = false;
      setRolesError('Select atleast 1 role');
    } else setRolesError('');
    if (!title) {
      flag = false;
      setTitleError('Compaign Title Require');
    } else setTitleError('');
    if (!subject) {
      flag = false;
      setSubjectError('Email Subject Require');
    } else setSubjectError('');
    if (flag) {
      const formData = new FormData();
      formData.append('message', text);
      for (let i = 0; i < file.length; i += 1) {
        formData.append('files', file[i]);
      }
      for (let i = 0; i < selectedRoles.length; i += 1) {
        formData.append('roles', selectedRoles[i]);
      }
      if (scheduleDate) {
        let dateTime: any;
        if (scheduleTime) dateTime = moment(`${scheduleDate} ${scheduleTime}`).format('YYYY-MM-DD HH:mm:ss');
        else dateTime = moment(`${scheduleDate} ${scheduleTime}`).format('YYYY-MM-DD 00:00:00');
        formData.append('scheduleDate', dateTime.toString());
        formData.append('isSchedule', 'true');
      }
      formData.append('title', title);
      formData.append('subject', subject);
      if (mode === 'add') createCampaign({ params: formData });
      else updateEmailCampaign({ deleteFiles: deletePrevFiles, params: formData });
      console.log('fomrdata', formData);
    }
  };

  const uploadFile = (data: any, flag: boolean) => {
    if (flag) setPrevFiles(data);
    else setFile(data);
  };

  const handleChangeView = () => {
    if (mode === 'add' || mode === 'detail') {
      clearFields();
      setMode('view');
    } else {
      clearFields();
      setMode('add');
    }
  };

  const handlePages = (updatePage: any) => {
    LoadData(updatePage.selected + 1);
  };

  const handleDetail = (data: EmailTemplate) => {
    setMode('detail');
    setItem(data);
    setTitle(data.title);
    setSubject(data.subject);
    setText(data.templateHtml);
    setCampaignRoles(data.roles);
    setSelectedRoles(data.roles);
    const date: any = moment(data.scheduleDate).format('YYYY-MM-DD');
    const time: any = moment(data.scheduleDate).format('HH:mm:ss');
    setScheduleDate(date);
    setScheduleTime(time);
    setPrevFiles(data.attachements);
  };

  const handlePrevDeleteFiles = (data: string) => {
    const obj: string[] = deletePrevFiles;
    obj.push(data);
    setDeletePrevFiles([...obj]);
  };

  return (
    <>
      <div className="card">
        <div className="card-header" style={{ textAlign: 'center' }}>
          Email Compaign
          <div style={{ float: 'right' }}>
            <Button
              label={mode === 'add' || mode === 'detail' ? 'View Campaign' : 'Create Campaign'}
              clickHandler={handleChangeView}
            />
          </div>
        </div>
        <div className="card-body">
          {mode === 'add' || mode === 'detail' ? (
            <div>
              <div className="row">
                <div className="col-md-3">
                  <label>Compaign Title:</label>
                  <CustomInput
                    value={title}
                    type="text"
                    placeholder="Enter Compaign Title"
                    handleChange={setTitle}
                    errorMessage={titleError}
                  />
                </div>
                <div className="col-md-3">
                  <label>Email Subject:</label>
                  <CustomInput
                    value={subject}
                    type="text"
                    placeholder="Enter Email Subject"
                    handleChange={setSubject}
                    errorMessage={subjectError}
                  />
                </div>
                <div className="col-md-3">
                  <label>Schedule Date:</label>
                  <CustomInput value={scheduleDate} type="date" placeholder="" handleChange={setScheduleDate} />
                </div>
                <div className="col-md-3">
                  <label>Schedule Time:</label>
                  <CustomInput value={scheduleTime} type="time" placeholder="" handleChange={setScheduleTime} />
                </div>
              </div>
              {roles.length > 0 && (
                <div>
                  <label>Select Roles:</label>
                  <GroupCheckBoxes campaignRoles={campaignRoles} Items={roles} handleChecked={setSelectedRoles} />
                </div>
              )}
              {rolesError ? <TextErrorMessage message={rolesError} /> : ''}
              <br />
              <div>
                <TextEditor text={text} setText={setText} />
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <DropZone
                    handlePrevDeleteFiles={handlePrevDeleteFiles}
                    type="img"
                    fileUploadHandler={uploadFile}
                    prevFiles={item ? prevFiles : null}
                    path={item ? item.filePath : null}
                  />
                </div>
                <div className="col-md-6">
                  <div style={{ float: 'right' }}>
                    <Button
                      showLoading={loading}
                      type="customSaveBtn"
                      label={mode === 'add' ? 'Save' : 'Update'}
                      clickHandler={handleSave}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <EmailCampaignList
              isDataLoading={isDataLoading}
              pagination={pagination}
              emailcampaigns={emailCampaignsList}
              handlePages={handlePages}
              handleDetail={handleDetail}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EmailCampaign;
