import React, { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Style from 'views/topbar/Topbar.module.css';
import logo from 'assets/castingpaxlogo.png';
import { useDispatch } from 'react-redux';
import { callLogout } from 'store/actions/auth.actions';

const Topbar: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(callLogout());
    history.push('/login');
  };
  return (
    <>
      <div className={`${Style.navigation}`}>
        <div className="container-fluid">
          <div className="d-flex justify-content-between w-100">
            <Link className={`${Style.brand}`} to="/login">
              <img className={`${Style.logo}`} alt="" src={logo} />
            </Link>
            <Button
              className={`btn btn-dark ${Style.button}`}
              onClick={handleLogout}
              onKeyPress={handleLogout}
              tabIndex={0}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
