import React, { FunctionComponent, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import Button from 'components/controls/button/button';

interface ModelItem {
  locationName: string;
  clickHandle: Function;
  loader: boolean;
  clickCancle: Function;
}
const ModelBodyItem: FunctionComponent<ModelItem> = (props: ModelItem) => {
  const { locationName, clickHandle, loader, clickCancle } = props;
  const [error, setError] = useState('');
  const [name, setName] = useState<string>(locationName);
  const handleSave = () => {
    if (name !== '') {
      clickHandle(name);
    } else setError('Location Name Required');
  };
  return (
    <div>
      <CustomInput
        type="text"
        placeholder="Enter Location Name"
        value={name}
        handleChange={setName}
        errorMessage={error}
      />
      <Button showLoading={loader} label={locationName === '' ? 'Add' : 'Update'} clickHandler={handleSave} />
      &ensp;
      <Button type="customCancelBtn" label="Cancel" clickHandler={() => clickCancle()} />
    </div>
  );
};

export default ModelBodyItem;
