import { connect } from 'react-redux';
import jobComponent from 'views/job/Jobs';
import { FetchingJobs } from 'store/actions/job.actions';
import { getJobsSelector, getLoading, getPaginationSelector } from 'store/selector/job.selector';

const mapDisptachToProps = {
  getJobs: FetchingJobs,
};

const mapStateToProps = (state: any) => {
  const Jobs = getJobsSelector(state);
  const Pagination = getPaginationSelector(state);
  const isLoading = getLoading(state);
  return {
    jobs: Jobs,
    pagination: Pagination,
    isLoading,
  };
};

export default connect(mapStateToProps, mapDisptachToProps)(jobComponent);
