import GeneralSetting from 'views/generalsetting/Generalsetting';
import { connect } from 'react-redux';
import { generalSetting, generalSettingfetching } from 'store/actions/auth.actions';
import { getDataLoading, getGeneralSettingSelector } from 'store/selector/auth.selector';

const mapDisptachToProps = {
  sendGeneralSetting: generalSetting,
  fetchGeneralSetting: generalSettingfetching,
};
const mapstateToProps = (state: any) => {
  const generalSettingSelector = getGeneralSettingSelector(state);
  const isDataLoading = getDataLoading(state);
  return { generalSettingSelector, isDataLoading };
};
export default connect(mapstateToProps, mapDisptachToProps)(GeneralSetting);
