import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';
import { IHelpcenter } from 'modules/params/type';
import { HelpCenterItem, HelpCenterList } from 'modules/general/general.type';

export const FetchingHelpcenterlist = () => ({
  type: ACTIONS.HELPCENTER_FETCH_CALL,
});

export const HelpcenterlistFetched = {
  pending: {
    type: ACTIONS.HELPCENTER_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: HelpCenterList) => ({
    type: ACTIONS.HELPCENTER_FETCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.HELPCENTER_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const AddingHelpcenterparent = (data: IHelpcenter) => ({
  type: ACTIONS.HELPCENTER_PARENT_CREATE_CALL,
  payload: data,
});

export const AddingHelpcenterchildren = (data: IHelpcenter) => ({
  type: ACTIONS.HELPCENTER_CHILDREN_CREATE_CALL,
  payload: data,
});

export const AddHelpcenter = (data: HelpCenterItem) => ({
  type: ACTIONS.HELPCENTER_CREATE,
  data,
});

export const DeletingparentHelpcenter = (id: string) => ({
  type: ACTIONS.HELPCENTER_PARENT_DELETE_CALL,
  payload: id,
});

export const DeletingchildrenHelpcenter = (id: string) => ({
  type: ACTIONS.HELPCENTER_CHILDREN_DELETE_CALL,
  payload: id,
});

export const UpdatingparentHelpcenter = (data: IHelpcenter) => ({
  type: ACTIONS.HELPCENTER_PARENT_UPDATE_CALL,
  payload: data,
});
export const UpdatingchildrenHelpcenter = (data: IHelpcenter) => ({
  type: ACTIONS.HELPCENTER_CHILDREN_UPDATE_CALL,
  payload: data,
});

export const DeleteHelpcenter = {
  pending: {
    type: ACTIONS.HELPCENTER_DELETE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.HELPCENTER_DELETE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.HELPCENTER_DELETE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const UpdateHelpcenter = {
  pending: {
    type: ACTIONS.HELPCENTER_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.HELPCENTER_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.HELPCENTER_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
