import asyncActionType from 'utils/reduxActions';

export const FILTER_ACTIONS = {
  SET_SCREEN_WIDTH: '[CASTING_PAX]SET_SCREEN_WIDTH',
  LOGIN: asyncActionType('[CASTING_PAX]LOGIN'),
  LOGIN_CALL: '[CASTING_PAX]LOGIN_CALL',
  AUTHENTICATED_USER: '[CASTING_PAX]AUTHENTICATED_USER',
  AUTH_LOGOUT_BEGIN: '[CASTING_PAX]AUTH_LOGOUT_BEGIN',
  AUTH_LOGOUT: '[CASTING_PAX]AUTH_LOGOUT',
  SIGNUP: asyncActionType('[CASTING_PAX]SIGNUP'),
  SIGNUP_CALL: '[CASTING_PAX]SIGNUP_CALL',
  REGISTER_NEW_USER: '[CASTING_PAX]REGISTER_NEW_USER',
  SIGNUP_STEP_TWO: '[CASTING_PAX]SIGNUP_STEP_TWO',
  PEROFILE_UPDATE: '[CASTING_PAX]PEROFILE_UPDATE',
  CHANGE_SELECTED_TAB: '[CASTING_PAX]CHANGE_SELECTED_TAB',
  RESET_ERROR_MESSAGE: '[CASTING_PAX]RESET_ERROR_MESSAGE',

  GENERAL_SETTING_FETCH: '[CASTING_PAX]GENERAL_SETTING_FETCH',
  GENERAL_SETTING_FETCHED: asyncActionType('[CASTING_PAX]GENERAL_SETTING_FETCHED'),
  GENERAL_SETTING: '[CASTING_PAX]GENERAL_SETTING',

  USER_FETCHING: '[CASTING_PAX]USER_FETCHING',
  USER_FETCH: asyncActionType('[CASTING_PAX]USER_FETCH'),
  USER_STATUS: '[CASTING_PAX]USER_STATUS',
  UPDATE_USER_STATUS: asyncActionType('[CASTING_PAX]UPDATE_USER_STATUS'),
  SEARCH_USERS: '[CASTING_PAX]SEARCH_USERS',

  CATEGORY_FETCH_CALL: '[CASTING_PAX]CATEGORY_FETCH_CALL',
  CATEGORY_FETCH: asyncActionType('[CASTING_PAX]CATEGORY_FETCH'),
  CATEGORY_CREATE_CALL: '[CASTING_PAX]CATEGORY_CREATE_CALL',
  CATEGORY_CREATE: '[CASTING_PAX]CATEGORY_CREATE',
  CATEGORY_UPDATE_CALL: '[CASTING_PAX]CATEGORY_UPDATE_CALL',
  CATEGORY_UPDATE: asyncActionType('[CASTING_PAX]CATEGORY_UPDATE'),
  CATEGORY_DELETE_CALL: '[CASTING_PAX]CATEGORY_DELETE_CALL',
  CATEGORY_DELETE: asyncActionType('[CASTING_PAX]CATEGORY_DELETE'),

  JOBS_FETCHING: '[CASTING_PAX]JOBS_FETCHING',
  JOBS_FETCHED: asyncActionType('[CASTING_PAX]JOBS_FETCHED'),

  TYPES_FETCHING: '[CASTING_PAX]TYPES_FETCHING',
  TYPES_FETCHED: asyncActionType('[CASTING_PAX]TYPES_FETCHED'),
  DEFAULT_CATEGORIES_CALL: '[CASTING_PAX]DEFAULT_CATEGORIES_CALL',
  DEFAULT_CATEGORIES: '[CASTING_PAX]DEFAULT_CATEGORIES',
  CREATE_TYPE_CALL: '[CASTING_PAX]CREATE_TYPE_CALL',
  CREATE_TYPE: asyncActionType('[CASTING_PAX]CREATE_TYPE'),
  TYPE_UPDATE_CALL: '[CASTING_PAX]TYPE_UPDATE_CALL',
  TYPE_UPDATE: asyncActionType('[CASTING_PAX]TYPE_UPDATE'),
  TYPE_DELETE_CALL: '[CASTING_PAX]TYPE_DELETE_CALL',
  TYPE_DELETE: asyncActionType('[CASTING_PAX]TYPE_DELETE'),

  PLAN_FETCHING: '[CASTING_PAX]PLANS_FETCHING',
  PLAN_FETCHED: asyncActionType('[CASTING_PAX]PLAN_FETCHED'),
  PLAN_UPDATE_CALL: '[CASTING_PAX]PLANS_UPDATE_CALL',
  PLAN_UPDATE: '[CASTING_PAX]PLANS_UPDATE',

  LOCATION_FETCHING: '[CASTING_PAX]LOCATION_FETCHING',
  LOCATION_FETCHED: asyncActionType('[CASTING_PAX]LOCATION_FETCHED'),
  LOCATION_CREATE_CALL: '[CASTING_PAX]LOCATION_CREATE_CALL',
  LOCATION_CREATE: asyncActionType('[CASTING_PAX]LOCATION_CREATE'),
  LOCATION_UPDATE_CALL: '[CASTING_PAX]LOCATION_UPDATING',
  UPDATE_LOCATION: asyncActionType('[CASTING_PAX]UPDATE_LOCATION'),
  LOCATION_DELETE_CALL: '[CASTING_PAX]LOCATION_DELETE_CALL',
  LOCATION_DELETE: asyncActionType('[CASTING_PAX]LOCATION_DELETE'),

  NOTIFICATION_FETCHING: '[CASTING_PAX]NOTIFICATION_FETCHING',
  NOTIFICATION_FETCHED: asyncActionType('[CASTING_PAX]NOTIFICATION_FETCHED'),
  NOTIFICATION_CREATE_CALL: '[CASTING_PAX]NOTIFICATION_CREATE_CALL',
  NOTIFICATION_CREATE: asyncActionType('[CASTING_PAX]NOTIFICATION_CREATE'),
  NOTIFICATION_DELETE_CALL: '[CASTING_PAX]NOTIFICATION_DELETE_CALL',
  NOTIFICATION_DELETE: asyncActionType('[CASTING_PAX]NOTIFICATION_DELETE'),

  ROLE_FETCHING: '[CASTING_PAX]ROLE_FETCHING',
  ROLE_FETCHED: '[CASTING_PAX]ROLE_FETCHED',

  SHOW_FETCHING: '[CASTING_PAX]SHOW_FETCHING',
  SHOW_FETCHED: asyncActionType('[CASTING_PAX]SHOW_FETCHED'),

  HELPCENTER_PARENT_CREATE_CALL: '[CASTING_PAX]HELPCENTER_CREATE_CALL',
  HELPCENTER_CHILDREN_CREATE_CALL: '[CASTING_PAX]HELPCENTER_CHILDREN_CREATE_CALL',
  HELPCENTER_CREATE: '[CASTING_PAX]HELPCENTER_CREATE',
  HELPCENTER_FETCH_CALL: '[CASTING_PAX]HELPCENTER_FETCH_CALL',
  HELPCENTER_FETCH: asyncActionType('[CASTING_PAX]HELPCENTER_FETCH'),

  HELPCENTER_PARENT_UPDATE_CALL: '[CASTING_PAX]HELPCENTER_PARENT_UPDATE_CALL',
  HELPCENTER_CHILDREN_UPDATE_CALL: '[CASTING_PAX]HELPCENTER_CHILDREN_UPDATE_CALL',
  HELPCENTER_UPDATE: asyncActionType('[CASTING_PAX]HELPCENTER_UPDATE'),
  HELPCENTER_PARENT_DELETE_CALL: '[CASTING_PAX]HELPCENTER_PARENT_DELETE_CALL',
  HELPCENTER_CHILDREN_DELETE_CALL: '[CASTING_PAX]HELPCENTER_CHILDREN_DELETE_CALL',
  HELPCENTER_DELETE: asyncActionType('[CASTING_PAX]HELPCENTER_DELETE'),

  EMAILCAMPAIGN_FETCH_CALL: '[CASTING_PAX]EMAILCAMPAIGN_FETCH_CALL',
  EMAILCAMPAIGN_FETCH: asyncActionType('[CASTING_PAX]EMAILCAMPAIGN_FETCH'),
  EMAILCAMPAIGN_CREATE_CALL: '[CASTING_PAX]EMAILCAMPAIGN_CREATE_CALL',
  EMAILCAMPAIGN_CREATE: asyncActionType('[CASTING_PAX]EMAILCAMPAIGN_CREATE'),
  EMAILCAMPAIGN_UPDATE_CALL: '[CASTING_PAX]EMAILCAMPAIGN_UPDATE_CALL',
  EMAILCAMPAIGN_UPDATE: asyncActionType('[CASTING_PAX]EMAILCAMPAIGN_UPDATE'),

  UNION_STATUS_FETCHING: '[CASTING_PAX]UNION_STATUS_FETCHING',
  UNION_STATUS_FETCHED: asyncActionType('[CASTING_PAX]UNION_STATUS_FETCHED'),
  UNION_STATUS_CREATE_CALL: '[CASTING_PAX]UNION_STATUS_CREATE_CALL',
  UNION_STATUS_CREATE: asyncActionType('[CASTING_PAX]UNION_STATUS_CREATE'),
  UNION_STATUS_DELETE_CALL: '[CASTING_PAX]UNION_STATUS_DELETE_CALL',
  UNION_STATUS_DELETE: asyncActionType('[CASTING_PAX]UNION_STATUS_DELETE'),
  UNION_STATUS_UPDATE_CALL: '[CASTING_PAX]UNION_STATUS_UPDATE_CALL',
  UNION_STATUS_UPDATE: asyncActionType('[CASTING_PAX]UNION_STATUS_UPDATE'),

  DASHBOARD_STATS_CALL: '[CASTING_PAX]DASHBOARD_STATS_CALL',
  DASHBOARD_STATS: asyncActionType('[CASTING_PAX]DASHBOARD_STATS'),
  DASHBOARD_CHART_DATA_CALL: '[CASTING_PAX]DASHBOARD_CHART_DATA_CALL',
  DASHBOARD_CHART_DATA: asyncActionType('[CASTING_PAX]DASHBOARD_CHART_DATA'),

  INVOICES_FETCH_CALL: '[CASTING_PAX]INVOICES_FETCH_CALL',
  INVOICES_FETCH: asyncActionType('[CASTING_PAX]INVOICES_FETCH'),

  UPDATE_SHOW_ACCOUNT_BEGIN: '[CASTING_PAX]UPDATE_SHOW_ACCOUNT_BEGIN',
  UPDATE_SHOW_ACCOUNT: asyncActionType('[CASTING_PAX]UPDATE_SHOW_ACCOUNT'),

  RATE_FETCHING: '[CASTING_PAX]RATE_FETCHING',
  RATE_FETCHED: asyncActionType('[CASTING_PAX]RATE_FETCHED'),

  CREATE_RATE_BEGIN: '[CASTING_PAX]CREATE_RATE_BEGIN',
  CREATE_RATE: asyncActionType('[CASTING_PAX]CREATE_RATE'),

  DELETE_RATE_BEGIN: '[CASTING_PAX]DELETE_RATE_BEGIN',
  DELETE_RATE: asyncActionType('[CASTING_PAX]DELETE_RATE'),

  UPDATE_RATE_BEGIN: '[CASTING_PAX]UPDATE_RATE_BEGIN',
  UPDATE_RATE: asyncActionType('[CASTING_PAX]UPDATE_RATE'),

  PROFILE_FILE_UPLOAD_BEGIN: '[CASTING_PAX]PROFILE_FILE_UPLOAD_BEGIN',
  PROFILE_FILE_UPLOAD: asyncActionType('[CASTING_PAX]PROFILE_FILE_UPLOAD'),
};

export const FETCH_STATUS = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export default {
  ...FILTER_ACTIONS,
};

export const JOB_STATUS_NEW = 'new';
export const JOB_STATUS_PENDING = 'pending';
export const JOB_STATUS_REQUESTED = 'requested';
export const JOB_STATUS_BOOKED = 'booked';
export const JOB_STATUS_CANCELLED = 'cancelled';
export const TYPE_MATRIX = 'matrix';

export const ROLE_PRODUCTION_ID = '606717f8f9d2b991014963f7';
export const ROLE_DIRECTOR_ID = '60671838f9d2b991014963f8';

export const DIRECTOR_ROLE: string = 'DIRECTOR';
export const PRODUCTION_ROLE: string = 'PRODUCTION';
export const COORDINATOR_ROLE: string = 'COORDINATOR';
export const PERFORMER_ROLE: string = 'PERFORMER';
