import React, { FunctionComponent, useMemo } from 'react';
import Style from 'views/subscription/setting.module.css';
import unionImage from 'assets/images/union.png';
import enterpriseSvg from 'assets/svg/Enterprise.svg';
import boosterSvg from 'assets/svg/booster.svg';
import proSvg from 'assets/svg/pro.svg';
import Button from 'components/controls/button/button';
import { SubscriptionPlan } from 'modules/user/types';

interface ItemProps {
  item: SubscriptionPlan;
  clickHandle: Function;
  index: number;
}

const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { item, index, clickHandle } = props;

  const icon = useMemo(() => {
    switch (item.category) {
      case 'Advantage':
        return boosterSvg;
      case 'Pro':
        return proSvg;
      default:
        return enterpriseSvg;
    }
  }, [item]);

  const jobText = () => `${item.jobs === 999 ? 'Unlimited' : item.jobs} weekly job to apply`;

  const addCssClass = useMemo(() => {
    switch (index) {
      case 1:
        return Style.secondCard;
      case 2:
        return Style.thirdCard;
      default:
        return Style.firstCard;
    }
  }, [index]);

  return (
    <>
      <div className={`position-relative firstCard ${Style.firstCard} ${addCssClass}`}>
        <div className={Style.cardInnerImg}>
          <img src={unionImage} alt="union" />
          <div className={Style.cardImageText}>
            <img src={icon} alt="enterprise" />
            <p className="text-white mb-0 ml-2">Starter</p>
          </div>
        </div>
        <div className={Style.firstCardInner}>
          <h4 className="font-weight-bold text-white">{item.title}</h4>
          <div className="d-flex justify-content-center align-items-baseline line-height-normal">
            <p className="mb-0 text-white font-weight-bold fontTwenty">{item.ratePerMonth}</p>
            <p className={`mb-0 text-white font-weight-bold ${Style.monthText}`}> / Month*</p>
          </div>
          <p className="mb-0 mt-2 text-white font-weight-normal fontFourteen">&nbsp;</p>
        </div>
        <hr className={Style.horizontalRow} />
        <p className="text-white mb-0 fontFourteen font-weight-normal">Features</p>
        <ul className={Style.listingWrapper}>
          <li className="text-white fontFourteen font-weight-normal">{`${item.days} days calender`}</li>
          <li className="text-white fontFourteen font-weight-normal">{`${item.photos} Profile Pictures`}</li>
          <li className="text-white fontFourteen font-weight-normal">{`${item.skills} Profile Skills`}</li>
          <li className="text-white fontFourteen font-weight-normal">{jobText()}</li>
        </ul>
        <div style={{ textAlign: 'center' }}>
          <Button clickHandler={() => clickHandle(item)} label="Edit" cssClass={Style.chooseButton} />
        </div>
      </div>
    </>
  );
};

export default Item;
