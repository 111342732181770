import { Location } from 'modules/user/types';
import { apiCall } from 'store/services/service';
import { URL_LOCATION, URL_LOCATION_DELETE } from 'store/services/URL';

export const api = {
  GetLocations() {
    return apiCall.get({ url: URL_LOCATION, isAuthToken: true, params: {} });
  },
  UpdateLocation(locationparams: Location) {
    return apiCall.put({ url: URL_LOCATION, isAuthToken: true, params: locationparams });
  },
  AddLocation(name: string) {
    return apiCall.post({ url: URL_LOCATION, isAuthToken: true, params: { name } });
  },
  DeleteLocation(id: string) {
    return apiCall.post({ url: URL_LOCATION_DELETE, isAuthToken: true, params: { id } });
  },
};

export default api;
