import { fromJS } from 'immutable';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  invoices: [],
  pagination: {},
  loading: false,
  error: '',
});
export default function JobReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.INVOICES_FETCH.PENDING: {
      return state.set('loading', true).set('error', '');
    }
    case ACTIONS.INVOICES_FETCH.SUCCESS: {
      return state
        .set('loading', false)
        .set('invoices', action.data.data)
        .set('pagination', action.data.pagination)
        .set('error', '');
    }
    case ACTIONS.INVOICES_FETCH.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }

    default:
      return state;
  }
}
