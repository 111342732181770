import React, { FunctionComponent } from 'react';

import Button from 'components/controls/button/button';

interface ItemProps {
  type: any;
  selectedID: string | undefined;
  handleDelete?: any;
  selectedItem: any;
  mode: any;
  show: any;
}
const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { type, selectedItem, mode, show, selectedID, handleDelete } = props;

  const change = (display: string) => {
    show(true);
    mode(display);
    selectedItem(type);
  };
  return (
    <tr>
      <td>{type.type}</td>
      <td>
        {type.label}
        {type.type.toLowerCase() === 'category' && ` (Rate-${type.rate || 0})`}
      </td>
      <td>{type.value}</td>
      <td>
        <Button label="Edit" clickHandler={() => change('edit')} />
        &ensp;
        <Button
          label="Delete"
          type="danger"
          clickHandler={() => handleDelete(type.id)}
          showLoading={type.id === selectedID}
        />
      </td>
    </tr>
  );
};

export default Item;
