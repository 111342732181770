import createSelector from 'utils/reselect';

const NotificationStateData = (state: any) => {
  const { notificationReducer } = state;
  return notificationReducer || {};
};

export const GetNotificationSelector = createSelector(
  NotificationStateData,
  (notificationreducer) => notificationreducer.get('notifications') || [],
);
export const GetPaginationSelector = createSelector(
  NotificationStateData,
  (notificationreducer) => notificationreducer.get('pagination') || [],
);
export const GetLoaderSelector = createSelector(
  NotificationStateData,
  (notificationreducer) => notificationreducer.get('loading') || false,
);
export const getDataLoading = createSelector(
  NotificationStateData,
  (notificationreducer) => notificationreducer.get('dataLoading') || false,
);
