import { connect } from 'react-redux';
import {
  createUnionStatus,
  unionStatusFetching,
  deleteUnionStatus,
  updateUnionStatus,
} from 'store/actions/unionStatus.actions';
import { getDataLoading, getLoading, getUnionStatus } from 'store/selector/unionStatusSelector';
import unionStatusView from 'views/unionStatus/unionStatus';

const mapStateToProps = (state: any) => {
  const loader = getLoading(state);
  const unionStatusData = getUnionStatus(state);
  const isLoading = getDataLoading(state);
  return { loader, unionStatusData, isLoading };
};
const mapDispatchToProps = {
  createUnionStatus,
  getUnionStatus: unionStatusFetching,
  deleteUnionStatus,
  updateUnionStatus,
};
export default connect(mapStateToProps, mapDispatchToProps)(unionStatusView);
