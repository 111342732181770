import { fromJS } from 'immutable';
import { Notification } from 'modules/general/general.type';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  notifications: [],
  pagination: [],
  roles: [],
  dataLoading: false,
  loading: false,
  error: '',
});
export default function NotificationReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.NOTIFICATION_FETCHED.SUCCESS: {
      return state
        .set('notifications', action.data.data)
        .set('pagination', action.data.pagination)
        .set('dataLoading', false)
        .set('error', '');
    }
    case ACTIONS.NOTIFICATION_FETCHED.PENDING: {
      return state.set('dataLoading', true).set('erroe', '');
    }
    case ACTIONS.NOTIFICATION_FETCHED.ERROR: {
      return state.set('dataLoading', false).set('error', action.error);
    }
    case ACTIONS.NOTIFICATION_CREATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.NOTIFICATION_CREATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.NOTIFICATION_CREATE.SUCCESS: {
      const prevnotifications = state.get('notifications');
      prevnotifications.unshift(action.data);
      return state
        .set('notifications', [...prevnotifications])
        .set('loading', false)
        .set('error', '');
    }
    case ACTIONS.NOTIFICATION_DELETE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.NOTIFICATION_DELETE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.NOTIFICATION_DELETE.SUCCESS: {
      const prevnotifications = state.get('notifications');
      const CurrentNotifications = prevnotifications.filter((n: Notification) => n.id !== action.data);
      return state
        .set('notifications', [...CurrentNotifications])
        .set('loading', false)
        .set('error', '');
    }
    default:
      return state;
  }
}
