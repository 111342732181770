import React, { FunctionComponent, useEffect, useState } from 'react';
import { SubscriptionPlan, subscriptionPlanList } from 'modules/user/types';
import Style from 'views/subscription/setting.module.css';
import Item from 'views/subscription/item';
import Modal from 'components/controls/Modal/Modal';
import ModalBodyItem from 'views/subscription/modalbody';
import Loader from 'components/controls/Loader/Loader';

interface SubscriptionProps {
  getPlans: Function;
  updatePlan: Function;
  plans: subscriptionPlanList;
  isLoading: boolean;
}
const Subscription: FunctionComponent<SubscriptionProps> = (props: SubscriptionProps) => {
  const { getPlans, plans, updatePlan, isLoading } = props;
  const [Plans, setPlans] = useState<SubscriptionPlan[]>(plans);
  const [isshow, setisshow] = useState<boolean>(false);
  const [selecteditem, setselecteditem] = useState<any>();
  useEffect(() => {
    getPlans();
  }, []);
  useEffect(() => {
    setPlans(plans);
  }, [plans]);

  const EditSubscription = (item: SubscriptionPlan) => {
    setselecteditem(item);
    setisshow(true);
  };

  const closemodel = () => {
    setisshow(false);
    setselecteditem('');
  };

  const modelbody = () => <ModalBodyItem item={selecteditem} ClickCancle={closemodel} ClickHandle={UpdateModalData} />;

  const UpdateModalData = (data: SubscriptionPlan) => {
    setisshow(false);
    updatePlan(data);
  };

  const loadComponent = () => (
    <div className={`${Style.profileWrapper}`}>
      <div className="d-flex justify-content-between">
        {Plans &&
          Plans.map((p: SubscriptionPlan, index: number) => (
            <Item key={index} item={p} index={index} clickHandle={EditSubscription} />
          ))}
      </div>
    </div>
  );

  return (
    <div className="card">
      <div className="card-header" style={{ textAlign: 'center' }}>
        Subscription Plans
      </div>
      <div className="card-body">
        {isLoading ? <Loader isSmallView /> : loadComponent()}
        {isshow ? <Modal title="Edit Subscription Plan" body={modelbody} closeModal={closemodel} /> : null}
      </div>
    </div>
  );
};

export default Subscription;
