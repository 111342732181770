import { combineReducers } from 'redux';
import userReducer from 'store/reducers/auth.reducers';
import ACTIONS from 'store/castingPax.constants';
import usersReducer from 'store/reducers/user.reducers';
import CategoryReducer from 'store/reducers/category.reducers';
import JobReducer from 'store/reducers/job.reducer';
import SubsciptionReducer from 'store/reducers/subscription.reducer';
import LocationReducer from 'store/reducers/location.reducer';
import NotificationReducer from 'store/reducers/notification.reducers';
import helpcenterReducer from 'store/reducers/helpcenter.reducers';
import DefaulttypeReducer from 'store/reducers/defaulttype.reducers';
import EmailcampaignReducer from 'store/reducers/emailcampaign.reducer';
import DashboardReducer from 'store/reducers/dashboard.reducers';
import UnionStatusReducer from 'store/reducers/unionStatus.reducer';
import InvoicesReducer from 'store/reducers/invoices.reducers';
import showReducer from 'store/reducers/show.reducers';
import rateReducer from 'store/reducers/rate.reducers';

const appReducer = combineReducers({
  users: userReducer,
  userReducer: usersReducer,
  categoryReducer: CategoryReducer,
  jobReducer: JobReducer,
  subscriptionReducer: SubsciptionReducer,
  locationReducer: LocationReducer,
  notificationReducer: NotificationReducer,
  helpCenterReducer: helpcenterReducer,
  defaultTypeReducer: DefaulttypeReducer,
  emailCampaignReducer: EmailcampaignReducer,
  dashboardReducer: DashboardReducer,
  unionStatusReducer: UnionStatusReducer,
  invoicesReducer: InvoicesReducer,
  showReducer,
  rateReducer,
});

const rootReducer = (state: any, action: any) => {
  let prevState = state;
  if (action.type === ACTIONS.AUTH_LOGOUT) prevState = undefined;

  return appReducer(prevState, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
