import React, { FunctionComponent, useState } from 'react';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import { Button } from 'react-bootstrap';

interface IModelItem {
  title: string | undefined;
  answer?: string | undefined;
  handleSave: Function;
  handleCancle: Function;
  node: any;
  mode: string | undefined;
}
const ModalItem: FunctionComponent<IModelItem> = (Props: IModelItem) => {
  const { title, handleSave, handleCancle, answer, node, mode } = Props;
  const [titleInput, setTitleInput] = useState(title);
  const [answerInput, setAnswerInput] = useState(answer);
  console.log('node', node, mode);
  return (
    <div>
      <CustomInput type="text" placeholder="Enter Title" value={titleInput} handleChange={setTitleInput} />
      {node.children && node.id !== '-1' && mode === 'add' ? (
        <CustomInput type="text" placeholder="Enter Answer" value={answerInput} handleChange={setAnswerInput} />
      ) : null}
      {node.answer && mode === 'edit' ? (
        <CustomInput type="text" placeholder="Enter Answer" value={answerInput} handleChange={setAnswerInput} />
      ) : null}
      <Button className="mr-2" onClick={() => handleSave(titleInput, answerInput)}>
        {title === '' ? 'Save' : 'Update'}
      </Button>
      <Button onClick={() => handleCancle()}>Cancel</Button>
    </div>
  );
};

export default ModalItem;
