import React, { FunctionComponent, useState, useEffect } from 'react';
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import { HelpCenterItem, HelpCenterList } from 'modules/general/general.type';
import { IHelpcentersort } from 'modules/params/type';
import { Button } from 'react-bootstrap';
import Delete from 'assets/svg/delete.svg';
import Edit from 'assets/svg/edit.svg';
import plus from 'assets/svg/plus.svg';
import Modal from 'components/controls/Modal/Modal';
import ModelBody from 'views/helpcenter/modalbody';
import api from 'store/services/helpcenter.services';

interface ITreeProps {
  helpCenter: HelpCenterItem;
  items: HelpCenterList;
  removeHelpCenterParent: Function;
  createHelpCenterParent: Function;
  createHelpCenterChildren: Function;
  removeHelpCenterChildren: Function;
  updateHelpCenterParent: Function;
  updateHelpCenterChildren: Function;
}

const HelpcenterTree: FunctionComponent<ITreeProps> = (props: ITreeProps) => {
  const {
    items,
    helpCenter,
    removeHelpCenterParent,
    removeHelpCenterChildren,
    createHelpCenterParent,
    createHelpCenterChildren,
    updateHelpCenterParent,
    updateHelpCenterChildren,
  } = props;
  const [TreeData, setTreeData] = useState<any>(items);
  const [prevData, setPrevData] = useState<any>();
  const [isShow, setIsShow] = useState<boolean>();
  const [mode, setMode] = useState<string>();
  const [selectedNode, setSelectedNode] = useState<any>('');
  const [titleInput, setTitleInput] = useState<string>();
  const [answerInput, setAnswerInput] = useState<string>();

  useEffect(() => {
    console.log('mount');
    return () => {
      console.log('unmound');
      setTreeData({});
    };
  }, []);
  useEffect(() => {
    setTreeData(items);
  }, [items]);

  useEffect(() => {
    if (mode === 'delete') {
      setMode('');
      setUpdateData(selectedNode);
    }
    if (mode === 'add') {
      setMode('');
      setAnswerInput('');
      setNewData(helpCenter);
    }
  }, [helpCenter]);

  const onChange = (treeData: any) => {
    setPrevData(TreeData);
    setTreeData(treeData);
  };
  const closeModel = () => {
    setIsShow(false);
    setMode('');
    setTitleInput('');
    setAnswerInput('');
  };
  const modelBody = () => (
    <ModelBody
      mode={mode}
      node={selectedNode.node}
      answer={answerInput}
      title={titleInput}
      handleSave={updateModalData}
      handleCancle={closeModel}
    />
  );

  const addNode = (rowInfo: any) => {
    setMode('add');
    setIsShow(true);
    setSelectedNode(rowInfo);
    setTitleInput('');
  };

  const setNewData = (data: any) => {
    const Newdata = TreeData;
    if (selectedNode.node.id !== '-1') {
      const index = Newdata.findIndex((h: HelpCenterItem) => h.id === data.categoryId);
      if (index !== -1) {
        const cat = Newdata[index];
        if (!cat.children) {
          cat.children = [];
        }
        cat.children.push(data);
        Newdata.splice(index, 1, { ...cat });
      }
    } else {
      const newdata = data;
      newdata.children = [];
      Newdata.push(newdata);
    }
    setTreeData([...Newdata]);
    setSelectedNode('');
    setTitleInput('');
    setAnswerInput('');
  };

  const editTitle = (rowInfo: any) => {
    setSelectedNode(rowInfo);
    setTitleInput(rowInfo.node.title);
    if (rowInfo.node.answer) setAnswerInput(rowInfo.node.answer);
    else setAnswerInput('');
    setMode('edit');
    setIsShow(true);
  };

  const removeNode = (rowInfo: any) => {
    setMode('delete');
    setSelectedNode(rowInfo);
    if (rowInfo.node.children) removeHelpCenterParent({ id: rowInfo.node.id });
    else removeHelpCenterChildren({ id: rowInfo.node.id });
  };

  const updateModalData = (catTitle: string, catAnswer: string) => {
    const data: any = {
      title: catTitle,
    };
    if (mode === 'add') {
      if (selectedNode.node.id === '-1') {
        if (TreeData.length === 1 && TreeData.length <= 0) {
          data.itemPosition = 0;
        } else {
          data.itemPosition = TreeData.length - 1;
        }
        createHelpCenterParent(data);
      } else {
        data.itemPosition = TreeData.length - 1;
        data.categoryId = selectedNode.node.id;
        data.answer = catAnswer;
        createHelpCenterChildren(data);
      }
    } else {
      selectedNode.node.title = data.title;
      data.id = selectedNode.node.id;
      if (selectedNode.node.categoryId) {
        data.answer = catAnswer;
        data.categoryId = selectedNode.node.categoryId;
        data.itemPosition = selectedNode.node.itemPosition;
        selectedNode.node.answer = data.answer;
        updateHelpCenterChildren(data);
      } else {
        updateHelpCenterParent(data);
      }
    }
    setIsShow(false);
    setTitleInput('');
    setAnswerInput('');
  };
  const setUpdateData = (rowInfo: any) => {
    const index = TreeData.findIndex((h: HelpCenterItem) => h.id === rowInfo.node.id);
    if (index !== -1) {
      const data = TreeData.filter((h: HelpCenterItem) => h.id !== rowInfo.node.id);
      setTreeData([...data]);
    } else {
      const data = TreeData;
      data.map((centeritem: HelpCenterItem) => {
        const child = centeritem;
        if (child.children) {
          const pos = child.children.findIndex((h: HelpCenterItem) => h.id === rowInfo.node.id);
          if (pos !== -1) {
            child.children.splice(pos, 1);
          }
        }
        return { ...child };
      });
      setTreeData([...data]);
    }
    setSelectedNode('');
  };
  const onMoveNode = (event: any) => {
    let flag = 0;
    let flag1 = 0;
    if (event.nextParentNode) {
      flag = 1;
      if (!event.nextParentNode.categoryId && !event.node.categoryId) {
        setTreeData(prevData);
      }
    }
    if (event.nextTreeIndex === 0) {
      flag1 = 1;
      setTreeData(prevData);
    }
    if (flag !== 1 && flag1 !== 1) {
      const { treeData } = event;
      const helpitems: any = treeData.map((h: HelpCenterItem) => {
        const obj: IHelpcentersort = { id: h.id };
        if (h.children) {
          obj.children = h.children.map((c: HelpCenterItem) => {
            const childObj: IHelpcentersort = { id: c.id, categoryId: h.id };
            return childObj;
          });
        }
        return obj;
      });
      helpitems.splice(0, 1);
      api.ReOrderSkills({ helpitems }).catch((err) => err);
      console.log('Sorting', helpitems);
    }
  };
  return (
    <>
      <div style={{ height: '800px' }}>
        <SortableTree
          canDrag={(node) => node.node.id !== '-1'}
          treeData={TreeData}
          maxDepth={2}
          onChange={(treeData) => onChange(treeData)}
          onMoveNode={onMoveNode}
          generateNodeProps={(rowInfo) => ({
            buttons: [
              <div>
                {!rowInfo.parentNode ? (
                  <Button className="mr-2" onClick={() => addNode(rowInfo)}>
                    <img src={plus} alt="Addbutton" width="15px" />
                  </Button>
                ) : null}
                {rowInfo.node.id !== '-1' ? (
                  <Button className="mr-2" style={{ background: 'white' }} onClick={() => editTitle(rowInfo)}>
                    <img src={Edit} alt="editbutton" width="15px" />
                  </Button>
                ) : null}
                {(!rowInfo.node.children || rowInfo.node.children.length < 1) && rowInfo.node.id !== '-1' ? (
                  <Button style={{ background: 'white' }} onClick={() => removeNode(rowInfo)}>
                    <img src={Delete} alt="deletebutton" width="15px" />
                  </Button>
                ) : null}
              </div>,
            ],
          })}
        />
      </div>
      {isShow ? (
        <Modal
          title={mode === 'add' ? 'Enter New Category Title' : 'Update Category Title'}
          body={modelBody}
          closeModal={closeModel}
        />
      ) : null}
    </>
  );
};
export default HelpcenterTree;
