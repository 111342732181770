import { IDefaulttype, IfetchTypes } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import {
  URL_DEFAULT_TYPE,
  URL_DEFAULT_CATEGORY,
  URL_DEFAULT_TYPE_LIST,
  URL_DEFAULT_TYPE_DELETE,
} from 'store/services/URL';

export const api = {
  GetTypes(payload: IfetchTypes) {
    return apiCall.post({ url: URL_DEFAULT_TYPE_LIST, isAuthToken: true, params: payload });
  },
  GetDefaultCategories() {
    return apiCall.get({ url: URL_DEFAULT_CATEGORY, isAuthToken: true, params: {} });
  },
  CreateDefaultType(payload: IDefaulttype) {
    return apiCall.post({ url: URL_DEFAULT_TYPE, isAuthToken: true, params: payload });
  },
  UpdateDefaultType(payload: IDefaulttype) {
    return apiCall.put({ url: URL_DEFAULT_TYPE, isAuthToken: true, params: payload });
  },
  DeleteDefaultType(payload: string) {
    return apiCall.post({ url: URL_DEFAULT_TYPE_DELETE, isAuthToken: true, params: payload });
  },
};

export default api;
