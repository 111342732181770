import { fromJS } from 'immutable';
import ACTIONS from 'store/castingPax.constants';

const initialState = fromJS({
  jobs: [],
  pagination: {},
  loading: false,
  error: '',
  roles: [],
});
export default function JobReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.JOBS_FETCHED.PENDING: {
      return state.set('loading', true).set('error', false);
    }
    case ACTIONS.JOBS_FETCHED.SUCCESS: {
      return state
        .set('jobs', action.data.data)
        .set('pagination', action.data.pagination)
        .set('loading', false)
        .set('error', false);
    }
    case ACTIONS.JOBS_FETCHED.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.ROLE_FETCHED: {
      return state.set('roles', action.data.data);
    }
    default:
      return state;
  }
}
