import { all, call, takeEvery, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/dashboard.services';
import ApiResponse from 'modules/response/response.types';
import { dashboardActions } from 'store/actions';

function* GetStats() {
  try {
    yield put(dashboardActions.DashboardStats.pending);
    const response: ApiResponse = yield call(api.GetStats);
    if (response) yield put(dashboardActions.DashboardStats.success(response));
  } catch (error: any) {
    yield put(dashboardActions.DashboardStats.error(error.message));
  }
}
function* getChartData() {
  try {
    yield put(dashboardActions.dashboardChartData.pending);
    const response: any = yield call(api.getChartData);
    if (response) {
      yield put(dashboardActions.dashboardChartData.success(response));
    }
  } catch (error: any) {
    yield put(dashboardActions.dashboardChartData.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.DASHBOARD_STATS_CALL, GetStats),
    takeEvery(ACTIONS.DASHBOARD_CHART_DATA_CALL, getChartData),
  ]);
}
