import { connect } from 'react-redux';
import Categoryview from 'views/category/Category';
import { FetchingCategories, CategoryUpdate, DeletingCategory, AddingCategory } from 'store/actions/category.actions';
import { getCategoriesSelector, getCategorySelector, getDataLoading } from 'store/selector/category.Selector';

const mapDispatchToProps = {
  getCategories: FetchingCategories,
  updateCategory: CategoryUpdate,
  removeCategory: DeletingCategory,
  createCategory: AddingCategory,
};

const mapStateToProps = (state: any) => {
  const Categories = getCategoriesSelector(state);
  const Category = getCategorySelector(state);
  const isDataLoading = getDataLoading(state);
  return {
    categories: Categories,
    addCategory: Category,
    isDataLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categoryview);
