import { all, call, takeEvery, put } from 'redux-saga/effects';
import { IfetchShows, IUpdateAccount } from 'modules/params/type';
import { showActions } from 'store/actions';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/show.services';

function* getShows(state: { type: string; payload: IfetchShows }) {
  try {
    yield put(showActions.fetchShow.pending);
    const response: any = yield call(api.getShows, state.payload);
    if (response) yield put(showActions.fetchShow.success(response[0]));
  } catch (error: any) {
    yield put(showActions.fetchShow.error(error.message));
  }
}
function* updateShowAccount(state: { type: string; payload: IUpdateAccount }) {
  try {
    yield put(showActions.updateShowAccount.pending(state.payload));
    const response: boolean = yield call(api.updateAccount, state.payload);
    if (response) {
      yield put(showActions.updateShowAccount.success(state.payload));
    }
  } catch (error: any) {
    yield put(showActions.updateShowAccount.error(error));
    console.log('error in update account', error.message);
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.SHOW_FETCHING, getShows),
    takeEvery(ACTIONS.UPDATE_SHOW_ACCOUNT_BEGIN, updateShowAccount),
  ]);
}
