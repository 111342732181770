import { all, call, takeEvery, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/notification.services';
import { notificationActions } from 'store/actions';
import { IfetchNotications, INotification } from 'modules/params/type';
import { NotificationList } from 'modules/general/general.type';
import ApiResponse from 'modules/response/response.types';

function* GetNotications(state: { type: string; payload: IfetchNotications }) {
  try {
    yield put(notificationActions.NotificationFetched.pending);
    const response: NotificationList = yield call(api.GetNotifications, state.payload);
    if (response) yield put(notificationActions.NotificationFetched.success(response));
  } catch (error: any) {
    yield put(notificationActions.NotificationFetched.error(error.message));
  }
}
function* CreateNotification(state: { type: string; payload: INotification }) {
  try {
    yield put(notificationActions.CreateNotificationStatus.pending);
    const response: ApiResponse = yield call(api.CreateNotification, state.payload);
    if (response) yield put(notificationActions.CreateNotificationStatus.success(response));
  } catch (error: any) {
    yield put(notificationActions.CreateNotificationStatus.error(error.message));
  }
}
function* DeleteNotificaion(state: { type: string; payload: string }) {
  try {
    yield put(notificationActions.DeleteNotificationStatus.pending);
    const response: ApiResponse = yield call(api.DeleteNotification, state.payload);
    if (response.data) yield put(notificationActions.DeleteNotificationStatus.success(state.payload));
  } catch (error: any) {
    yield put(notificationActions.DeleteNotificationStatus.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.NOTIFICATION_FETCHING, GetNotications),
    takeEvery(ACTIONS.NOTIFICATION_CREATE_CALL, CreateNotification),
    takeEvery(ACTIONS.NOTIFICATION_DELETE_CALL, DeleteNotificaion),
  ]);
}
