import React, { FunctionComponent } from 'react';
import { Rate } from 'modules/general/general.type';
import Button from 'components/controls/button/button';

interface ItemProps {
  rate: Rate;
  SelectedId: string | undefined;
  deleteRate: Function;
  updateRate: Function;
}

const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { rate, SelectedId, deleteRate, updateRate } = props;

  return (
    <tr>
      <td>{rate.title}</td>
      <td>{rate.code}</td>
      <td>{rate.rate}</td>
      <td>
        <Button label="Edit" clickHandler={() => updateRate(rate)} />
        &ensp;
        <Button
          label="Delete"
          type="danger"
          clickHandler={() => deleteRate(rate.id)}
          showLoading={rate.id === SelectedId}
        />
      </td>
    </tr>
  );
};

export default Item;
