import { fromJS } from 'immutable';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

const initialState = fromJS({
  helpcenterlist: [],
  helpcenter: {},
  dataLoading: false,
  loading: false,
  error: '',
});
export default function helpcenterReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case ACTIONS.HELPCENTER_FETCH.PENDING: {
      return state.set('dataLoading', action.status === FETCH_STATUS.LOADING).set('error', '');
    }
    case ACTIONS.HELPCENTER_FETCH.SUCCESS: {
      return state.set('helpcenterlist', action.data).set('dataLoading', false).set('error', '');
    }
    case ACTIONS.HELPCENTER_FETCH.ERROR: {
      return state.set('dataLoading', false).set('error', action.error);
    }
    case ACTIONS.HELPCENTER_CREATE: {
      return state.set('helpcenter', action.data);
    }
    case ACTIONS.HELPCENTER_DELETE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.HELPCENTER_DELETE.SUCCESS: {
      return state.set('loading', false).set('error', '').set('helpcenter', { id: action.data.id.id, status: true });
    }
    case ACTIONS.HELPCENTER_DELETE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    case ACTIONS.HELPCENTER_UPDATE.PENDING: {
      return state.set('loading', action.status === 'LOADING');
    }
    case ACTIONS.HELPCENTER_UPDATE.SUCCESS: {
      return state.set('loading', false).set('error', '');
    }
    case ACTIONS.HELPCENTER_UPDATE.ERROR: {
      return state.set('loading', false).set('error', action.error);
    }
    default:
      return state;
  }
}
