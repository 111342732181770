export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = 'http://localhost:8000/api/';
// export const BASE_URL = 'http://localhost:5000/api/';

export const URL_LOGIN = `${BASE_URL}admin/v1/login`;
export const URL_PROFILE_FILE = `${BASE_URL}profile/upload_file`;

export const URL_GENERAL_SETTING = `${BASE_URL}admin/v1/general_setting`;

export const URL_USER_LISTING = `${BASE_URL}admin/v1/users`;
export const URL_USER_STATUS = `${BASE_URL}admin/v1/change_user_status`;

export const URL_CATEGORY_FETCH = `${BASE_URL}admin/v1/categories`;
export const URL_CATEGORY_DELETE = `${BASE_URL}admin/v1/delete_categories`;
export const URL_CATEGORY_REORDER = `${BASE_URL}admin/v1/sort_categories`;

export const URL_JOB_FETCH = `${BASE_URL}admin/v1/jobs`;

export const URL_SUBSCRIPTION_PLAN = `${BASE_URL}admin/v1/subscriptions`;

export const URL_NOTIFICATION = `${BASE_URL}admin/v1/notifications`;
export const URL_NOTIFICATION_DELETE = `${BASE_URL}admin/v1/delete_notifications`;

export const URL_ROLE = `${BASE_URL}roles/all_roles`;

export const URL_LOCATION = `${BASE_URL}admin/v1/locations`;
export const URL_LOCATION_DELETE = `${BASE_URL}admin/v1/delete_locations`;

export const URL_UNION_STATUS = `${BASE_URL}admin/v1/union_status`;
export const URL_UNION_STATUS_DELETE = `${BASE_URL}admin/v1/delete_union_status`;

export const URL_HELPCENTER = `${BASE_URL}admin/v1/help_center`;
export const URL_HELPCENTER_PARENT_CREATE = `${BASE_URL}admin/v1/help_center_category`;
export const URL_HELPCENTER_CHILDREN_CREATE = `${BASE_URL}admin/v1/help_center_question`;
export const URL_HELPCENTER_PARENT_DELETE = `${BASE_URL}admin/v1/delete_hc_category`;
export const URL_HELPCENTER_CHILDREN_DELETE = `${BASE_URL}admin/v1/delete_hc_question`;

export const URL_DEFAULT_TYPE = `${BASE_URL}admin/v1/default_types`;
export const URL_DEFAULT_CATEGORY = `${BASE_URL}admin/v1/default_categories`;
export const URL_DEFAULT_TYPE_LIST = `${BASE_URL}admin/v1/default_types_list`;
export const URL_DEFAULT_TYPE_DELETE = `${BASE_URL}admin/v1/delete_default_types`;
export const URL_HELPCENTER_REORDER = `${BASE_URL}admin/v1/sort_hc`;

export const URL_EMAILCAMPAIGN = `${BASE_URL}admin/v1/email_campaigns`;
export const URL_EMAILCAMPAIGN_LIST = `${BASE_URL}admin/v1/email_campaigns_list`;

export const URL_ADMIN_DASHBOARD = `${BASE_URL}admin/v1/dashboard`;
export const URL_ADMIN_CHART = `${BASE_URL}admin/v1/chart_data`;

export const URL_INVOICES = `${BASE_URL}admin/v1/invoices`;

export const URL_SHOWS = `${BASE_URL}admin/v1/shows`;
export const URL_UPDATE_AMOUNT = `${BASE_URL}admin/v1/update_amount`;

export const URL_GENERATE_BILL = `${BASE_URL}matrix/generate_bill`;

export const URL_RATES = `${BASE_URL}admin/v1/performer_rate`;
export const URL_DELETE_RATES = `${BASE_URL}admin/v1/delete_performer_rate`;
