import { connect } from 'react-redux';
import { FetchingStats, getChartDataBegin } from 'store/actions/dashboard.actions';
import {
  getChartLoading,
  getJobChart,
  GetJobsSelector,
  getUserChart,
  GetUsersSelector,
} from 'store/selector/dashboard.selector';
import Dashboard from 'views/dashboard/Dashboard';

const mapDispatchToProps = {
  getChartData: getChartDataBegin,
  getStats: FetchingStats,
};
const mapStateToProps = (state: any) => {
  const jobs = GetJobsSelector(state);
  const allUsers = GetUsersSelector(state);
  const jobChart = getJobChart(state);
  const userChart = getUserChart(state);
  const chartLoading = getChartLoading(state);
  return { jobs, allUsers, jobChart, userChart, chartLoading };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
