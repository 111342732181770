import { all, call, takeEvery, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import { rateActions } from 'store/actions';
import api from 'store/services/rate.services';
import { Rate, RateList } from 'modules/general/general.type';
import { IDeleteRate, IRateParams, IUpdateRate } from 'modules/params/type';

function* getRates() {
  try {
    yield put(rateActions.rateFetched.pending);
    const response: RateList = yield call(api.getRates);
    if (response) yield put(rateActions.rateFetched.success(response));
  } catch (error: any) {
    yield put(rateActions.rateFetched.error(error.message));
  }
}
function* createRate(state: { type: string; payload: IRateParams }) {
  try {
    yield put(rateActions.createRate.pending);
    const response: any = yield call(api.createRate, state.payload);
    if (response) yield put(rateActions.createRate.success(response));
  } catch (err: any) {
    yield put(rateActions.createRate.error(err.message));
  }
}
function* deleteRate(state: { type: string; payload: IDeleteRate }) {
  try {
    yield put(rateActions.deleteRate.pending);
    const response: any = yield call(api.deleteRate, state.payload);
    if (response.data) yield put(rateActions.deleteRate.success(state.payload.id));
  } catch (err: any) {
    yield put(rateActions.deleteRate.error(err.message));
  }
}
function* updateRate(state: { type: string; payload: IUpdateRate }) {
  try {
    yield put(rateActions.updateRate.pending);
    const response: Rate = yield call(api.updateRate, state.payload);
    if (response) yield put(rateActions.updateRate.success(response));
  } catch (err: any) {
    yield put(rateActions.updateRate.error(err.message));
  }
}
export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.RATE_FETCHING, getRates),
    takeEvery(ACTIONS.CREATE_RATE_BEGIN, createRate),
    takeEvery(ACTIONS.DELETE_RATE_BEGIN, deleteRate),
    takeEvery(ACTIONS.UPDATE_RATE_BEGIN, updateRate),
  ]);
}
