import { all, call, put, takeEvery } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/location.services';
import ApiResponse from 'modules/response/response.types';
import { locationActions } from 'store/actions';
import { Location, LocationList } from 'modules/user/types';

function* GetLocations() {
  try {
    yield put(locationActions.locationfetched.pending);
    const response: LocationList = yield call(api.GetLocations);
    if (response) yield put(locationActions.locationfetched.success(response));
  } catch (error: any) {
    yield put(locationActions.locationfetched.error(error.message));
  }
}
function* UpdatingLocation(state: { type: string; payload: Location }) {
  try {
    yield put(locationActions.UpdateLocation.pending);
    const response: ApiResponse = yield call(api.UpdateLocation, state.payload);
    if (response) yield put(locationActions.UpdateLocation.success(state.payload));
  } catch (error: any) {
    yield put(locationActions.UpdateLocation.error(error.message));
  }
}
function* AddLocation(state: { type: string; payload: string }) {
  try {
    yield put(locationActions.CreateLocationStatus.pending);
    const response: ApiResponse = yield call(api.AddLocation, state.payload);
    if (response) yield put(locationActions.CreateLocationStatus.success(response));
  } catch (error: any) {
    yield put(locationActions.CreateLocationStatus.error(error.message));
  }
}
function* DeleteLocation(state: { type: string; payload: string }) {
  try {
    yield put(locationActions.DeleteLocationStatus.pending);
    const response: ApiResponse = yield call(api.DeleteLocation, state.payload);
    if (response) yield put(locationActions.DeleteLocationStatus.success(state.payload));
  } catch (error: any) {
    yield put(locationActions.DeleteLocationStatus.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.LOCATION_FETCHING, GetLocations),
    takeEvery(ACTIONS.LOCATION_UPDATE_CALL, UpdatingLocation),
    takeEvery(ACTIONS.LOCATION_CREATE_CALL, AddLocation),
    takeEvery(ACTIONS.LOCATION_DELETE_CALL, DeleteLocation),
  ]);
}
