import React, { FunctionComponent } from 'react';
import { Notification, Role } from 'modules/general/general.type';
import Button from 'components/controls/button/button';

interface ItemProps {
  notification: Notification;
  deleteNotification: Function;
  SelectedID: string | undefined;
}
const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { notification, deleteNotification, SelectedID } = props;

  return (
    <tr>
      <td>{notification.title}</td>
      <td>{notification.message}</td>
      <td>
        {notification.roles.map((r: Role, index: number) => (
          <div key={index}>{r.name}</div>
        ))}
      </td>
      <td>
        <Button
          label="Delete"
          type="danger"
          clickHandler={() => deleteNotification(notification.id)}
          showLoading={notification.id === SelectedID}
        />
      </td>
    </tr>
  );
};
export default Item;
