import { apiCall } from 'store/services/service';
import { URL_ADMIN_CHART, URL_ADMIN_DASHBOARD } from 'store/services/URL';

export const api = {
  GetStats() {
    return apiCall.get({ url: URL_ADMIN_DASHBOARD, isAuthToken: true, params: {} });
  },
  getChartData() {
    return apiCall.get({ url: URL_ADMIN_CHART, isAuthToken: false, params: {} });
  },
};

export default api;
