import { IEmailCampaignParam, IFetchEmails } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import { URL_EMAILCAMPAIGN, URL_EMAILCAMPAIGN_LIST } from 'store/services/URL';

export const api = {
  CreateEmailCampaign(payload: IEmailCampaignParam) {
    return apiCall.filePost(URL_EMAILCAMPAIGN, payload);
  },
  getEmailCampaigns(payload: IFetchEmails) {
    return apiCall.post({ url: URL_EMAILCAMPAIGN_LIST, isAuthToken: true, params: payload });
  },
  updateEmailCampaigns(payload: IEmailCampaignParam) {
    return apiCall.put({ url: URL_EMAILCAMPAIGN, isAuthToken: true, params: payload });
  },
};

export default api;
