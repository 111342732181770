import React, { FunctionComponent } from 'react';
import ModalHeader from 'components/controls/Modal/ModalHeader';
import ModalBody from 'components/controls/Modal/ModalBody';
import Style from 'components/controls/Modal/Modal.module.css';

interface ModalProps {
  maxWidth?: boolean;
  title: string;
  body: Function;
  closeModal: (event: React.FormEvent<HTMLDivElement>) => void;
  icon?: string;
  cssClass?: string;
}
const Modal: FunctionComponent<ModalProps> = (props: ModalProps) => {
  const { title, body, closeModal, icon, cssClass, maxWidth } = props;
  const getMaxWidth = () => {
    if (maxWidth) return Style.modalMaxContentWrapper;
    return '';
  };
  return (
    <>
      <div className={`${Style.modalContentWrapper} ${cssClass} ${getMaxWidth()}`}>
        <ModalHeader title={title} handleClick={closeModal} icon={icon} />
        <ModalBody>{body()}</ModalBody>
      </div>
      <div onClick={closeModal} tabIndex={0} role="button" onKeyPress={closeModal} className={`${Style.bgLayer}`} />
    </>
  );
};

export default Modal;
