import createSelector from 'utils/reselect';

const unionStateData = (state: any) => {
  const { unionStatusReducer } = state;
  return unionStatusReducer || {};
};

export const getUnionStatus = createSelector(
  unionStateData,
  (unionStatusReducer) => unionStatusReducer.get('unionStatus') || [],
);
export const getLoading = createSelector(
  unionStateData,
  (unionStatusReducer) => unionStatusReducer.get('loading') || false,
);

export const getDataLoading = createSelector(
  unionStateData,
  (unionStatusReducer) => unionStatusReducer.get('dataLoading') || false,
);
