import React, { FunctionComponent } from 'react';
import checkboxStyle from 'components/controls/checkbox/checkbox.module.css';

interface CheckBoxProps {
  item?: any;
  id: string;
  label?: string;
  handleChecked?: Function;
  defaultCheck: boolean;
}
const CheckBox: FunctionComponent<CheckBoxProps> = (props: CheckBoxProps) => {
  const { id, label, handleChecked, item, defaultCheck } = props;

  return (
    <>
      <div className={`custom-control custom-checkbox ${checkboxStyle.customCheckBoxWrapper}`}>
        <input
          type="checkbox"
          className={`custom-control-input ${checkboxStyle.customControlInput}`}
          id={id}
          name={id}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (handleChecked) handleChecked(e.target.checked, item);
          }}
          checked={defaultCheck}
        />
        <label className={`custom-control-label ${checkboxStyle.customControlLabel}`} htmlFor={id}>
          {label}
        </label>
      </div>
    </>
  );
};
export default CheckBox;
