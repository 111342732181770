import { IDeleteRate, IRateParams, IUpdateRate } from 'modules/params/type';
import { apiCall } from 'store/services/service';
import { URL_DELETE_RATES, URL_RATES } from 'store/services/URL';

export const RoleApi = {
  getRates() {
    return apiCall.get({ url: URL_RATES, isAuthToken: true, params: {} });
  },
  createRate(params: IRateParams) {
    return apiCall.post({ url: URL_RATES, isAuthToken: true, params });
  },
  deleteRate(params: IDeleteRate) {
    return apiCall.post({ url: URL_DELETE_RATES, isAuthToken: true, params });
  },
  updateRate(params: IUpdateRate) {
    return apiCall.put({ url: URL_RATES, isAuthToken: true, params });
  },
};

export default RoleApi;
