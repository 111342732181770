import createSelector from 'utils/reselect';

const userStateData = (state: any) => {
  const { userReducer } = state;
  return userReducer || {};
};

export const Getpagination = createSelector(userStateData, (userReducer) => userReducer.get('pagination') || {});
export const Getusersselector = createSelector(userStateData, (userReducer) => userReducer.get('users') || []);
export const getLoading = createSelector(userStateData, (userReducer) => userReducer.get('loading') || false);
export const getDataLoading = createSelector(userStateData, (userReducer) => userReducer.get('dataLoading') || false);
