import { SubscriptionPlan, subscriptionPlanList } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const FetchingPlans = () => ({
  type: ACTIONS.PLAN_FETCHING,
});

export const PlansFetched = {
  pending: {
    type: ACTIONS.PLAN_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: subscriptionPlanList) => ({
    type: ACTIONS.PLAN_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.PLAN_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const PlanUpdating = (data: SubscriptionPlan) => ({
  type: ACTIONS.PLAN_UPDATE_CALL,
  payload: data,
});
export const PlanUpdated = (data: SubscriptionPlan) => ({
  type: ACTIONS.PLAN_UPDATE,
  data,
});
