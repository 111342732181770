import { IProfileFileParam } from 'modules/params/type';
import { IGeneralSetting, LOGIN_PARAMS } from 'modules/user/types';
import { apiCall } from 'store/services/service';
import { URL_LOGIN, URL_PROFILE_FILE, URL_GENERAL_SETTING } from 'store/services/URL';

export const api = {
  loginRequest(loginParams: LOGIN_PARAMS) {
    return apiCall.post({ url: URL_LOGIN, isAuthToken: false, params: loginParams });
  },

  profileFiles(params: IProfileFileParam) {
    return apiCall.filePost(URL_PROFILE_FILE, params);
  },
  generalSetting(generalParams: IGeneralSetting) {
    return apiCall.post({ url: URL_GENERAL_SETTING, isAuthToken: false, params: generalParams });
  },
  getGeneralSetting() {
    return apiCall.get({ url: URL_GENERAL_SETTING, isAuthToken: false, params: {} });
  },
};

export default api;
