import { all, call, put, takeEvery } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/unionStatus.services';
// import ApiResponse from 'modules/response/response.types';
import { unionStatusActions } from 'store/actions';
import { IUnionStatus } from 'modules/params/type';
import { UnionStatus, UnionStatusList } from 'modules/user/types';

function* addUnionStatus(state: { type: string; payload: IUnionStatus }) {
  try {
    yield put(unionStatusActions.unionStatusCall.pending);
    const response: UnionStatus = yield call(api.addUnionStatus, state.payload);
    if (response) yield put(unionStatusActions.unionStatusCall.success(response));
  } catch (error: any) {
    yield put(unionStatusActions.unionStatusCall.error(error.message));
  }
}
function* getUnionStatus() {
  try {
    yield put(unionStatusActions.unionStatusFetched.pending);
    const response: UnionStatusList = yield call(api.getUnionStatus);
    if (response) yield put(unionStatusActions.unionStatusFetched.success(response));
  } catch (error: any) {
    yield put(unionStatusActions.unionStatusFetched.error(error.message));
  }
}
function* deleteUnionStatus(state: { type: string; payload: string }) {
  try {
    yield put(unionStatusActions.deleteUnionCall.pending);
    const response: boolean = yield call(api.deleteUnionStatus, state.payload);
    if (response) yield put(unionStatusActions.deleteUnionCall.success(state.payload));
  } catch (error: any) {
    yield put(unionStatusActions.deleteUnionCall.error(error.message));
  }
}
function* updateUnionStatus(state: { type: string; payload: IUnionStatus }) {
  try {
    yield put(unionStatusActions.updateUnionCall.pending);
    const response: UnionStatus = yield call(api.updateUnionStatus, state.payload);
    if (response) yield put(unionStatusActions.updateUnionCall.success(state.payload));
  } catch (error: any) {
    yield put(unionStatusActions.updateUnionCall.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(ACTIONS.UNION_STATUS_CREATE_CALL, addUnionStatus),
    takeEvery(ACTIONS.UNION_STATUS_FETCHING, getUnionStatus),
    takeEvery(ACTIONS.UNION_STATUS_DELETE_CALL, deleteUnionStatus),
    takeEvery(ACTIONS.UNION_STATUS_UPDATE_CALL, updateUnionStatus),
  ]);
}
