import { connect } from 'react-redux';
import { fethingInvoices } from 'store/actions/invoices.actions';
import { getInvociesSelector, getLoading, getPaginationSelector } from 'store/selector/invoices.selector';
import Invoices from 'views/invoices/Invoices';

const mapDispatchToProps = {
  fetchInvoices: fethingInvoices,
};

const mapStateToProps = (state: any) => {
  const totalInvoices = getInvociesSelector(state);
  const pagination = getPaginationSelector(state);
  const loading = getLoading(state);
  return {
    totalInvoices,
    pagination,
    loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
