import defaulttype from 'views/defaulttype/defaulttype';
import { connect } from 'react-redux';
import {
  FetchingTypes,
  FetchingCategories,
  CreateDefaultType,
  UpdatingDefaultType,
  DeletingDefaultType,
} from 'store/actions/defaulttype.actions';
import {
  GetDefaulttypessSelector,
  GetPaginationSelector,
  GetCategoriesSelector,
  TypeProgress,
  getDataLoading,
} from 'store/selector/defaulttype.selector';

const mapDispatchToProps = {
  getTypes: FetchingTypes,
  getCategories: FetchingCategories,
  createType: CreateDefaultType,
  updateType: UpdatingDefaultType,
  deleteType: DeletingDefaultType,
};

const mapStateToProps = (state: any) => {
  const DefaultTypes = GetDefaulttypessSelector(state);
  const Pagination = GetPaginationSelector(state);
  const Categories = GetCategoriesSelector(state);
  const Loading = TypeProgress(state);
  const isDataLoading = getDataLoading(state);
  return {
    defaultTypes: DefaultTypes,
    pagination: Pagination,
    categories: Categories,
    loading: Loading,
    isDataLoading,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(defaulttype);
