import moment from 'moment';
import React, { FunctionComponent } from 'react';

interface ItemProps {
  invoice: any;
}
const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { invoice } = props;

  return (
    <tr>
      <td>{moment(invoice.createAt).format('DD-MM-YYYY')}</td>
      <td>{invoice.description}</td>
      <td>{`${invoice.producerId.firstName} ${invoice.producerId.lastName}`}</td>
      <td>{`$${invoice.totalBill}`}</td>
      <td>{invoice.invoiceNo}</td>
    </tr>
  );
};

export default Item;
