import React, { FunctionComponent, useEffect, useState } from 'react';
import Pagination from 'components/controls/pagination/Pagination';
import { Pagination as Paginationtype } from 'modules/response/response.types';
import { Table } from 'react-bootstrap';
import { defaultPagination } from 'helpers/utils';
import Item from 'views/job/Item';
import { ShowDayJob, showDayJobList } from 'modules/user/types';
import Loader from 'components/controls/Loader/Loader';

interface JProps {
  getJobs: Function;
  jobs: showDayJobList;
  pagination: Paginationtype;
  isLoading: boolean;
}
const jobsListing: FunctionComponent<JProps> = (props) => {
  const { getJobs, jobs, pagination, isLoading } = props;
  const [paginationJob, setPagination] = useState<Paginationtype>(defaultPagination());
  useEffect(() => {
    LoadData(1);
  }, []);

  useEffect(() => {
    setPagination(pagination);
  }, [jobs]);

  const LoadData = (page: number) => {
    getJobs({ page, limit: 10 });
  };

  const handlePages = (updatePage: any) => {
    LoadData(updatePage.selected + 1);
  };
  return (
    <div className="card">
      <div className="card-header" style={{ textAlign: 'center', width: '100%' }}>
        All Jobs
      </div>
      <div className="card-body">
        {isLoading ? (
          <Loader isSmallView />
        ) : (
          <Table style={{ textAlign: 'center' }} striped bordered hover>
            <thead>
              <tr>
                <th>Show Title</th>
                <th>No# Performer</th>
                <th>Dates</th>
                <th>Location</th>
                <th>Created By</th>
              </tr>
            </thead>
            <tbody>{jobs && jobs.map((job: ShowDayJob, index: number) => <Item key={index} job={job} />)}</tbody>
          </Table>
        )}
        <br />
        {paginationJob.totalPages > 1 && (
          <Pagination
            page={paginationJob.page}
            totalPages={paginationJob.totalPages > 0 ? paginationJob.totalPages : 1}
            handlePagination={handlePages}
          />
        )}
      </div>
    </div>
  );
};

export default jobsListing;
