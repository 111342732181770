import ApiResponse from 'modules/response/response.types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';
import { IfetchUsers, IUserStatus } from 'modules/params/type';

export const FetchingUsers = (payload: IfetchUsers) => ({
  type: ACTIONS.USER_FETCHING,
  payload,
});

export const userfetch = {
  pending: {
    type: ACTIONS.USER_FETCH.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: ApiResponse) => ({
    type: ACTIONS.USER_FETCH.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.USER_FETCH.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const Userstatus = (state: IUserStatus) => ({
  type: ACTIONS.USER_STATUS,
  payload: state,
});

export const UpdateUserStatus = (data: IUserStatus) => ({
  type: ACTIONS.UPDATE_USER_STATUS,
  payload: data,
});

export const updateUserStatus = {
  pending: {
    type: ACTIONS.UPDATE_USER_STATUS.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: IUserStatus) => ({
    type: ACTIONS.UPDATE_USER_STATUS.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UPDATE_USER_STATUS.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
