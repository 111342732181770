import { IUnionStatus } from 'modules/params/type';
import { UnionStatus, UnionStatusList } from 'modules/user/types';
import ACTIONS, { FETCH_STATUS } from 'store/castingPax.constants';

export const unionStatusFetching = () => ({
  type: ACTIONS.UNION_STATUS_FETCHING,
});

export const unionStatusFetched = {
  pending: {
    type: ACTIONS.UNION_STATUS_FETCHED.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: UnionStatusList) => ({
    type: ACTIONS.UNION_STATUS_FETCHED.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UNION_STATUS_FETCHED.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};

export const createUnionStatus = (data: IUnionStatus) => ({
  type: ACTIONS.UNION_STATUS_CREATE_CALL,
  payload: data,
});

export const deleteUnionStatus = (id: string) => ({
  type: ACTIONS.UNION_STATUS_DELETE_CALL,
  payload: id,
});

export const updateUnionStatus = (data: Location) => ({
  type: ACTIONS.UNION_STATUS_UPDATE_CALL,
  payload: data,
});

export const unionStatusCall = {
  pending: {
    type: ACTIONS.UNION_STATUS_CREATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: UnionStatus) => ({
    type: ACTIONS.UNION_STATUS_CREATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UNION_STATUS_CREATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const deleteUnionCall = {
  pending: {
    type: ACTIONS.UNION_STATUS_DELETE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: string) => ({
    type: ACTIONS.UNION_STATUS_DELETE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UNION_STATUS_DELETE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
export const updateUnionCall = {
  pending: {
    type: ACTIONS.UNION_STATUS_UPDATE.PENDING,
    status: FETCH_STATUS.LOADING,
  },
  success: (data: any) => ({
    type: ACTIONS.UNION_STATUS_UPDATE.SUCCESS,
    data,
    status: FETCH_STATUS.SUCCESS,
  }),
  error: (error: Error) => ({
    type: ACTIONS.UNION_STATUS_UPDATE.ERROR,
    error,
    status: FETCH_STATUS.ERROR,
  }),
};
