import React, { FunctionComponent } from 'react';
import Button from 'components/controls/button/button';

interface ItemProps {
  location: any;
  sr: number;
  changeLocation: Function;
  deleteLocation: Function;
  changeMode: Function;
  selectedId: string | undefined;
}
const Item: FunctionComponent<ItemProps> = (props: ItemProps) => {
  const { location, sr, changeLocation, changeMode, deleteLocation, selectedId } = props;
  return (
    <tr>
      <td>{sr + 1}</td>
      <td>{location.name}</td>
      <td>
        <Button
          label="Edit"
          clickHandler={() => {
            changeLocation(location);
            changeMode('edit');
          }}
        />
        &ensp;
        <Button
          label="Delete"
          type="danger"
          clickHandler={() => {
            deleteLocation(location);
            changeMode('delete');
          }}
          showLoading={location.id === selectedId}
        />
      </td>
    </tr>
  );
};

export default Item;
