import { all, call, takeEvery, put } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/job.services';
import ApiResponse, { Pagination } from 'modules/response/response.types';
import { jobActions } from 'store/actions';

function* GetJobs(state: { type: string; payload: Pagination }) {
  try {
    yield put(jobActions.JobsFetched.pending);
    const response: ApiResponse = yield call(api.GetJobs, state.payload);
    if (response) yield put(jobActions.JobsFetched.success(response));
  } catch (error: any) {
    yield put(jobActions.JobsFetched.error(error.message));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTIONS.JOBS_FETCHING, GetJobs)]);
}
