import React, { FunctionComponent, useEffect, useState } from 'react';
import { Pagination as PaginationType } from 'modules/response/response.types';
import PaginationComponent from 'components/controls/pagination/Pagination';
import SelectOption from 'components/controls/select/SelectOption';
import { Table } from 'react-bootstrap';
import Item from 'views/defaulttype/item';
import Button from 'components/controls/button/button';
import Modal from 'components/controls/Modal/Modal';
import ModalBody from 'views/defaulttype/modelbody';
import { DefaultType, DefaultTypeList } from 'modules/user/types';
import Loader from 'components/controls/Loader/Loader';

interface Tprops {
  getTypes: Function;
  getCategories: Function;
  createType: Function;
  updateType: Function;
  deleteType: Function;
  pagination: PaginationType;
  loading: boolean;
  defaultTypes: DefaultTypeList;
  categories: any;
  isDataLoading: boolean;
}
const Defaulttype: FunctionComponent<Tprops> = (props: Tprops) => {
  const {
    getTypes,
    defaultTypes,
    pagination,
    getCategories,
    categories,
    createType,
    loading,
    updateType,
    deleteType,
    isDataLoading,
  } = props;
  const [types, setTypes] = useState<DefaultTypeList>(defaultTypes);
  const [isshow, setIsShow] = useState<boolean>(false);
  const [mode, setMode] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(loading);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [categoryType, setCategoryType] = useState<string>();
  const [defaultValue, setDefaultValue] = useState<string>('--select--');
  const [selectedId, setSelectedId] = useState<string>();

  useEffect(() => {
    getCategories();
    setSelectedItem({ type: '', description: '', value: '', label: '' });
  }, []);

  useEffect(() => {
    setTypes(defaultTypes);
    closeModal();
  }, [defaultTypes]);

  useEffect(() => {
    LoadData(1);
  }, [categoryType]);

  useEffect(() => {
    setLoader(loading);
    if (!loading) {
      setSelectedId('');
      setSelectedItem({ type: '', description: '', value: '', label: '' });
      setIsShow(false);
    }
  }, [loading]);

  const LoadData = (page: number) => {
    getTypes({ page, limit: 10, text: categoryType });
  };

  const handlePages = (updatePage: any) => {
    LoadData(updatePage.selected + 1);
  };

  const showModel = () => {
    if (mode === 'edit') {
      setIsShow(true);
      setDefaultValue(selectedItem.type);
    } else {
      setMode('add');
      setIsShow(true);
    }
  };
  const closeModal = () => {
    setSelectedItem({ type: '', description: '', value: '', label: '' });
    setIsShow(false);
    setDefaultValue('--select--');
    setMode('');
  };
  const updateModelData = (data: DefaultType) => {
    if (mode === 'add') createType(data);
    else updateType(data);
  };

  const handleDeleteType = (id: string) => {
    setSelectedId(id);
    deleteType(id);
  };

  const modalBody = () => (
    <ModalBody
      loader={loader}
      typedata={categories}
      selectedtype={selectedItem}
      ClickHandle={updateModelData}
      ClickCancle={closeModal}
    />
  );

  const loadComponent = () => (
    <Table style={{ textAlign: 'center' }} striped bordered hover>
      <thead>
        <tr>
          <th>Type</th>
          <th>Label</th>
          <th>Value</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {types &&
          types.map((type: any, index: number) => (
            <Item
              key={index}
              type={type}
              handleDelete={handleDeleteType}
              selectedItem={setSelectedItem}
              selectedID={selectedId}
              mode={setMode}
              show={setIsShow}
            />
          ))}
      </tbody>
    </Table>
  );

  return (
    <div className="card">
      <div className="card-header" style={{ textAlign: 'center' }}>
        All Default Types
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-3">
            <SelectOption
              defaultvalue={defaultValue}
              label="Select Default Type"
              data={categories}
              handleSelect={setCategoryType}
            />
          </div>
          <div className="col-md-9">
            <div style={{ float: 'right', marginTop: '5px' }}>
              <Button label="Add Type" clickHandler={showModel} />
            </div>
          </div>
        </div>
        <br />
        <div className="row justify-content-center">
          <div className="col-md-11 ">
            {isDataLoading ? <Loader isSmallView /> : loadComponent()}
            <br />
            {pagination.totalPages > 1 && (
              <PaginationComponent
                page={pagination.page}
                totalPages={pagination.totalPages > 0 ? pagination.totalPages : 1}
                handlePagination={handlePages}
              />
            )}
          </div>
          {isshow && (
            <Modal title={mode === 'add' ? 'Enter New Type' : 'Update Type'} body={modalBody} closeModal={closeModal} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Defaulttype;
