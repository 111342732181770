import React, { FunctionComponent, useState } from 'react';
import Button from 'components/controls/button/button';
import CustomInput from 'components/controls/input/customInput/CustomInput';
import GroupCheckBoxes from 'components/controls/checkbox/GroupCheckBoxes';

interface ModalProps {
  ClickCancle: Function;
  ClickHandle: Function;
  roles: any;
  loader: boolean;
}
const ModalBody: FunctionComponent<ModalProps> = (props: ModalProps) => {
  const { ClickHandle, ClickCancle, roles, loader } = props;
  const [selectedRoles, setSelectedRoles] = useState<any>([]);
  const [Title, setTitle] = useState<string>();
  const [Message, setMessage] = useState<string>();

  const ClickChange = (items: any) => {
    setSelectedRoles(items);
  };
  const handleNotification = () => {
    ClickHandle({
      roles: selectedRoles,
      title: Title,
      message: Message,
    });
  };
  return (
    <div>
      <div>
        <GroupCheckBoxes Items={roles} handleChecked={ClickChange} />
      </div>
      <br />
      <div className="row">
        <div className="col-md-3">
          <label>Title</label>
        </div>
        <div className="col-md-9">
          <CustomInput type="text" placeholder="Enter Notification Title" handleChange={setTitle} />
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <label>Message</label>
        </div>
        <div className="col-md-9">
          <CustomInput type="text" placeholder="Enter Notification Message" handleChange={setMessage} />
        </div>
      </div>
      <Button showLoading={loader} label="Create" clickHandler={handleNotification} />
      &ensp;
      <Button label="Cancel" type="customCancelBtn" clickHandler={() => ClickCancle()} />
    </div>
  );
};
export default ModalBody;
