import { all, call, put, takeEvery } from 'redux-saga/effects';
import ACTIONS from 'store/castingPax.constants';
import api from 'store/services/subscription.services';
import { subscriptionActions } from 'store/actions';
import { SubscriptionPlan, subscriptionPlanList } from 'modules/user/types';

function* GetPlans() {
  try {
    yield put(subscriptionActions.PlansFetched.pending);
    const response: subscriptionPlanList = yield call(api.GetPlans);
    if (response) yield put(subscriptionActions.PlansFetched.success(response));
  } catch (error: any) {
    yield put(subscriptionActions.PlansFetched.error(error.message));
  }
}
function* UpdatePlan(state: { type: string; payload: SubscriptionPlan }) {
  try {
    const response: SubscriptionPlan = yield call(api.UpdatePlan, state.payload);
    if (response) yield put(subscriptionActions.PlanUpdated(response));
  } catch (error: any) {
    yield console.log(error.message);
  }
}

export default function* rootSaga() {
  yield all([takeEvery(ACTIONS.PLAN_FETCHING, GetPlans), takeEvery(ACTIONS.PLAN_UPDATE_CALL, UpdatePlan)]);
}
